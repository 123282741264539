import React from "react";
import { Button, Container, Form, Grid, Header, Image, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ApiCallResult } from "../../utils/constants";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ErrorMessage } from "@hookform/error-message";

const ResetPassword = ({
	onSubmit,
	onResetClick,
	createResetPasswordDataResult
}) => {
	const schema = yup.object({
		password: yup.string().min(8, "Password must have at least 8 characters")
					 .max(20, "Password must have a maximum of 20 characters")
					 .matches(/\d+/, "Password must have at least one number")
					 .matches(/[a-z]+/, "Password must have at least one lowercase character")
					 .matches(/[A-Z]+/, "Password must have at least one uppercase character")
					 .matches(/[!@#$%^&*()-+]+/, "Password must have at least one special character")
					 .required("Password is required to register"),
		confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
	}).required();
	const {control, watch, getValues, setValue, handleSubmit, formState: {errors}, setError, clearErrors} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			password: "",
			confirmPassword: ""
		}
	});

	const submitForm = async values => {
		await onSubmit({
			password: values.password
		});
	};

	return (
		<Container className="argus-full-page-container login-background" fluid>
			<Grid centered padded stretched verticalAlign="middle">
				<Grid.Row>
					<Grid.Column width={16}>
						<Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="medium" centered as={Link} to="/"/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={2}/>
					<Grid.Column width={10}>
						<Segment padded>
							{createResetPasswordDataResult === ApiCallResult.NoResult &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<Header as="h1">Reset password</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Enter your new password
											</p>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={2} />
										<Grid.Column width={12} textAlign="center">
											<Form onSubmit={handleSubmit(submitForm)} size="small">
												<Grid centered>
													<Grid.Row>
														<Grid.Column width={8}>
															<Controller
																name="password"
																control={control}
																render={({field: {onChange, value}}) => (
																	<Form.Input
																		label="Password"
																		type="password"
																		onChange={onChange}
																		value={value}
																		placeholder="Password"/>
																)}
															/>
															<ErrorMessage
																errors={errors}
																name="password"
																render={({message}) => <p
																	className="b4 color-red mb-2">{message}</p>}
															/>
														</Grid.Column>
														<Grid.Column width={8}>
															<Controller
																name="confirmPassword"
																control={control}
																render={({field: {onChange, value}}) => (
																	<Form.Input
																		label="Confirm Password"
																		type="password"
																		onChange={onChange}
																		value={value}
																		placeholder="Password"/>
																)}
															/>
															<ErrorMessage
																errors={errors}
																name="confirmPassword"
																render={({message}) => <p
																	className="b4 color-red mb-2">{message}</p>}
															/>
														</Grid.Column>
													</Grid.Row>
													<Grid.Row>
														<Grid.Column width={16} textAlign="center">
															<Button className="blue-button"
																	type="submit">
																Submit
															</Button>
														</Grid.Column>
													</Grid.Row>
												</Grid>
											</Form>
										</Grid.Column>
										<Grid.Column width={2}/>
									</Grid.Row>
								</Grid>
							}
							{createResetPasswordDataResult === ApiCallResult.Success &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Your password has been successfully reset.
												Please use your new credentials to log in.
											</p>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
							{createResetPasswordDataResult === ApiCallResult.Error &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Your password could not be reset.
												Please try again later or contact support.
											</p>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<Button type="button"
													className="blue-button"
													onClick={onResetClick}>
												Try again
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
						</Segment>
					</Grid.Column>
					<Grid.Column width={2}/>
				</Grid.Row>
			</Grid>
		</Container>
	)
}

export default ResetPassword;