import React from "react";
import "./style.css";
import { Grid } from "semantic-ui-react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

SwiperCore.use([Autoplay, Navigation]);

const Hero = () => {
    const heroImages = [
        "https://argusweb.azureedge.net/argus-web/home/Argus Website Banners-01.jpg",
        "https://argusweb.azureedge.net/argus-web/home/Argus Website Banners-02.jpg",
        "https://argusweb.azureedge.net/argus-web/home/Argus Website Banners-03.jpg"
    ]

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Swiper
                        modules={[Autoplay, Navigation]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        loop={true}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: true,
                        }}
                        onSwiper={(swiper) => console.log("")}>
                        {heroImages.map((image, index) => (
                            <SwiperSlide key={index}>
                                <img src={image} alt={`Slide ${index + 1}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Hero;
