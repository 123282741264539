import React, {useState} from "react";
import { Link } from "react-router-dom";
import {Card, Container, Image, Reveal} from "semantic-ui-react";
import "./style.css";

const TopCategoriesItem = ({
                                categoryName,
                                productTypes,
                                categoryImage
                           }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const capitalize = (input) => {
        return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    }

    return (
        <Card className="transparent-card" as={Link} to={`/products?product_category=${categoryName}`}>
            <Container textAlign='center'>
                <Image src={categoryImage} wrapped ui={false} fluid />
            </Container>
            <Card.Content>
                <Container textAlign="center">
                    <Card.Header className="top-category-card-name">
                        {capitalize(categoryName.replaceAll('_', ' ').toLowerCase())}
                    </Card.Header>
                </Container>
            </Card.Content>

            {/*<Box sx={{ display: 'flex', flexDirection: 'column' }}>*/}
            {/*    <CardContent>*/}
            {/*        <Typography component="div" variant="h6" sx={{ textDecoration: "underline", fontSize: "0.9em"}}>*/}
            {/*            {categoryName.replaceAll('_', ' ')}*/}
            {/*        </Typography>*/}
            {/*    </CardContent>*/}
            {/*    <CardMedia*/}
            {/*        component="img"*/}
            {/*        sx={{ minHeight: 150, maxHeight: 150}}*/}
            {/*        image={categoryImage}*/}
            {/*        alt={categoryName.replaceAll('_', ' ')}*/}
            {/*    />*/}

            {/*    {productTypes && <CardContent sx={{ "paddingBottom": 0}}>*/}
            {/*        <List sx={{ "paddingBottom": 0}}>*/}
            {/*            {take(productTypes, 5).map(type=> {*/}
            {/*                const typeName = type.replaceAll('_', ' ');*/}
            {/*                return (*/}
            {/*                    <ListItemButton sx={{ "paddingBottom": 0, "paddingTop": 0 }} component="a" href={`/results?pt=${type}`}>*/}
            {/*                        <ListItemText primary={typeName} />*/}
            {/*                    </ListItemButton>*/}
            {/*                )*/}
            {/*            }) }*/}
            {/*        </List>*/}
            {/*    </CardContent>}*/}
            {/*    {productTypes?.length > 5 && <Collapse in={expanded} timeout="auto" unmountOnExit>*/}
            {/*        <CardContent sx={{ "paddingTop": 0}}>*/}
            {/*            <List sx={{ "paddingTop": 0}}>*/}
            {/*                {slice(productTypes, 5).map(type=> {*/}
            {/*                    const typeName = type.replaceAll('_', ' ');*/}
            {/*                    return (*/}
            {/*                        <ListItemButton sx={{ "paddingBottom": 0, "paddingTop": 0 }} component="a" href={`/results?pt=${type}`}>*/}
            {/*                            <ListItemText primary={typeName} />*/}
            {/*                        </ListItemButton>*/}
            {/*                    )*/}
            {/*                }) }*/}
            {/*            </List>*/}
            {/*        </CardContent>*/}
            {/*    </Collapse>}*/}
            {/*    {productTypes?.length > 5 && !expanded && <CardActions disableSpacing>*/}
            {/*        <Button size="small" onClick={handleExpandClick} color="primary">*/}
            {/*            {"See More"}*/}
            {/*        </Button>*/}
            {/*    </CardActions>}*/}
            {/*    {productTypes?.length > 5 && expanded && <CardActions disableSpacing>*/}
            {/*        <Button onClick={handleExpandClick} size="small" color="primary">*/}
            {/*            {"See Less"}*/}
            {/*        </Button>*/}
            {/*    </CardActions>}*/}
            {/*</Box>*/}
        </Card>
    )
}

export default TopCategoriesItem;