
import axios from "axios";
import {encode} from "js-base64";
import {createAxios} from "./axios";
import { storageKeys } from "./storageKeys";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_PRODUCT_API_URL;

export const getProductBySku = async (productSku) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/product`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const searchParams = new URLSearchParams();
        if (!!productSku) searchParams.append("sku", productSku);

        const requestUrl = `?${searchParams.toString()}`;

        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getProducts = async ({
    productCategory,
    productType,
    vendor,
    make,
    model,
    variant,
    year,
    pageNumber
}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/product`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const searchParams = new URLSearchParams();
        if (!!productCategory) searchParams.append("productCategory", productCategory);
        if (!!productType) searchParams.append("productType", productType);
        if (!!vendor) searchParams.append("vendor", vendor);
        if (!!make) searchParams.append("make", make);
        if (!!model) searchParams.append("model", model);
        if (!!variant) searchParams.append("variant", variant);
        if (!!year) searchParams.append("year", year);
        if (!!pageNumber) searchParams.append("skip", pageNumber - 1);

        const requestUrl = `?${searchParams.toString()}`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getProductsForVehicle = async ({
    vehicleYearId,
    productCategory,
    productType,
    vendor,
    pageNumber
}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/product`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const searchParams = new URLSearchParams();
        if (!!productCategory) searchParams.append("productCategory", productCategory);
        if (!!productType) searchParams.append("productType", productType);
        if (!!vendor) searchParams.append("vendor", vendor);
        if (!!pageNumber) searchParams.append("skip", pageNumber - 1);

        const requestUrl = `/vehicle/${vehicleYearId}?${searchParams.toString()}`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getPrices = async ({
    productSkus,
    level
}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/product`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const params = {
            productSkus: productSkus,
            level: level
        }

        const stringfiedParams = JSON.stringify(params);

        const base64Params = encode(stringfiedParams);

        const searchParams = new URLSearchParams();
        if (!!base64Params) searchParams.append("filters", base64Params);

        const requestUrl = `/prices?${searchParams.toString()}`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getFilters = async ({
    productIds,
    currentProductCategory,
    currentProductType,
    currentVendor,
    currentMake,
    currentModel,
    currentVariant,
    currentYear
}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/product`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const params = {
            productIds,
            currentProductCategory,
            currentProductType,
            currentVendor,
            currentMake,
            currentModel,
            currentVariant,
            currentYear
        }

        const stringfiedParams = JSON.stringify(params);

        const base64Params = encode(stringfiedParams);

        const searchParams = new URLSearchParams();
        if (!!base64Params) searchParams.append("filters", base64Params);

        const requestUrl = `/filters?${searchParams.toString()}`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getProductData = async (productSkus) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/product`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const params = {
            productSkus: productSkus
        }

        const stringfiedParams = JSON.stringify(params);

        const base64Params = encode(stringfiedParams);

        const searchParams = new URLSearchParams();
        if (!!base64Params) searchParams.append("filters", base64Params);

        const requestUrl = `/products?${searchParams.toString()}`;

        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }

}

export const getSaleProducts = async (priceCategory) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/product`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const searchParams = new URLSearchParams();
        if (!!priceCategory) searchParams.append("level", priceCategory);

        const requestUrl = `/sale-products?${searchParams.toString()}`;

        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }

}