import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import TopCategoriesItem from "../TopCategoriesItem";
import {search} from "../../api/searchController";
import {Card, Dimmer, Grid} from "semantic-ui-react";
import {BeatLoader} from "react-spinners";
import "./style.css";

const getCategories = async ({
                                 setProductTypeSearchResults,
                                 setLoading,
                             }) => {
    setLoading(true);
    try {
        const response = await search("", null);
        if (response.success) {
            setProductTypeSearchResults(response.data.productTypeSearchResults);
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoading(false);
    }
}

const TopCategories = () => {
    const [loading, setLoading] = useState(false);
    const [productTypeSearchResults, setProductTypeSearchResults] = React.useState([]);

    useEffect(() => {
        const getCategoriesAsync = async () => {
            const callGetCategoriesOptions = {
                setProductTypeSearchResults,
                setLoading
            }

            await getCategories(callGetCategoriesOptions);
        }



        getCategoriesAsync();
    }, [])

    return (
        <div className="pt-5 pb-5">
            {!loading && productTypeSearchResults && <Card.Group itemsPerRow={5}>
                {productTypeSearchResults.map((category, index) => (
                    <TopCategoriesItem
                        key={index}
                        categoryName={category.category}
                        categoryImage={category.image}
                        productTypes={category.productTypes}
                    />
                ))}
            </Card.Group>}
            {loading && <Grid style={{height: "50vh"}}>
                <Grid.Row className="mt-5 mb-5">
                    <Dimmer active inverted>
                        <BeatLoader color="#36d7b7" />
                        <p>Please wait...</p>
                    </Dimmer>
                </Grid.Row>
            </Grid>}
        </div>

    );
};

export default TopCategories;