import React from "react";
import { Route, Routes } from "react-router-dom";

import Homepage from "../HomePage";
import ResultsPage from "../ResultsPage";
import CartPage from "../CartPage";
import Checkout from "../CheckoutPage";
import ErrorPage from "../ErrorPage";
import LoginPage from "../LoginPage";
import LogoutPage from "../LogoutPage";
import RegisterPage from "../RegisterPage";
import PartNumberSearchResultPage from "../PartNumberSearchResultPage";
import SearchResultsPage from "../SearchResultsPage";
import PaymentPage from "../PaymentPage";
import PaymentConfirmationPage from "../PaymentConfirmationPage";
import ProductsPage from "../ProductsPage";
import AboutPage from "../AboutPage";
import TeamPage from "../TeamPage";
import FaqPage from "../FaqPage";
import ContactUsPage from "../ContactUsPage";
import CataloguesPage from "../CataloguesPage";
import AccountVerificationPage from "../AccountVerificationPage";
import ForgotPasswordPage from "../ForgotPasswordPage";
import ResetPasswordPage from "../ResetPasswordPage";
import CheckoutOrderInformationPage from "../CheckoutOrderInformationPage";
import CheckoutBillingAddressPage from "../CheckoutBillingAddressPage";
import CheckoutShippingAddressPage from "../CheckoutShippingAddressPage";
import CheckoutDeliveryMethodPage from "../CheckoutDeliveryMethodPage";
import CheckoutSummaryPage from "../CheckoutSummaryPage";
import PaymentAccountCreditPage from "../PaymentAccountCreditPage";
import PaymentEftPage from "../PaymentEftPage";
import PaymentPayfastPage from "../PaymentPayfastPage";
import PaymentCreditCardOnCollectionPage from "../PaymentCreditCardOnCollectionPage";
import PayfastConfirmationPage from "../PayfastConfirmationPage";
import CheckoutCollectionAddressPage from "../CheckoutCollectionAddressPage";
import ProductPageWrapper from "../../page-wrappers/ProductPageWrapper";

const AllRoute = () => {
  return (
      <Routes>
        <Route exact path="/" element={<Homepage/>} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/team" element={<TeamPage/>} />
        <Route path="/faq" element={<FaqPage/>} />
        <Route path="/contact-us" element={<ContactUsPage/>} />
        <Route path="/catalogues" element={<CataloguesPage/>} />
        <Route path="/results" element={<ResultsPage/>} />
        <Route path="/products" element={<ProductsPage/>} />
        <Route path="/product/:productSku" element={<ProductPageWrapper/>} />
        <Route path="/cart" element={<CartPage/>} />
        <Route path="/checkout" element={<Checkout/>} />
        <Route path="/checkout/information" element={<CheckoutOrderInformationPage />} />
        <Route path="/checkout/billing" element={<CheckoutBillingAddressPage />} />
        <Route path="/checkout/collection" element={<CheckoutCollectionAddressPage />} />
        <Route path="/checkout/shipping" element={<CheckoutShippingAddressPage />} />
        <Route path="/checkout/delivery-method" element={<CheckoutDeliveryMethodPage />} />
        <Route path="/checkout/summary" element={<CheckoutSummaryPage />} />
        <Route path="/error" element={<ErrorPage/>} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/register" element={<RegisterPage/>} />
        <Route path="/account-verification" element={<AccountVerificationPage/>} />
        <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
        <Route path="/reset-password" element={<ResetPasswordPage/>} />
        <Route path="/part-number-results" element={<PartNumberSearchResultPage/>} />
        <Route path="/search-results" element={<SearchResultsPage/>} />
        <Route path="/payment/:orderId" element={<PaymentPage/>} />
        <Route path="/payment/account-credit/:orderId" element={<PaymentAccountCreditPage/>} />
        <Route path="/payment/eft/:orderId" element={<PaymentEftPage/>} />
        <Route path="/payment/payfast/:orderId" element={<PaymentPayfastPage/>} />
        <Route path="/payment/credit-card-on-collection/:orderId" element={<PaymentCreditCardOnCollectionPage/>} />
        <Route path="/payment-confirmation/:paymentId" element={<PaymentConfirmationPage/>} />
        <Route path="/payfast-confirmation/:orderId" element={<PayfastConfirmationPage/>} />
        <Route path="/logout" element={<LogoutPage/>} />
        <Route element={<ErrorPage/>} />
      </Routes>
  );
};

export default AllRoute;
