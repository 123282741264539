import React, { useEffect, useState } from "react";
import DOMPurify from 'dompurify';

import "./style.css";
import {useCart} from "react-use-cart";
import {Grid, Button, Image, Header, Container, Segment, Icon, Message} from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { PriceCategory, ProductAvailability } from "../../utils/constants";
import ConfirmationModal, {confirmationChoices} from "../ConfirmationModal";
import {SouthAfricanRand} from "../../utils/helpers";
import {round} from "lodash";

const Product = ({product, price, priceCategory}) => {
  const navigate = useNavigate();
  const [productAddedInformation, setProductAddedInformation] = useState(null);

  const { addItem } = useCart();

  const onBackClick = () => {
    return navigate(-1);
  }

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(product.html)
  })

  const onAddToCartClick = () => {
    const cartProduct = {
      id: product.productId,
      name: product.name,
      brand: product.vendor,
      sku: product.sku,
      image: product.image,
      price: product.price
    }
    addItem(cartProduct, 1);
    setProductAddedInformation(product);
  }

  const closeModalHandle = () => {
    setProductAddedInformation(null);
  }

  const handleProductAddedModalClose = (confirmationChoice) => async () => {
    const shouldGoToCart = confirmationChoices.confirm === confirmationChoice;

    if (shouldGoToCart) {
      return navigate("/cart");
    }

    closeModalHandle();
  };

  let colourForAvailability;
  let textForAvailability;
  let colourForQuality;
  let textForQuality;
  if (!!product) {
    switch (product.availability) {
      case ProductAvailability.InStock:
        colourForAvailability = "green";
        textForAvailability = "This item is in stock at our warehouse. See our delivery policy to see when you can expect it.";
        break;
      case ProductAvailability.LowStock:
        colourForAvailability = "yellow";
        textForAvailability = "This item shows as having low stock in our warehouse. Please be aware that in some cases, this item may already be sold out and we may have to order this item in or refund your order.";
        break;
      case ProductAvailability.ThreeToFiveDays:
        colourForAvailability = "orange";
        textForAvailability = "This item is in-stock at our suppliers. When you place an order for this item, we will collect it from our supplier and deliver it to you. Please see our delivery policy to see when you can expect it.";
        break;
      case ProductAvailability.No_Stock:
        colourForAvailability = "red";
        textForAvailability = "This item is unfortunately out of stock, but more stock is on the way! Please contact us or regularly check our website to be notified when this item is back in stock.";
        break
      default:
        colourForAvailability = "";
        break;
    }
    switch (product.quality) {
      case "A+":
        colourForQuality = "violet";
        textForQuality = "Manufacturer is a recognised O.E. supplier.";
        break;
      case "A":
        colourForQuality = "blue";
        textForQuality = "Aftermarket part manufactured to O.E. equivalent specfications.";
        break;
      case "B":
        colourForQuality = "teal";
        textForQuality = "Economical replacement aftermarket part conforms to regulator requirements.";
        break;
      case "Other":
        colourForQuality = "olive";
        textForQuality = "Applies to tools, accessories and non car specific parts.";
        break;
      default:
        colourForQuality = "";
        textForQuality = "";
        break;
    }
  }

  return (
      <Container className="argus-container" fluid>
        <Grid container>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button compact
                      size="tiny"
                      className="blue-basic-button borderless"
                      onClick={onBackClick}>
                <Icon name="arrow left" />
                Back
              </Button>

            </Grid.Column>
          </Grid.Row>
          {!!product &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment padded>
                    <Grid padded>
                      <Grid.Row>
                        <Grid.Column width={8} textAlign="center" verticalAlign="middle">
                            <Image centered size="medium" src={product.image} alt={product.name} fluid />
                          </Grid.Column>
                        <Grid.Column width={8}>
                          <Grid divided='vertically'>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <Header as="h3">{product.name}</Header>
                                <p className="b3">SKU: {product.sku}</p>
                                {product.vendor && <p className="b3">Vendor: {product.vendor}</p>}
                              </Grid.Column>
                            </Grid.Row>
                            {!!price &&
                                <Grid.Row>
                                  <Grid.Column width={16}>
                                    <Header as="h3">{SouthAfricanRand.format(round(price.price, 2))}</Header>
                                    {!!priceCategory && priceCategory !== PriceCategory.Retail && <><sub>(Ex. VAT)</sub></>}
                                  </Grid.Column>
                                </Grid.Row>
                            }
                            <Grid.Row>
                              <Grid.Column width={16}>
                                {!!product.productCategory &&
                                    <Link to={`/products?product_category=${product.productCategory}`}>
                                      {product.productCategory.replaceAll("_", " ")}
                                    </Link>
                                }
                                {!!product.productType &&
                                    <Link to={`/products?product_type=${product.productType}`}>
                                      {product.productType.replaceAll("_", " ")}
                                    </Link>
                                }
                              </Grid.Column>
                            </Grid.Row>
                            {!!product.availability &&
                                <Grid.Row>
                                  <Grid.Column width={16}>
                                    <Message color={colourForAvailability}>
                                      <Message.Header>{product.availability}</Message.Header>
                                      <Message.Content>{textForAvailability}</Message.Content>
                                    </Message>
                                  </Grid.Column>
                                </Grid.Row>
                            }
                            {!!product.quality &&
                                <Grid.Row>
                                  <Grid.Column width={16}>
                                    <Message color={colourForQuality}>
                                      <Message.Content>{textForQuality}</Message.Content>
                                    </Message>
                                  </Grid.Column>
                                </Grid.Row>
                            }
                            {product.availability !== ProductAvailability.No_Stock &&
                              <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                  <Button size="small"
                                          className="blue-button"
                                          onClick={onAddToCartClick}>
                                    Add to cart
                                  </Button>
                                </Grid.Column>
                              </Grid.Row>
                            }
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
          }
          {!!product?.html &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment padded>
                    <Header className="mb-3" as="h5">Description</Header>
                    <div dangerouslySetInnerHTML={sanitizedData()} />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
          }
          {!product &&
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <Header as="h3">No product found</Header>
                </Grid.Column>
              </Grid.Row>
          }
          {!!productAddedInformation &&
              <ConfirmationModal
                  open={!!productAddedInformation}
                  confirmLabel="Go to cart"
                  rejectLabel="Continue shopping"
                  heading="Product added"
                  closeModal={handleProductAddedModalClose}
                  content={
                    <Grid container>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <p className="h6">{productAddedInformation.name}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Image src={productAddedInformation.image} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  }
              />
          }
        </Grid>
      </Container>
  );
};

export default Product;
