import React from "react";
import { Grid } from "semantic-ui-react";
import "./style.css";

const SiteBottombar = () => {

    return (
        <Grid className="bottom-bar p-2">
            <Grid.Row>
                <Grid.Column width={2}/>
                <Grid.Column width={14}>
                    <p className="b1">
                        &copy; Argus Motor Company. All rights reserved.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default SiteBottombar;