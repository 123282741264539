import React from "react";
import { Grid, Header, Image } from "semantic-ui-react";

import "./style.css";

const About = () => {
    return (
        <div className="argus-min-height">
          <div className="argus-title-background mb-5">
            <div className="argus-title">About us</div>
          </div>
          <Grid>
            <Grid.Row className="py-8">
              <Grid.Column width={16}>
                <Grid container>
                  <Grid.Row>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Image size="huge"
                             centered
                             src="https://argusweb.azureedge.net/argus-web/about/about-beginning.webp"/>
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={16} textAlign="center">
                            <Header as="h3">The beginning</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={16} textAlign="center">
                            <p className="b2">
                              In early 1955, a young motor mechanic, Louis Raff, left smoggy Johannesburg with his wife
                              and
                              their
                              two sons, to set up shop in 'the countryside' at Argus Motors, a ramshackle filling
                              station
                              with
                              a workshop
                              and adjoining pondok on Great North Road in Brentwood Park, a few kilometers north of
                              Benoni
                              on
                              the
                              East Rand.
                              <br/><br/>
                              The 'business' was purchased for £800 from a private operator while the license to sell
                              fuel
                              was
                              obtained from the Shell Company, which in turn held the lease on the five-acre property on
                              which
                              Argus Motors was situated.
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="section-dark-background py-8">
              <Grid.Column width={16}>
                <Grid container>
                  <Grid.Row>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Grid padded container>
                        <Grid.Row>
                          <Grid.Column width={16} textAlign="center">
                            <Header as="h3" className="color-white">From the ground up</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={16} textAlign="center">
                            <p className="b2">
                              In what was zoned as a ‘sub-economic’ area, Brentwood Park proved a tough testing ground
                              for
                              Louis and Anne, who lived ‘off-grid’, relying on candle power, a windmill and an outhouse
                              to
                              supply their utilities, while a pre-war Onen lighting plant (a 3hp generator)
                              provided unreliable and often dangerous voltage to power the four Argus Motors fuel pumps.
                              <br/><br/>
                              By the early 1960s Louis and Anne had four sons (Larry, Ronnie, Mark and Chaim) and a
                              singular
                              mission to not only upgrade Argus Motors, but also to own the land it was situated on. To
                              achieve this meant convincing the Shell Company board of directors in Johannesburg to
                              install a new generator and provide the necessary loan capital to modernize the Argus
                              Motors
                              forecourt and workshop.
                              <br/><br/>
                              Faced with obdurate corporate red tape, Louis and Anne ‘stormed’ Shell House to present
                              their
                              business case- that Argus Motors has great potential in the growing Benoni district and
                              with
                              the
                              necessary capital, could finally become a viable concern.
                              <br/><br/>
                              Both Louis and Anne, on separate visits to the Shell head offices, made such an impression
                              on
                              the suits that they not only had a new 12hp generator installed but also acquired the
                              necessary
                              loans to give Argus Motors a full bricks and mortar overhaul.
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Image size="huge"
                             centered
                             src="https://argusweb.azureedge.net/argus-web/about/about-ground-up.webp"/>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="py-8">
              <Grid.Column width={16}>
                <Grid container>
                  <Grid.Row>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Image size="huge"
                             centered
                             src="https://argusweb.azureedge.net/argus-web/about/about-distrobution.webp"/>
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Grid padded container>
                        <Grid.Row>
                          <Grid.Column width={16} textAlign="center">
                            <Header as="h3">Into distribution</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={16} textAlign="center">
                            <p className="b2">
                              By the mid-1970s, both Larry and Ronnie were working in the family business which had
                              established itself as the leading service station in the area, thanks to Louis’ “honesty,
                              generosity and dedication to quality workmanship,” says Anne. “Not only were we able to
                              buy
                              the
                              land but also begin building new facilities on it which we could rent to other automotive
                              business.”
                              <br/><br/>
                              Born with an entrepreneurial gift, Larry, then in his mid-20s, soon recognized the massive
                              potential replacement parts could have. Wholesale agency contracts were duly signed with
                              leading
                              ISO-accredited parts suppliers and Argus Motor Company took a giant leap forward, into the
                              highly competitive business of distributing aftermarket parts to retail motor spares
                              shops.
                              Leading brands such as DOE, BETA, Argus Motoring and X-APPEAL that are recognized today in
                              the
                              market-place were registered.
                              <br/><br/>
                              As the 20th Century drew to a close the Raff family and its loyal staff had developed
                              Argus
                              Motor Company into a leading southern African parts supplier, with a distribution
                              infrastructure
                              servicing all the ASDC countries.
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="section-dark-background py-8">
              <Grid.Column width={16}>
                <Grid container>
                  <Grid.Row>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Grid padded container>
                        <Grid.Row>
                          <Grid.Column width={16} textAlign="center">
                            <Header as="h3" className="color-white">Continuing the legacy</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={16} textAlign="center">
                            <p className="b2">
                              In 2018 the family suffered the loss of Ronnie. Today, with Larry and Mark as directors,
                              Argus Motor Company now enjoys its third generation of Raff talent in Louis and Anne’s
                              grandchildren, Moishe (COO), Baruch (CTO) and Shifra (CFO).
                              <br/><br/>
                              A massive warehouse equipped with high-tech inventory management and dispatching systems
                              keeps a fleet of delivery bakkies busy five days a week, while contracted courier
                              companies deliver the long-distance orders from over 2500 Argus Motors customers.
                              <br/><br/>
                              According to the team, “Our focus is on supplying quality replacement parts with
                              exceptional backup service to the retail spares trade. Our 5000 square meter warehouse
                              has over 20 000 stock items of both leading and Argus-branded products covering the full
                              spectrum of popular replacement parts and accessories, which are digitally catalogued for
                              swift identification and accurate merchandising.”
                              <br/><br/>
                              With the credo, ‘people sell to people’ underpinning its day-to-day operations, superior
                              customer service will always be the primary driver of Argus. “When you deal with Argus
                              Motor Company, you are assured of total customer satisfaction and service. You are
                              dealing with a company that is steeped in business ethics and an ethos that you, the
                              valued customer, is all important,” concludes Larry.
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Image size="huge"
                             src="https://argusweb.azureedge.net/argusblob01/web/argus-splash.jpg"
                             centered/>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
    );
};
export default About;
