import React, {Fragment, useEffect} from "react";
import Logout from "../../components/Logout";
import {useNavigate} from "react-router-dom";
import {logout} from "../../api/authenticationController";

const LogoutPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        navigate("/");
    }, [])

    return (
        <Fragment>
            <Logout />
        </Fragment>
    );
};
export default LogoutPage;
