import React, {Fragment, useEffect, useState} from "react";
import ResultGrid from "../../components/ResultGrid";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getCurrentUser} from "../../api/authenticationController";
import {useNavigate} from "react-router-dom";
import SiteTopbar from "../../components/SiteTopbar";
import SiteSubheader from "../../components/SiteSubheader";
import {ApiCallStatus, LoggedInStatus} from "../../utils/constants";

const checkIsLoggedIn = async ({
   setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
        }
    } catch (e) {
        console.error(e);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const clearCookies = async () => {
    await removeAuthCookies();
};

const ResultsPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setLoading
        });
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {

        if (loggedIn === LoggedInStatus.Yes) {
            fetchCurrentUserAsync();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            return navigate(0);
        }
        else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
        }
    }, [loggedIn])

    return (
        <Fragment>
            <SiteTopbar />
            <SiteHeader currentUser={currentUser} />
            <SiteSubheader currentUser={currentUser} />
            <ResultGrid loading={loading} setLoading={setLoading} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default ResultsPage;
