import React, {useEffect, useState} from "react";
import {Button, Form, Grid, Header, Icon, Item, Segment} from "semantic-ui-react";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {ErrorMessage} from "@hookform/error-message";
import { cloneDeep, compact, find, get, join, round, toLower, trim } from "lodash";
import GoogleAutoComplete from "../GoogleAutoComplete";
import { CreateUUID, SouthAfricanRand } from "../../utils/helpers";
import { useCart } from "react-use-cart";

const CheckoutAddressUi = {
    firstLoad: -1,
    noAddressOptions: 0,
    addressDropdown: 1,
    addressForm: 2,
    addressChosen: 3,
    error: 4
}

const CheckoutShippingAddress = ({
    accountType,
    orderCheckoutInfo,
    checkoutData,
    logisticsFee,
    countries,
    provinces,
    userAddresses,
    vat,
    vatAmount,
    createAddress,
    fetchGooglePlaceDetails,
    onSubmit,
    gotoCheckoutSummaryPage
}) => {
    const {cartTotal} = useCart();
    const [currentUiState, setCurrentUiState] = useState(CheckoutAddressUi.firstLoad);
    const [googleAddressValue, setGoogleAddressValue] = useState(null);
    const [countryOptions, setCountryOptions] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [chosenAddressId, setChosenAddressId] = useState(checkoutData?.shippingAddressId ?? null)
    const schema = yup.object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        countryId: yup.string(),
        streetAddress: yup.string().required("Street address is required"),
        suburb: yup.string(),
        city: yup.string(),
        postalCode: yup.string(),
        provinceId: yup.string(),
    }).required();
    const {
        control,
        trigger,
        getValues,
        setValue,
        formState: {errors},
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            firstName: "",
            lastName: "",
            countryId: "",
            streetAddress: "",
            suburb: "",
            city: "",
            postalCode: "",
            provinceId: ""
        }
    });
    const [formDropdownOptionsChosen, setFormDropdownOptionsChosen] = useState({
        countryId: null,
        provinceId: null
    });

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        let countryOptionsTemp = [];
        countryOptionsTemp = countries.map(x => {
            return {
                key: x.countryId,
                text: x.name,
                value: x.countryId
            }
        });
        setCountryOptions(countryOptionsTemp);

    }, [countries]);

    useEffect(() => {
        let provinceOptionsTemp = [];
        provinceOptionsTemp = provinces.map(x => {
            return {
                key: x.provinceId,
                text: x.name,
                value: x.provinceId
            }
        });
        setProvinceOptions(provinceOptionsTemp);

    }, [provinces]);

    useEffect(() => {
        if (!!userAddresses && userAddresses.length > 0) {
            setCurrentUiState(CheckoutAddressUi.addressDropdown)
        } else {
            setCurrentUiState(CheckoutAddressUi.noAddressOptions)
        }
    }, [userAddresses]);

    useEffect(() => {
        const fetchGooglePlaceDetailsAsync = async () => {
            const fetchGooglePlaceDetailsAsyncOptions = {
                placeId: get(googleAddressValue, 'place_id'),
                description: get(googleAddressValue, 'description'),
            }

            const response = await fetchGooglePlaceDetails(fetchGooglePlaceDetailsAsyncOptions);
            if (!!response) {
                let streetNumber, route, sublocality, locality, city, province, country, postalCode;

                streetNumber = response?.streetNumber ?? "";
                route = response?.route ?? "";
                sublocality = response?.sublocality ?? "";
                locality = response?.locality ?? "";
                //city = response?.city ?? "";
                province = response?.province ?? "";
                country = response?.country ?? "";
                postalCode = response?.postalCode ?? "";

                setValue("streetAddress", trim(`${streetNumber} ${route}`))
                setValue("suburb", trim(sublocality))
                setValue("city", trim(locality))
                setValue("postalCode", trim(postalCode))

                const optionsChosenCopy = cloneDeep(formDropdownOptionsChosen);

                if (!!province && !!provinces) {
                    const foundProvince = provinces.find(x => toLower(x.name) === trim(toLower(province)));

                    if (!!foundProvince) {
                        optionsChosenCopy["provinceId"] = foundProvince.provinceId;
                        setValue("provinceId", foundProvince.provinceId);
                        clearErrors("provinceId");
                    }
                }

                if (!!country && countries) {
                    const foundCountry = countries.find(x => toLower(x.name) === trim(toLower(country)));

                    if (!!foundCountry) {
                        optionsChosenCopy["countryId"] = foundCountry.countryId;
                        setValue("countryId", foundCountry.countryId);
                        clearErrors("countryId");
                    }
                }

                setFormDropdownOptionsChosen(optionsChosenCopy);
            }
        }

        if (!!googleAddressValue && googleAddressValue["place_id"]) {
            fetchGooglePlaceDetailsAsync();
        }
    }, [googleAddressValue]);

    const onAddAddressClick = () => {
        setCurrentUiState(CheckoutAddressUi.addressForm);
    };

    const onCancelAddAddressClick = () => {
        if (!!userAddresses && userAddresses.length > 0) {
            setCurrentUiState(CheckoutAddressUi.addressDropdown)
        } else {
            setCurrentUiState(CheckoutAddressUi.noAddressOptions)
        }
    };

    const onSaveAddressClick = async () => {
        if (!!chosenAddressId) {
            const checkoutDataClone = cloneDeep(checkoutData);
            checkoutDataClone.shippingAddressId = chosenAddressId;
            checkoutDataClone.collectionAddressId = null;

            const onSubmitOptions = {
                checkoutData: checkoutDataClone
            }

            await onSubmit(onSubmitOptions)
                .then (() => setChosenAddressId(null));
        }
    };

    const onOptionChange = (_, {name, value}) => {
        const optionsChosenCopy = cloneDeep(formDropdownOptionsChosen);
        optionsChosenCopy[name] = value;
        setValue(name, value);
        clearErrors(name)
        setFormDropdownOptionsChosen(optionsChosenCopy);
    }

    const onSaveNewAddressClick = async () => {
        const result = await trigger([
            "firstName",
            "lastName",
            "countryId",
            "streetAddress",
            "suburb",
            "city",
            "postalCode",
            "provinceId"
        ]);

        if (result) {
            const addressId = CreateUUID();
            const createAddressOptions = {
                accountType,
                addressId,
                firstName: !!getValues("firstName") ? getValues("firstName") : null,
                lastName: !!getValues("lastName") ? getValues("lastName") : null,
                streetAddress: !!getValues("streetAddress") ? getValues("streetAddress") : null,
                countryId: !!getValues("countryId") ? getValues("countryId") : null,
                suburb: !!getValues("suburb") ? getValues("suburb") : null,
                city: !!getValues("city") ? getValues("city") : null,
                postalCode: !!getValues("postalCode") ? getValues("postalCode") : null,
                provinceId: !!getValues("provinceId") ? getValues("provinceId") : null,
            }

            await createAddress(createAddressOptions);
            setCurrentUiState(CheckoutAddressUi.addressDropdown);
        }
    };

    const showAddAddressButton = () => {
        return currentUiState === CheckoutAddressUi.noAddressOptions ||
            currentUiState === CheckoutAddressUi.addressDropdown
    }

    const showCancelAddAddressButton = () => {
        return currentUiState === CheckoutAddressUi.addressForm
    }

    const onUseAddressClick = (addressId) => {
        setChosenAddressId(addressId);
    }

    const showSaveChanges = !!chosenAddressId;
    const deliveryFeeToDisplay = () => {
        if (!!logisticsFee || logisticsFee === 0) {
            if (logisticsFee > 0) {
                return SouthAfricanRand.format(round(logisticsFee, 2))
            }
            else {
                return "Free";
            }
        }
        else {
            return "TBD"
        }
    }

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Checkout</div>
            </div>
            {!!checkoutData &&
                <Grid container>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Segment padded>
                                <Grid container>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <Header as="h4">
                                                <Icon name="truck" />
                                                Shipping address
                                            </Header>
                                        </Grid.Column>
                                        {showAddAddressButton() &&
                                            <Grid.Column width={8} textAlign="right">
                                                <Button type="button"
                                                        compact
                                                        className="blue-button"
                                                        size="small"
                                                        onClick={onAddAddressClick}
                                                >
                                                    Add address
                                                </Button>
                                            </Grid.Column>
                                        }
                                        {showCancelAddAddressButton() &&
                                            <Grid.Column width={8} textAlign="right">
                                                <Button type="button"
                                                        compact
                                                        className="red-button"
                                                        size="tiny"
                                                        onClick={onCancelAddAddressClick}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid.Column>
                                        }
                                    </Grid.Row>
                                    {currentUiState === CheckoutAddressUi.noAddressOptions &&
                                        <>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Segment padded>
                                                        <Grid container>
                                                            <Grid.Row verticalAlign="middle">
                                                                <Grid.Column width={16} textAlign="center">
                                                                    <p className="b1">
                                                                        No addresses found.
                                                                    </p>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </>
                                    }
                                    {currentUiState === CheckoutAddressUi.addressDropdown &&
                                        <>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Grid container divided="vertically">
                                                        {userAddresses.map(address => {
                                                            const country = find(countries, x => x.countryId === address.countryId);
                                                            const province = find(provinces, x => x.provinceId === address.provinceId);
                                                            const name = `${address.firstName} ${address.lastName}`;
                                                            const displayName = trim(name);
                                                            const addressArray = [
                                                                address.streetAddress,
                                                                address.suburb,
                                                                address.city,
                                                                address.postalCode,
                                                                province?.name,
                                                                country?.name
                                                            ]
                                                            const cleanedAddressArray = compact(addressArray);
                                                            const addressString = join(cleanedAddressArray, ", ");

                                                            let showAsCurrent = false;
                                                            if (!!chosenAddressId) {
                                                                showAsCurrent = address.addressId === chosenAddressId;
                                                            }
                                                            else if (!!checkoutData.shippingAddressId) {
                                                                showAsCurrent = address.addressId === checkoutData.shippingAddressId;
                                                            }

                                                            return (
                                                                <Grid.Row verticalAlign="middle">
                                                                    <Grid.Column width={14}>
                                                                        {!!displayName && <p className="b2">{displayName}</p>}
                                                                        <p className="b2">{addressString}</p>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={2}>
                                                                        {!!showAsCurrent &&
                                                                            <Button type="button"
                                                                                    compact
                                                                                    disabled
                                                                                    icon
                                                                                    className="green-button"
                                                                                    size="tiny">
                                                                                <Icon name="check"/>
                                                                            </Button>
                                                                        }
                                                                        {!showAsCurrent &&
                                                                            <Button type="button"
                                                                                    compact
                                                                                    size="tiny"
                                                                                    className="blue-basic-button"
                                                                                    onClick={() => onUseAddressClick(address.addressId)}>
                                                                                Use
                                                                            </Button>
                                                                        }
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid.Column>
                                            </Grid.Row>
                                            {!!showSaveChanges &&
                                                <Grid.Row textAlign="right">
                                                    <Grid.Column width={16}>
                                                        <Button type="button"
                                                                className="blue-button"
                                                                onClick={onSaveAddressClick}>
                                                            <Icon name="save" />
                                                            Save
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            }
                                        </>
                                    }
                                    {currentUiState === CheckoutAddressUi.addressForm &&
                                        <>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Segment padded>
                                                        <Grid container>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <GoogleAutoComplete value={googleAddressValue}
                                                                                        setValue={setGoogleAddressValue}/>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={8}>
                                                                    <Controller
                                                                        name="firstName"
                                                                        control={control}
                                                                        render={({
                                                                                     field: {onChange, value},
                                                                                     fieldState: {error, isDirty}
                                                                                 }) => (
                                                                            <Form.Input
                                                                                error={!!error && !isDirty}
                                                                                fluid
                                                                                value={value}
                                                                                label='First Name'
                                                                                placeholder='First Name'
                                                                                type="text"
                                                                                onChange={onChange}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="firstName"
                                                                        render={({message}) => <p
                                                                            className="b4 color-red mb-2">{message}</p>}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={8}>
                                                                    <Controller
                                                                        name="lastName"
                                                                        control={control}
                                                                        render={({
                                                                                     field: {onChange, value},
                                                                                     fieldState: {error, isDirty}
                                                                                 }) => (
                                                                            <Form.Input
                                                                                error={!!error && !isDirty}
                                                                                fluid
                                                                                value={value}

                                                                                label='Last name'
                                                                                placeholder='Last name'
                                                                                type="text"
                                                                                onChange={onChange}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="lastName"
                                                                        render={({message}) => <p
                                                                            className="b4 color-red mb-2">{message}</p>}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Form.Select
                                                                        name="countryId"
                                                                        fluid
                                                                        error={!!errors?.countryId}
                                                                        value={formDropdownOptionsChosen.countryId}

                                                                        label='Country'
                                                                        placeholder='Country'
                                                                        onChange={onOptionChange}
                                                                        options={countryOptions}
                                                                        search
                                                                        selection
                                                                    />
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="countryId"
                                                                        render={({message}) => <p
                                                                            className="b4 color-red mb-2">{message}</p>}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Controller
                                                                        name="streetAddress"
                                                                        control={control}
                                                                        render={({
                                                                                     field: {onChange, value},
                                                                                     fieldState: {error, isDirty}
                                                                                 }) => (
                                                                            <Form.Input
                                                                                error={!!error && !isDirty}
                                                                                fluid
                                                                                value={value}

                                                                                label='Street Address'
                                                                                placeholder='Street Address'
                                                                                type="text"
                                                                                onChange={onChange}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="streetAddress"
                                                                        render={({message}) => <p
                                                                            className="b4 color-red mb-2">{message}</p>}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Controller
                                                                        name="suburb"
                                                                        control={control}
                                                                        render={({
                                                                                     field: {onChange, value},
                                                                                     fieldState: {error, isDirty}
                                                                                 }) => (
                                                                            <Form.Input
                                                                                error={!!error && !isDirty}
                                                                                fluid
                                                                                value={value}

                                                                                label='Suburb'
                                                                                placeholder='Suburb'
                                                                                type="text"
                                                                                onChange={onChange}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="suburb"
                                                                        render={({message}) => <p
                                                                            className="b4 color-red mb-2">{message}</p>}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={8}>
                                                                    <Controller
                                                                        name="city"
                                                                        control={control}
                                                                        render={({
                                                                                     field: {onChange, value},
                                                                                     fieldState: {error, isDirty}
                                                                                 }) => (
                                                                            <Form.Input
                                                                                error={!!error && !isDirty}
                                                                                fluid
                                                                                value={value}

                                                                                label='City'
                                                                                placeholder='City'
                                                                                type="text"
                                                                                onChange={onChange}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="city"
                                                                        render={({message}) => <p
                                                                            className="b4 color-red mb-2">{message}</p>}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={8}>
                                                                    <Controller
                                                                        name="postalCode"
                                                                        control={control}
                                                                        render={({
                                                                                     field: {onChange, value},
                                                                                     fieldState: {error, isDirty}
                                                                                 }) => (
                                                                            <Form.Input
                                                                                error={!!error && !isDirty}
                                                                                fluid
                                                                                value={value}

                                                                                label='Postal code'
                                                                                placeholder='Postal code'
                                                                                type="text"
                                                                                onChange={onChange}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="postalCode"
                                                                        render={({message}) => <p
                                                                            className="b4 color-red mb-2">{message}</p>}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Form.Select
                                                                        name="provinceId"
                                                                        error={!!errors.provinceId}
                                                                        fluid
                                                                        value={formDropdownOptionsChosen.provinceId}
                                                                        options={provinceOptions}

                                                                        label='Province'
                                                                        placeholder='Province'
                                                                        type="text"
                                                                        onChange={onOptionChange}
                                                                        search
                                                                        selection
                                                                    />
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="provinceId"
                                                                        render={({message}) => <p
                                                                            className="b4 color-red mb-2">{message}</p>}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16} textAlign="right">
                                                                    <Button type="button"
                                                                            className="blue-basic-button"
                                                                            compact
                                                                            onClick={onSaveNewAddressClick}>
                                                                        Save
                                                                    </Button>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </>
                                    }
                                    {currentUiState === CheckoutAddressUi.error &&
                                        <>

                                        </>
                                    }
                                </Grid>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Grid container>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Segment padded>
                                            <Grid container>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Header as="h6">
                                                            <Icon name="box" />
                                                            Order summary
                                                        </Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                {!!vat && !!vatAmount &&
                                                    <>
                                                        <Grid.Row>
                                                            <Grid.Column width={8}>
                                                                <Header as="h6">Subtotal</Header>
                                                            </Grid.Column>
                                                            <Grid.Column width={8} textAlign="right">
                                                                <Header as="h5">{SouthAfricanRand.format(round(cartTotal, 2))}</Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={8}>
                                                                <Header as="h6">VAT</Header>
                                                                <sub>(VAT: {vat}%)</sub>
                                                            </Grid.Column>
                                                            <Grid.Column width={8} textAlign="right">
                                                                <Header as="h5">{SouthAfricanRand.format(round(vatAmount, 2))}</Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={8}>
                                                                <Header as="h6">Delivery fee</Header>
                                                            </Grid.Column>
                                                            <Grid.Column width={8} textAlign="right">
                                                                <Header as="h5">{deliveryFeeToDisplay()}</Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={8}>
                                                                <Header as="h6">Total</Header>
                                                            </Grid.Column>
                                                            <Grid.Column width={8} textAlign="right">
                                                                <Header as="h5">{SouthAfricanRand.format(round(cartTotal + vatAmount + logisticsFee ?? 0, 2))}</Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </>
                                                }
                                          </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {!showSaveChanges &&
                                            <Grid.Row textAlign="right">
                                                <Grid.Column width={16}>
                                                    <Button type="button"
                                                            fluid
                                                            className="blue-button"
                                                            onClick={gotoCheckoutSummaryPage}>
                                                        Continue
                                                        <Icon name="arrow right"/>
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </div>
    )
}

export default CheckoutShippingAddress;