import Cookies from "js-cookie";
import {storageKeys} from "./storageKeys";
import axios from "axios";

const idpBaseUrl = process.env.REACT_APP_IDP_URL;

export const getFullTradeAccount = async (tradeAccountId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${idpBaseUrl}/api/tradeAccount/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `full/${tradeAccountId}`;

        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                } else if (error.request) {
                    console.error('Network Error:', error.request);
                } else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};