import React, {useState, useEffect} from 'react'
import {
    Accordion,
    Button,
    Card, Dimmer,
    Divider,
    Form, Grid,
    Header,
    Icon,
    Segment
} from "semantic-ui-react";
import {search} from "../../api/searchController"
import { capitalize, cloneDeep, take } from "lodash";
import GridItem from "../GridItem";
import {Desktop, Mobile, Tablet} from "../MediaComponent";
import SearchResultProductType from "../SearchResultProductType";
import {BeatLoader} from "react-spinners";
import {storage_vehicle} from "../../utils/constants";
import {useCart} from "react-use-cart";
import { getManufacturers, getModels, getVariants, getYears } from "../../api/vehicleController";


const getUrlParameter = parameterName => {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === parameterName) {
            return sParameterName[1] === undefined ? false : decodeURIComponent(sParameterName[1]);
        }
    }
};

const fetchVehicleData = async ({
                                    userInput,
                                    setManufacturerOptions,
                                    setModelOptions,
                                    setVariantOptions,
                                    setYearOptions,
                                    setLoadingUserInput
                                }) => {
    setLoadingUserInput(true);
    try {
        const manufacturersResponse = await getManufacturers(userInput.year);
        const yearsResponse = await getYears(userInput.manufacturer, userInput.model, userInput.variant);
        if (userInput.manufacturer || userInput.year) {
            const modelsResponse = await getModels(userInput.manufacturer, userInput.year);
            if (modelsResponse?.success) {
                const sortedModels = modelsResponse.data.sort();

                const modelOptions = sortedModels.map(model => {
                    return {
                        key: model,
                        text: capitalize(model),
                        value: model
                    }
                });
                setModelOptions(modelOptions);
            }

        }
        if (userInput.manufacturer || userInput.model) {
            const variantsResponse = await getVariants(userInput.manufacturer, userInput.model, userInput.year);
            if (variantsResponse?.success) {
                const sortedVariants = variantsResponse.data.sort();
                const variantOptions = sortedVariants.map(variant => {
                    return {
                        key: variant,
                        text: capitalize(variant),
                        value: variant
                    }
                });
                setVariantOptions(variantOptions);
            }
        }

        if (manufacturersResponse?.success) {
            const sortedManufacturers = manufacturersResponse.data.sort();
            const manufacturerOptions = sortedManufacturers.map(x => {
                return {
                    key: x,
                    text: capitalize(x),
                    value: x
                }
            });
            setManufacturerOptions(manufacturerOptions);
        }

        if (yearsResponse?.success) {
            const sortedYears = yearsResponse.data.sort();
            const yearOptions = sortedYears.map(x => {
                return {
                    key: x,
                    text: x,
                    value: x
                }
            });
            setYearOptions(yearOptions);
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoadingUserInput(false);
    }
}

const performSearch = async ({
    searchTerm,
    setProductTypeSearchResults,
    setSearchTermSearchResults,
    setLoadingSearchResults,
    accessories,
    setAccordionActiveIndex
}) => {
    setLoadingSearchResults(true);
    try {
        const response = await search(searchTerm, accessories);
        if (response.success) {
            setProductTypeSearchResults(response.data.productTypeSearchResults);
            setSearchTermSearchResults(response.data.searchStringResults);
            setAccordionActiveIndex(response.data.searchStringResults.length > 0 ? -1 : 0);
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoadingSearchResults(false);
    }
}


const SearchResult = () => {
    const {items, setItems} = useCart();
    const [loadingUserInput, setLoadingUserInput] = useState(true);
    const [loadingSearchResults, setLoadingSearchResults] = useState(true);
    const [productTypeSearchResults, setProductTypeSearchResults] = useState([]);
    const [searchTermSearchResults, setSearchTermSearchResults] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);
    const [userInput, setUserInput] = useState({
        manufacturer: null,
        model: null,
        variant: null,
        year: null
    });
    const [currentVehicle, setCurrentVehicle] = useState({
        manufacturer: null,
        model: null,
        variant: null,
        year: null
    });
    const [normalSearch, setNormalSearch] = useState(true);
    const [accessoriesSearchTerm, setAccessoriesSearchTerm] = useState(null);
    const [accordionActiveIndex, setAccordionActiveIndex] = useState(-1);

    useEffect(() => {
        const typeParam = getUrlParameter("t");
        if (typeParam === "p") {
            setNormalSearch(true);
            const searchTermParam = getUrlParameter("q");
            let localStorageVehicle = localStorage.getItem(storage_vehicle);
            if (localStorageVehicle) {
                const savedUserInput = JSON.parse(localStorageVehicle);
                setUserInput(savedUserInput);
                setCurrentVehicle(savedUserInput);
            }
            performSearch({
                searchTerm: searchTermParam,
                setProductTypeSearchResults,
                setSearchTermSearchResults,
                setLoadingSearchResults,
                setAccordionActiveIndex
            });
        }
        else if (typeParam === "a") {
            setNormalSearch(false);
            performSearch({
                searchTerm: "",
                setProductTypeSearchResults,
                setSearchTermSearchResults,
                setLoadingSearchResults,
                setAccordionActiveIndex,
                accessories: true
            });
        }
    }, []);

    useEffect(() => {
        const fetchVehicleDataOptions = {
            userInput,
            setManufacturerOptions,
            setModelOptions,
            setVariantOptions,
            setYearOptions,
            setLoadingUserInput
        }
        fetchVehicleData(fetchVehicleDataOptions);
    }, [userInput]);

    const handleUserVehicleChange = (_, {name, value}) => {
        let userInputCopy = cloneDeep(userInput);
        userInputCopy[name] = value;

        if (name === 'manufacturer') {
            userInputCopy.model = null;
            userInputCopy.variant = null;
            userInputCopy.year = null;
        }
        else if (name === 'model') {
            userInputCopy.variant = null;
            userInputCopy.year = null;
        }
        else if (name === 'variant') {
            userInputCopy.year = null;
        }
        setUserInput(userInputCopy);
    }

    const submitUserInput = () => {
        let jsonUserInput = JSON.stringify(userInput);
        localStorage.setItem(storage_vehicle, jsonUserInput);
        setCurrentVehicle(userInput);
        //notifySuccess("Vehicle successfully saved.")
    }

    const clearSavedVehicle = () => {
        localStorage.removeItem(storage_vehicle);
        const nullCar = {
            manufacturer: null,
            model: null,
            variant: null,
            year: null
        }
        setCurrentVehicle(nullCar);
        setUserInput(nullCar);
        //notifySuccess("Vehicle successfully saved.")
    }

    const goToProductPage = () => {
        let newHref = "/result?";
        let params = [];
        if (userInput.manufacturer) {
            params.push(`make=${userInput.manufacturer}`)
        }
        if (userInput.year) {
            params.push(`year=${userInput.year}`)
        }
        if (userInput.model) {
            params.push(`model=${userInput.model}`)
        }
        if (userInput.variant) {
            params.push(`variant=${userInput.variant}`)
        }
        const paramString = params.join("&");
        window.location.href = newHref + paramString;
    }

    const handleAccessorySearch = () => {
        performSearch({
            searchTerm: accessoriesSearchTerm,
            setProductTypeSearchResults,
            setSearchTermSearchResults,
            setLoadingSearchResults,
            accessories: true
        });
    }

    const handleAccessorySearchTermChange = (_, {value}) => {
        setAccessoriesSearchTerm(value);
    }

    const onAccordionTitleClick = (e, {index}) => {
        const newIndex = accordionActiveIndex === index ? -1 : index
        setAccordionActiveIndex(newIndex);
    }

    const userInputIsNotEmpty = () => {
        return userInput.manufacturer || userInput.year || userInput.model || userInput.variant
    }

    const savedVehicleIsNotEmpty = () => {
        return currentVehicle.manufacturer || currentVehicle.year || currentVehicle.model || currentVehicle.variant
    }

    const userInputDoesNotEqualSavedVehicle = () => {
        return currentVehicle.manufacturer !== userInput.manufacturer ||
            currentVehicle.year !== userInput.year ||
            currentVehicle.model !== userInput.model ||
            currentVehicle.variant !== userInput.variant;
    }

    const handleUpdateQuantity = (productId, quantity) => {
        const itemsCopy = cloneDeep(items);
        const itemToUpdate = itemsCopy.find(x => x.id === productId);
        itemToUpdate.quantity = quantity;
        setItems(itemsCopy);
    }

    return (
        <div>
            <Segment raised padded="very">
                {normalSearch &&
                    <div>
                        <Segment padded className="bg-argus-blue">
                            <Header color="green" size="mini" textAlign="center">
                                Please select your vehicle
                            </Header>
                            <Form className="mb-3" size="mini" inverted>
                                <Form.Group inline widths="equal">
                                    <Form.Select
                                        fluid
                                        name="manufacturer"
                                        label='Manufacturer'
                                        options={manufacturerOptions}
                                        placeholder='Manufacturer'
                                        value={userInput.manufacturer}
                                        onChange={handleUserVehicleChange}
                                        loading={loadingUserInput}
                                        search
                                        clearable
                                    />
                                    <Form.Select
                                        fluid
                                        name="model"
                                        label='Model'
                                        options={modelOptions}
                                        placeholder='Model'
                                        value={userInput.model}
                                        onChange={handleUserVehicleChange}
                                        loading={loadingUserInput}
                                        disabled={!userInput.manufacturer && !userInput.year}
                                        search
                                        clearable
                                    />
                                    <Form.Select
                                        fluid
                                        name="variant"
                                        label='Variant'
                                        options={variantOptions}
                                        placeholder='Variant'
                                        value={userInput.variant}
                                        onChange={handleUserVehicleChange}
                                        loading={loadingUserInput}
                                        disabled={!userInput.manufacturer && !userInput.model}
                                        search
                                        clearable
                                    />
                                    <Form.Select
                                        fluid
                                        name="year"
                                        label='Year'
                                        options={yearOptions}
                                        placeholder='Year'
                                        value={userInput.year}
                                        onChange={handleUserVehicleChange}
                                        loading={loadingUserInput}
                                        search
                                        clearable
                                    />
                                </Form.Group>
                            </Form>
                            {userInputIsNotEmpty() && userInputDoesNotEqualSavedVehicle() &&
                                <div className="mb-3">
                                    <Button className="center-x"
                                            size="mini"
                                            color="green"
                                            compact
                                            onClick={submitUserInput}>
                                        Save new vehicle details
                                    </Button>
                                </div>
                            }
                            {savedVehicleIsNotEmpty() &&
                                <div>
                                    <Divider />
                                    <Header className="text-align-center underline mb-1" style={{color: "#EEEEEE"}}>
                                        Saved vehicle
                                    </Header>
                                    <Header className="text-align-center mb-1" style={{color: "#EEEEEE"}}>
                                        {`${currentVehicle.manufacturer ? `${currentVehicle.manufacturer} ` : ""}${currentVehicle.model ? `${currentVehicle.model} ` : ""}${currentVehicle.variant ? `${currentVehicle.variant} ` : ""}${currentVehicle.year ? `${currentVehicle.year}` : ""}`}
                                    </Header>
                                    <Grid className="mb-1" centered>
                                        <Grid.Row centered>
                                            <Grid.Column textAlign="center">
                                                <Button color="green"
                                                        inverted
                                                        size="mini"
                                                        compact
                                                        onClick={goToProductPage}>
                                                    See all parts for this vehicle
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row centered>
                                            <Grid.Column textAlign="center">
                                                <Button color="red"
                                                        inverted
                                                        size="mini"
                                                        compact
                                                        onClick={clearSavedVehicle}>
                                                    Clear
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                </div>
                            }
                        </Segment>
                    </div>
                }
                {!normalSearch &&
                    <div>
                        <Segment padded="very" className="bg-argus-blue" inverted>
                            <Header color="green" size="mini" textAlign="center">Search accessories</Header>
                            <Form onSubmit={handleAccessorySearch}  size="mini">
                                <Form.Field>
                                    <Form.Input
                                        name="accessoriesSearchTerm"
                                        value={accessoriesSearchTerm}
                                        onChange={handleAccessorySearchTermChange}
                                        type="text"
                                        placeholder="Search..."
                                    />
                                </Form.Field>
                                <Button className="center-x"
                                        inverted
                                        color="green"
                                        type="submit"
                                        compact
                                        size="mini">
                                    Search
                                </Button>
                            </Form>
                        </Segment>
                    </div>
                }
                <Divider className="py-5" horizontal>OR</Divider>
                <Accordion fluid styled>
                    {productTypeSearchResults.length > 0 &&
                        <div>
                            <Accordion.Title onClick={onAccordionTitleClick} index={0} active={accordionActiveIndex === 0}>
                                <Header className="mt-1" color="green" size="mini" textAlign="center">Please choose a category</Header>
                                <p className="text-align-center">{`Click to ${accordionActiveIndex === 0 ? "close" : "view"}`}</p>
                            </Accordion.Title>
                            <Accordion.Content onClick={onAccordionTitleClick} index={0} active={accordionActiveIndex === 0}>
                                {loadingSearchResults && <Grid style={{height: "50vh"}}>
                                    <Grid.Row className="mt-5 mb-5">
                                        <Dimmer active inverted>
                                            <BeatLoader color="#36d7b7" />
                                            <p>Please wait...</p>
                                        </Dimmer>
                                    </Grid.Row>
                                </Grid>}
                                {!loadingSearchResults &&
                                    <Desktop>
                                        <Card.Group itemsPerRow={5}>
                                            {productTypeSearchResults.map((searchResult, index) => {
                                                return <SearchResultProductType
                                                    key={index}
                                                    index={index}
                                                    searchResult={searchResult}
                                                    userInput={userInput}
                                                    normalSearch={normalSearch}
                                                />
                                            })}
                                        </Card.Group>
                                    </Desktop>
                                }
                                {!loadingSearchResults &&
                                    <Tablet>
                                        <Card.Group itemsPerRow={2}>
                                            {productTypeSearchResults.map((searchResult, index) => {
                                                return <SearchResultProductType
                                                    key={index}
                                                    index={index}
                                                    searchResult={searchResult}
                                                    userInput={userInput}
                                                    normalSearch={normalSearch}
                                                />
                                            })}
                                        </Card.Group>
                                    </Tablet>
                                }
                                {!loadingSearchResults &&
                                    <Mobile>
                                        <Card.Group itemsPerRow={1}>
                                            {productTypeSearchResults.map((searchResult, index) => {
                                                return <SearchResultProductType
                                                    key={index}
                                                    index={index}
                                                    searchResult={searchResult}
                                                    userInput={userInput}
                                                    normalSearch={normalSearch}
                                                />
                                            })}
                                        </Card.Group>
                                    </Mobile>
                                }
                            </Accordion.Content>
                        </div>
                    }
                    {searchTermSearchResults.length > 0 &&
                        <div>
                            <Accordion.Title onClick={onAccordionTitleClick} index={1} active={accordionActiveIndex === 1}>
                                <Header className="mt-1" color="green" size="mini" textAlign="center">Products found</Header>
                                <p className="text-align-center">{`Click to ${accordionActiveIndex === 1 ? "close" : "view"}`}</p>
                            </Accordion.Title>
                            <Accordion.Content onClick={onAccordionTitleClick} index={1} active={accordionActiveIndex === 1}>
                                {loadingSearchResults && <Grid style={{height: "50vh"}}>
                                    <Grid.Row className="mt-5 mb-5">
                                        <Dimmer active inverted>
                                            <BeatLoader color="#36d7b7" />
                                            <p>Please wait...</p>
                                        </Dimmer>
                                    </Grid.Row>
                                </Grid>}
                                {!loadingSearchResults &&
                                    <Desktop>
                                        <Card.Group itemsPerRow={3}>
                                            {take(searchTermSearchResults, 10).map((searchResult, index) => {
                                                const qtyInCart = items.filter(x => x.id === searchResult.id).length
                                                return <GridItem
                                                    key={index}
                                                    title={searchResult.title}
                                                    price={searchResult.price_max}
                                                    image={searchResult.src}
                                                    productId={searchResult.id}
                                                    handle={searchResult.handle}
                                                    vendorImage={searchResult.vendor_image}
                                                    vendorDescription={searchResult.vendor_desc}
                                                    quality={searchResult.quality}
                                                    availability={searchResult.product_availability}
                                                    warranty={searchResult.product_warranty}
                                                    warrantyText={searchResult.product_warranty_text}
                                                    applicationPosition={searchResult.application_position_desc}
                                                    applicationPositionImage={searchResult.application_position_image}
                                                    bodyHtml={searchResult.body_html}
                                                    updateCart={handleUpdateQuantity}
                                                    quantityInCart={qtyInCart}
                                                />
                                            })}
                                        </Card.Group>
                                    </Desktop>
                                }
                                {!loadingSearchResults &&
                                    <Tablet>
                                        <Card.Group itemsPerRow={2}>
                                            {searchTermSearchResults.map((searchResult, index) => {
                                                const qtyInCart = items.filter(x => x.id === searchResult.id).length
                                                return <GridItem
                                                    key={index}
                                                    title={searchResult.title}
                                                    price={searchResult.price_max}
                                                    image={searchResult.src}
                                                    productId={searchResult.id}
                                                    handle={searchResult.handle}
                                                    vendorImage={searchResult.vendor_image}
                                                    vendorDescription={searchResult.vendor_desc}
                                                    quality={searchResult.quality}
                                                    availability={searchResult.product_availability}
                                                    warranty={searchResult.product_warranty}
                                                    warrantyText={searchResult.product_warranty_text}
                                                    applicationPosition={searchResult.application_position_desc}
                                                    applicationPositionImage={searchResult.application_position_image}
                                                    bodyHtml={searchResult.body_html}
                                                    quantityInCart={qtyInCart}
                                                    updateCart={handleUpdateQuantity}
                                                />
                                            })}
                                        </Card.Group>
                                    </Tablet>
                                }
                                {!loadingSearchResults &&
                                    <Mobile>
                                        <Card.Group itemsPerRow={1}>
                                            {searchTermSearchResults.map((searchResult, index) => {
                                                const qtyInCart = items.filter(x => x.id === searchResult.id).length
                                                return  <GridItem
                                                    key={index}
                                                    title={searchResult.title}
                                                    price={searchResult.price_max}
                                                    image={searchResult.src}
                                                    productId={searchResult.id}
                                                    handle={searchResult.handle}
                                                    vendorImage={searchResult.vendor_image}
                                                    vendorDescription={searchResult.vendor_desc}
                                                    quality={searchResult.quality}
                                                    availability={searchResult.product_availability}
                                                    warranty={searchResult.product_warranty}
                                                    warrantyText={searchResult.product_warranty_text}
                                                    applicationPosition={searchResult.application_position_desc}
                                                    applicationPositionImage={searchResult.application_position_image}
                                                    bodyHtml={searchResult.body_html}
                                                    updateCart={handleUpdateQuantity}
                                                    quantityInCart={qtyInCart}
                                                />
                                            })}
                                        </Card.Group>
                                    </Mobile>
                                }
                            </Accordion.Content>
                        </div>
                    }
                </Accordion>
            </Segment>
        </div>
    );
}

export default SearchResult;