import React from "react";
import {Box, Modal} from "@mui/material";
import {Button, Grid, Header, Image} from "semantic-ui-react";

export const confirmationChoices = {
    cancel: "cancel",
    confirm: "confirm",
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: 4,
};


export const ConfirmationModal = ({
    open,
    closeModal,
    heading,
    subHeading,
    content,
    disableConfirm,
    confirmLabel = "Confirm",
    confirmLoading,
    rejectLabel = "Cancel",
    forChanges
}) => {
    return (
        <Modal open={open} onClose={closeModal(confirmationChoices.cancel)}>
            <Box sx={style}>
                <Grid container>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16}>
                            <Image className="center-x" src="https://argusweb.azureedge.net/argus-web/logo.svg" size="small" />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16}>
                            <Header as="h4">{heading}</Header>
                            <p className="b2">{subHeading}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16}>
                            {content}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Button floated='right'
                                    type="button"
                                    className="blue-button"
                                    onClick={closeModal(confirmationChoices.confirm)}
                                    disabled={disableConfirm || confirmLoading}>
                                {forChanges ? "Save Changes" : confirmLabel}
                            </Button>
                            <Button floated="right"
                                    onClick={closeModal(confirmationChoices.cancel)}
                                    type="button">
                                {forChanges ? "Discard Changes": rejectLabel}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Box>
        </Modal>
    );
};
export default ConfirmationModal;