import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import PaymentConfirmation from "../../components/PaymentConfirmation";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getCurrentUser} from "../../api/authenticationController";
import { getPayfastResponse, postPayfastResponse } from "../../api/payfastController";
import {
    ApiCallStatus,
    LoggedInStatus,
    PayfastResponsePaymentStatus,
    PaymentMethod,
    PaymentStatus,
    PaymentType
} from "../../utils/constants";
import { postPayment } from "../../api/paymentController";
import {useCart} from "react-use-cart";
import {CreateUUID} from "../../utils/helpers";

const checkIsLoggedIn = async ({
   setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e){
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const fetchPayfastResponseData = async ({
    orderId,
    setPayfastResponse,
    setPayfastResponseCallStatus
}) => {
    setPayfastResponseCallStatus(ApiCallStatus.InProgress);
    try{
        let response = await getPayfastResponse(orderId);
        if (response?.success) {
            setPayfastResponse(response?.data);
            setPayfastResponseCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setPayfastResponseCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error){
        console.error(error);
        setPayfastResponseCallStatus(ApiCallStatus.Error);
    }
}

const createPayment = async ({
    paymentId,
    orderId,
    amount,
    type,
    status,
    setCreatePaymentCallStatus
}) => {
    setCreatePaymentCallStatus(ApiCallStatus.InProgress);
    try{
        const createPaymentOptions = {
            paymentId,
            orderId,
            amount,
            type,
            status
        }
        const response = await postPayment(createPaymentOptions);

        if (response?.success) {
            setCreatePaymentCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCreatePaymentCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error){
        console.error(error);
        setCreatePaymentCallStatus(ApiCallStatus.Error);
    }
}

const createPayfastResponse = async ({
    payfastResponse,
    setCreatePayfastResponseCallStatus
}) => {
    setCreatePayfastResponseCallStatus(ApiCallStatus.InProgress);
    try{
        const response = await postPayfastResponse(payfastResponse);

        if (response?.success) {
            setCreatePayfastResponseCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCreatePayfastResponseCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error){
        console.error(error);
        setCreatePayfastResponseCallStatus(ApiCallStatus.Error)
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};

const PayfastConfirmationPage = () => {
    const { orderId } = useParams();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [payfastResponse, setPayfastResponse] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [payfastResponseCallStatus, setPayfastResponseCallStatus] = useState(ApiCallStatus.NotStarted);
    const [createPaymentCallStatus, setCreatePaymentCallStatus] = useState(ApiCallStatus.NotStarted);
    const [createPayfastResponseCallStatus, setCreatePayfastResponseCallStatus] = useState(ApiCallStatus.NotStarted);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchPayfastResponseDataAsync = async () => {
        await fetchPayfastResponseData({
            orderId,
            setPayfastResponse,
            setPayfastResponseCallStatus
        });
    }

    const createPayfastResponseDataAsync = async ({
        payfastResponse,
        setCreatePayfastResponseCallStatus
    }) => {
        await createPayfastResponse({
            payfastResponse,
            setCreatePayfastResponseCallStatus
        });
    }

    const createPaymentAsync = async ({
        paymentId,
        orderId,
        amount,
        type,
        status
    }) => {
        await createPayment({
            paymentId,
            orderId,
            amount,
            type,
            status,
            setCreatePaymentCallStatus
        })
    }

    useEffect(() => {
        performLoggedInCheck();

        if (!!orderId){
            fetchPayfastResponseDataAsync()
        }
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes){
            fetchCurrentUserAsync();
        }
        else if (loggedIn === LoggedInStatus.No){
            clearCookiesAsync();
        }
    }, [loggedIn])

    useEffect(() => {
        if (!!payfastResponse) {
            createPayfastResponseDataAsync({
                payfastResponse,
                setCreatePayfastResponseCallStatus
            });
        }
    }, [payfastResponse]);

    useEffect(() => {
        if (createPayfastResponseCallStatus === ApiCallStatus.Succeeded) {
            createPaymentAsync ({
                paymentId: CreateUUID(),
                orderId: payfastResponse.merchantPaymentId,
                amount: payfastResponse.amountGross,
                type: PaymentType.PayFast,
                status: payfastResponse.paymentStatus === PayfastResponsePaymentStatus.Complete ? PaymentStatus.Complete : PaymentStatus.Failed,
                setCreatePaymentCallStatus
            });
        }
    }, [createPayfastResponseCallStatus]);

    const allLoadingStates = [
        currentUserCallStatus,
        payfastResponseCallStatus,
        createPaymentCallStatus,
        createPayfastResponseCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default PayfastConfirmationPage;
