import {toLower} from "lodash";
import {PriceCategory} from "../utils/constants";

export const calculateTradePriceCategory = (category) => {
    if (!category) return PriceCategory.Retail;

    const lowercaseCategory = toLower(category);
    switch (lowercaseCategory) {
        case PriceCategory.Trade:
            return PriceCategory.Trade;
        case PriceCategory.Wholesale:
            return PriceCategory.Wholesale;
        case PriceCategory.Wholesale2:
            return PriceCategory.Wholesale2;
        case PriceCategory.Wholesale3:
            return PriceCategory.Wholesale3;
        default:
            return PriceCategory.Retail;
    }
}