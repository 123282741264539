const prefix = process.env.REACT_APP_STORAGE_KEY_PREFIX || 'argus-';
export const storageKeys = {
    ACCESS_TOKEN: prefix + `${process.env.NREACT_APP_STORAGE_KEY_ACCESS_TOKEN || 'access_token'}`,
    ACCESS_TOKEN_EXPIRES_AT: prefix + `${process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN_EXPIRES_AT || 'access_token_expires_at'}`,
    REFRESH_TOKEN: prefix + `${process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN || 'refresh_token'}`,
    REFRESH_EXPIRES_AT: prefix + `${process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN_EXPIRES_AT || 'refresh_expires_at'}`,
    USERNAME: prefix + `${process.env.REACT_APP_STORAGE_KEY_EMAIL || 'email'}`,
    USER_ID: prefix + `${process.env.REACT_APP_STORAGE_KEY_USER_ID || 'user_id'}`,
    FIRSTNAME: prefix + `${process.env.REACT_APP_STORAGE_KEY_FIRST_NAME || 'first_name'}`,
    LASTNAME: prefix + `${process.env.REACT_APP_STORAGE_KEY_LAST_NAME || 'last_name'}`,
    TRADE_ACCOUNTS: prefix + `${process.env.REACT_APP_STORAGE_KEY_TRADE_ACCOUNTS || 'trade_accounts'}`,
    CURRENT_TRADE_ACCOUNT: prefix + `${process.env.REACT_APP_STORAGE_KEY_CURRENT_TRADE_ACCOUNT || 'current_trade_account'}`,
    CHECKOUT_ID: prefix + `${process.env.REACT_APP_STORAGE_KEY_CHECKOUT_ID || 'checkout_id'}`,
    ORDER_ID: prefix + `${process.env.REACT_APP_STORAGE_KEY_ORDER_ID || 'order_id'}`,
    NEW_CHECKOUT_SHIPPING_ADDRESS: prefix + `${process.env.REACT_APP_STORAGE_KEY_CHECKOUT_DELIVERY_ADDRESS || 'checkout_delivery_address'}`,
    NEW_CHECKOUT_BILLING_ADDRESS: prefix + `${process.env.REACT_APP_STORAGE_KEY_CHECKOUT_BILLING_ADDRESS || 'checkout_billing_address'}`,
    GUEST_CURRENT_VEHICLE: prefix + `${process.env.REACT_APP_STORAGE_KEY_GUEST_CURRENT_VEHICLE || 'guest_current_vehicle'}`,
    GUEST_SAVED_VEHICLES: prefix + `${process.env.REACT_APP_STORAGE_KEY_GUEST_SAVED_VEHICLES || 'guest_saved_vehicles'}`,
    ANNOUNCEMENT_MODAL_BETA_ACKNOWLEDGEMENT: prefix + '2023-11-02_announcement',
    USER_ANNOUNCEMENTS: prefix + `user_announcements`,
};