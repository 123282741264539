import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import {useCart} from "react-use-cart";
import { Dropdown, Grid, Header, Icon, Image, Input, Label, Menu } from "semantic-ui-react";
import {getItem} from "../../utils/storageHelper";
import {storageKeys} from "../../api/storageKeys";
import SavedVehiclesModal from "../SavedVehiclesModal";
import SavedVehicleModal from "../SavedVehicleModal";
import ChooseVehicleModal from "../ChooseVehicleModal";
import SearchModal from "../SearchModal";

const modal_name = {
  savedVehicle: "savedVehicle",
  savedVehicles: "savedVehicles",
  chooseVehicle: "chooseVehicle",
  search: "search",
  announcement: "announcement"
}

const SiteHeader = ({ currentUser }) => {
  const navigate = useNavigate();
  const {totalItems} = useCart();
  const [openModal, setOpenModal] = useState('');
  const [currentPage, setCurrentPage] = useState(null);
  const [totalSavedVehicles, setTotalSavedVehicles] = useState(0);
  const location = useLocation();

  const pagesToExclude = ["login", "register", "account-verification", "forgot-password"];

  useEffect(() => {
    const savedVehiclesString = getItem(storageKeys.GUEST_SAVED_VEHICLES);

    if (!!savedVehiclesString) {
      const savedVehiclesObject = JSON.parse(savedVehiclesString);
      setTotalSavedVehicles(savedVehiclesObject.length)
    }
  }, []);

  useEffect(() => {
    const announcementModalAcknowledged = getItem(storageKeys.ANNOUNCEMENT_MODAL_BETA_ACKNOWLEDGEMENT)

    const pathName = location.pathname;
    const pageName = pathName.split("/").pop();

    if (!announcementModalAcknowledged && pageName === "/") {
      openAnnouncementModal();
    }

    setCurrentPage(pageName);
  }, [location]);

  const handleSavedVehiclesClick = (e) => {
    e.preventDefault();
    openSavedVehiclesModal();
  };

  const openSavedVehiclesModal = () => {
      setOpenModal(modal_name.savedVehicles)
  }

  const openSavedVehicleModal = () => {
      setOpenModal(modal_name.savedVehicle)
  }

  const openChooseVehicleModal = () => {
      setOpenModal(modal_name.chooseVehicle)
  }

  const openSearchModal = () => {
      setOpenModal(modal_name.search)
  }

  const openAnnouncementModal = () => {
    setOpenModal(modal_name.announcement)
  }

  const closeModals = () => {
      setOpenModal('');
  }

  const gotoLoginPage = () => {
      return navigate('/login', { state: { from: location } });
  };

  const gotoProductPage = (sku) => {
      closeModals();
      navigate(`/product/${sku}`);
  }

  return (
    <>
      {!pagesToExclude.includes(currentPage) &&
          <header className="header-container">
            <Grid padded>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2}>
                  <Image as={Link} to="/" src="https://argusweb.azureedge.net/argus-web/logo.svg" alt="Argus" size="small" />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Menu className="argus-menu" borderless fluid>
                    <Menu.Menu position='left'>
                      <Menu.Item as={Link}
                                 to="/about">
                        About us
                      </Menu.Item>
                      <Menu.Item as={Link}
                                 to="/team">
                        Meet the team
                      </Menu.Item>
                      <Menu.Item as={Link}
                                 to="/faq">
                        FAQ
                      </Menu.Item>
                      <Menu.Item as={Link}
                                 to="/contact-us">
                        Contact us
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Input onClick={openSearchModal}
                         size="large"
                         fluid
                         icon='search'
                         value={""}
                         placeholder='Search...'/>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Grid container>
                    <Grid.Row textAlign="center">
                      <Grid.Column width={16}>
                        <a href="tel:27117475200" target="_blank">
                          <Icon name="phone" />
                          (+27)11 747 5200
                        </a>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row textAlign="center">
                      <Grid.Column width={16}>
                        <a href="https://api.whatsapp.com/send?phone=27836545120" target="_blank">
                          <Icon name="whatsapp" />
                          WhatsApp chat
                        </a>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                </Grid.Column>
                <Grid.Column width={3}>
                  <Menu className="argus-menu" borderless fluid>
                    <Menu.Menu position='right'>
                      {!currentUser &&
                          <>
                            <Menu.Item
                                name='Login'
                                onClick={gotoLoginPage}
                            >
                              Login
                            </Menu.Item>

                            <Menu.Item
                                name='Register'
                                as={Link}
                                to="/register"
                            >
                              Register
                            </Menu.Item>
                            <Menu.Item as={Link}
                                       onClick={handleSavedVehiclesClick}
                                       to="#">
                              <Icon name='warehouse'/>
                              <Label color='red' floating circular>
                                {totalSavedVehicles}
                              </Label>
                            </Menu.Item>
                            <Menu.Item as={Link}
                                       to="/cart">
                              <Icon name='shopping cart'/>
                              <Label color='red' floating circular>
                                {totalItems}
                              </Label>
                            </Menu.Item>
                          </>
                      }
                      {!!currentUser &&
                          <>
                            <Dropdown item text='My Account'>
                              <Dropdown.Menu>
                                <Dropdown.Item as={Link}
                                                 to="/logout">Sign out</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Menu.Item as={Link}
                                       onClick={handleSavedVehiclesClick}
                                       to="#">
                              <Icon name='warehouse'/>
                              <Label color='red' floating circular>
                                {totalSavedVehicles}
                              </Label>
                            </Menu.Item>
                            <Menu.Item as={Link}
                                       to="/cart">
                              <Icon name='shopping cart'/>
                              <Label color='red' floating circular>
                                {totalItems}
                              </Label>
                            </Menu.Item>
                          </>
                      }
                    </Menu.Menu>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </header>
      }
      {openModal === modal_name.savedVehicle &&
          <SavedVehicleModal open={openModal === modal_name.savedVehicle}
                             setOpen={openSavedVehicleModal}
                             setClose={closeModals}
                             openSavedVehiclesModal={openSavedVehiclesModal}
          />
      }
      {openModal === modal_name.savedVehicles &&
          <SavedVehiclesModal open={openModal === modal_name.savedVehicles}
                              setOpen={openSavedVehiclesModal}
                              setClose={closeModals}
                              openChooseVehicleModal={openChooseVehicleModal}
          />
      }
      {openModal === modal_name.chooseVehicle &&
          <ChooseVehicleModal
              open={openModal === modal_name.chooseVehicle}
              setOpen={openChooseVehicleModal}
              setClose={closeModals}
          />
      }
      {openModal === modal_name.search &&
          <SearchModal
              open={openModal === modal_name.search}
              setOpen={openSearchModal}
              setClose={closeModals}
              currentUser={currentUser}
              gotoProductPage={gotoProductPage}
          />
      }
    </>
  );
};

export default SiteHeader;
