import React, {useEffect} from "react";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import {getVendors} from "../../api/searchController";
import { includes, sortBy, take, filter, orderBy, find, takeRight } from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";


const getVendorsData = async ({
    setVendorSearchResults
}) => {
    // setLoading(true);
    try {
        const response = await getVendors();
        if (response.success) {
            setVendorSearchResults(response.data);
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        // setLoading(false);
    }
}

const VendorSlider = () => {
    const [vendorSearchResults, setVendorSearchResults] = React.useState([]);
    const [vendors, setVendors] = React.useState([]);
    const staticVendorOrder = [
        {vendor: "ngk", vendorOrder: 1},
        {vendor: "kyb", vendorOrder: 2},
        {vendor: "fram", vendorOrder: 3},
        {vendor: "doe", vendorOrder: 4},
        {vendor: "beta", vendorOrder: 5},
        {vendor: "shield", vendorOrder: 6},
        {vendor: "q20", vendorOrder: 7},
        {vendor: "ate", vendorOrder: 8},
        {vendor: "optima", vendorOrder: 9},
        {vendor: "teknosa", vendorOrder: 10},
    ]

    useEffect(() => {
        const getVendorsAsync = async () => {
            const getVendorsDataOptions = {
                setVendorSearchResults
            }
            await getVendorsData(getVendorsDataOptions);
        }

        getVendorsAsync();
    }, [])

    useEffect(() => {
        if (!!vendorSearchResults) {
            const vendorNames = staticVendorOrder.map(v => v.vendor);
            const filteredVendors = filter(vendorSearchResults, v => includes(vendorNames, v.vendor_Name.replace(" ", "_").toLowerCase()));
            const filteredVendorsWithOrder = filteredVendors.map(v => {
                const staticVendor = find(staticVendorOrder, s => s.vendor === v.vendor_Name.replace(" ", "_").toLowerCase());
                return {...v, order: staticVendor.vendorOrder}
            })
            const orderedVendors = orderBy(filteredVendorsWithOrder, v => v.order);
            setVendors(orderedVendors);
        }
    }, [vendorSearchResults]);

    return (
        <div className="mt-5 mb-5">
            <Grid padded>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Swiper
                            modules={[Autoplay, Navigation]}
                            spaceBetween={50}
                            slidesPerView={6}
                            navigation
                            loop={true}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: true,
                            }}
                            onSwiper={(swiper) => console.log("")}>
                            {vendors.map((vendor, index) => {
                                const link = `/products?vendor=${vendor.vendor_Name.replace(" ", "_").toLowerCase()}`;
                                return (
                                    <SwiperSlide key={index}>
                                        <img key={index} src={vendor.vendor_Image} alt={`Slide ${index + 1}`} href={link} />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default VendorSlider;