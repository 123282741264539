import React, {useEffect} from "react";
import { Button, Grid, Image, Segment } from "semantic-ui-react";

const PaymentEft = ({
    paymentOrderInfo,
    onSubmit
}) => {
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Payment</div>
            </div>
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment padded>
                            <Grid container>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Image src="https://argusweb.azureedge.net/argus-web/payment/eft.svg" size="small" fluid centered/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row textAlign="center">
                                    <Grid.Column width={16}>
                                        <p className="b1 mb-3">Direct EFT.</p>
                                        <p className="b2">Please note that shipment will only take place once the payment reflects in our account.</p>
                                        <p className="b2">(Payment is through Nedbank or Standard Bank)</p>
                                    </Grid.Column>
                                </Grid.Row>
                                {!!paymentOrderInfo &&
                                    <>
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                    <Button className="blue-button"
                                                            type="button"
                                                            onClick={onSubmit}>
                                                        Pay
                                                    </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                }
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )

}

export default PaymentEft;