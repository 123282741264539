import React from "react";
import {Card, Image} from "semantic-ui-react";


const SearchResultProductType = ({
                                            index,
                                            searchResult,
                                            userInput,
                                            normalSearch
                                        }) => {

//TODO
    let baseUrl = `/products?product_category=${encodeURI(searchResult.category)}`;
    baseUrl += !normalSearch ? `&a=${true}` : "";
    baseUrl += userInput.manufacturer ? `&make=${encodeURI(userInput.manufacturer)}` : "";
    baseUrl += userInput.year ? `&year=${userInput.year}` : "";
    baseUrl += userInput.model ? `&model=${encodeURI(userInput.model)}` : "";
    baseUrl += userInput.variant ? `&variant=${encodeURI(userInput.variant)}` : "";

    return <Card key={index}>
        <Image src={searchResult.image} wrapped ui={false} />
        <Card.Content className="no-flex-growth">
            <Card.Header>
                <a href={baseUrl}>{searchResult.category.replaceAll('_', ' ')}</a>
            </Card.Header>
        </Card.Content>
        <Card.Content>
            <Card.Description className="overflowY">
                {searchResult.productTypes.map((productType, index2) => {
                    let newUrl = baseUrl + `&pt=${encodeURI(productType)}`;
                    return <div style={{display: "block"}}>
                        <a key={index2} className="search-result-product-type-link mb-3" href={newUrl}>{productType.replaceAll('_', ' ')}</a>
                    </div>
                })}
            </Card.Description>
        </Card.Content>
    </Card>
}

export default SearchResultProductType;