import React, { useState, useRef, useMemo, useEffect } from "react";
import { debounce } from "lodash";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import "./style.css";
import { Grid as SemGrid, Image } from "semantic-ui-react";
import GoogleLogo from "../../img/google_on_white.png";

const GOOGLE_MAPS_API_KEY = process.env.REACT_GOOGLE_MAPS_API_KEY ?? "AIzaSyB4Lu-jVIbxq2RU5lu6jeThdCR2wPdmHb4";

const loadScript = (src, position, id) => {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};

const GoogleAutoComplete = ({value, setValue, loading}) => {
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);
    let sessionToken;

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 600),
        [],
    )

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
            sessionToken = new window.google.maps.places.AutocompleteSessionToken();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({input: inputValue, sessionToken}, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <>
            <SemGrid>
                <SemGrid.Row>
                    <SemGrid.Column width={16}>
                        <Autocomplete
                            id="auto-complete"
                            sx={{width: "100%"}}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.description
                            }
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            loading={loading}
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            noOptionsText="No locations"
                            onChange={(event, newValue) => {
                                setOptions(newValue ? [newValue, ...options] : options);
                                setValue(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Address" fullWidth/>
                            )}
                            renderOption={(props, option) => {
                                const matches =
                                    option.structured_formatting.main_text_matched_substrings || [];

                                const parts = parse(
                                    option.structured_formatting.main_text,
                                    matches.map((match) => [match.offset, match.offset + match.length]),
                                );

                                return (
                                    <li {...props}>
                                        <Grid container alignItems="center">
                                            <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                                {parts.map((part, index) => (
                                                    <Box
                                                        key={index}
                                                        component="span"
                                                        sx={{fontWeight: part.highlight ? 'bold' : 'regular'}}
                                                    >
                                                        {part.text}
                                                    </Box>
                                                ))}
                                                <Typography variant="body2" color="text.secondary">
                                                    {option.structured_formatting.secondary_text}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            }}
                        />
                    </SemGrid.Column>
                </SemGrid.Row>
            </SemGrid>
            <span className="google-font">Powered by</span> <Image src={GoogleLogo}
                                                                    size="mini"
                                                                    verticalAlign='middle'/>

        </>

    );
}

export default GoogleAutoComplete;