import React, { Fragment, useEffect, useState } from "react";
import { isLoggedIn, removeAuthCookies } from "../../api/authentication";
import { useNavigate } from "react-router-dom";
import { ApiCallStatus, LoggedInStatus} from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import { forgotPassword } from "../../api/authenticationController";
import ForgotPassword from "../../components/ForgotPassword";

const checkIsLoggedIn = async ({
	setLoggedIn
}) => {
	try {
		const response = await isLoggedIn();
		setLoggedIn(response);
	}
	catch (e) {
		console.error(e);
		setLoggedIn(LoggedInStatus.No);
	}
}

const clearCookies = async () => {
	await removeAuthCookies();
};

const createForgotPasswordData = async ({
	email,
	setCreateForgotPasswordDataCallStatus
}) => {
	setCreateForgotPasswordDataCallStatus(ApiCallStatus.InProgress);
	try {
		const response = await forgotPassword({
			email: encodeURI(email)
		})

		if (response?.success) {
			setCreateForgotPasswordDataCallStatus(ApiCallStatus.Succeeded);
		}
		else {
			setCreateForgotPasswordDataCallStatus(ApiCallStatus.Failed);
		}
	}
	catch (error) {
		console.error(error);
		setCreateForgotPasswordDataCallStatus(ApiCallStatus.Error);
	}
}

const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);

	const [createForgotPasswordDataCallStatus, setCreateForgotPasswordDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

	const clearCookiesAsync = async () => {
        await clearCookies();
    }

	useEffect(() => {
		performLoggedInCheck();
	}, []);

	useEffect(() => {
		if (loggedIn === LoggedInStatus.Yes) {
			navigate("/");
		}
		else if (loggedIn === LoggedInStatus.No) {
			clearCookiesAsync();
		}
	}, [loggedIn]);

	const onResetClick = () => {
		navigate(0)
	}

	const createForgotPasswordDataAsync = async (email) => {
		await createForgotPasswordData({
			email,
			setCreateForgotPasswordDataCallStatus
		});
	}

	const onSubmit = async ({ email }) => {
		await createForgotPasswordDataAsync(email);
	}

	const allLoadingStates = [
        createForgotPasswordDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Fragment>
			<SiteHeader/>
			<ForgotPassword onSubmit={onSubmit}
							onResetClick={onResetClick}
							createForgotPasswordDataResult={createForgotPasswordDataCallStatus} />
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={isLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
			<SiteFooter/>
		</Fragment>
	);
}

export default ForgotPasswordPage;