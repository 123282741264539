import React, { useState } from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import TopCategories from "../TopCategories";
import "./style.css"

const TopSellersCategories = () => {
    return (
        <Grid padded className="background-white">
            <Grid.Row>
                <Grid.Column width={16}>
                    <Grid container>
                        <Grid.Row>
                            <Grid.Column width={16} textAlign="center">
                                <Header as="h1">Find parts by category</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <TopCategories/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TopSellersCategories;