import axios from "axios";
import {storageKeys} from "./storageKeys";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_ORDER_API_URL;

export const createAddress = async (addressData) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/address/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        return await instance.post(``, addressData)
                                .then((response) => {
                                    if (response.status === 200) return {
                                        error: false,
                                        success: true,
                                        message: response.data.message,
                                        data: response.data.data
                                    };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                 .catch((error) => {
                                     if (error.response) {
                                         console.error('Server Error:', error.response.status);
                                     }
                                     else if (error.request) {
                                         console.error('Network Error:', error.request);
                                     }
                                     else {
                                         console.error('Error:', error.message);
                                     }
                                 });
    } catch (error) {
        console.error(error);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getAddressById = async (addressId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/address/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `current`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}