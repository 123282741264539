import React, {useEffect, useState} from "react";
import {Box, Modal} from "@mui/material";
import {Button, Form, Grid, Image} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import { cloneDeep, find } from "lodash";
import {storageKeys} from "../../api/storageKeys";
import {getItem, setItem} from "../../utils/storageHelper";
import { getManufacturers, getModels, getVariants, getVehicles, getYears } from "../../api/vehicleController";

const fetchManufacturersData = async ({
    userInput,
    setManufacturerOptions,
    setLoading
}) => {
    setLoading(true);
    try {
        let response = await getManufacturers(userInput.year);
        if (response?.success) {
            const sortedManufacturers = response.data.sort();
            const manufacturerOptions = sortedManufacturers.map(x => {
                return {
                    key: x,
                    text: x.toUpperCase(),
                    value: x
                }
            });
            setManufacturerOptions(manufacturerOptions);
        }

    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
    }
}

const fetchYearsData = async ({
    userInput,
    setYearOptions,
    setLoading
}) => {
    setLoading(true);
    try {
        let response = await getYears(userInput.manufacturer, userInput.model, userInput.variant);

        if (response?.success) {
            const sortedYears = response.data.sort();
            const yearOptions = sortedYears.map(x => {
                return {
                    key: x,
                    text: x,
                    value: x
                }
            });
            setYearOptions(yearOptions);
        }
    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
    }
}

const fetchModelsData = async ({
    userInput,
    setModelOptions,
    setLoading
}) => {
    setLoading(true);
    try {
        if (userInput.manufacturer || userInput.year) {
            const response = await getModels(userInput.manufacturer, userInput.year);

            if (response?.success) {
                const sortedModels = response.data.sort();

                const modelOptions = sortedModels.map(model => {
                    return {
                        key: model,
                        text: model.toUpperCase(),
                        value: model
                    }
                });
                setModelOptions(modelOptions);
            }
        }
    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
    }
}

const fetchVariantsData = async ({
    userInput,
    setVariantOptions,
    setLoading
}) => {
    setLoading(true);
    try {
        if (userInput.manufacturer && userInput.model) {
            const response = await getVariants(userInput.manufacturer, userInput.model, userInput.year);

            if (response?.success) {
                const sortedVariants = response.data.sort();
                const variantOptions = sortedVariants.map(variant => {
                    return {
                        key: variant,
                        text: variant.toUpperCase(),
                        value: variant
                    }
                });
                setVariantOptions(variantOptions);
            }
        }
    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
    }
}

export const confirmationChoices = {
    cancel: "cancel",
    confirm: "confirm",
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '50%',
    width: "50%",
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: 4,
};


const fetchVehicleDetails = async ({
    make,
    model,
    variant,
    year,
    setVehicleToSave,
    setLoading
}) => {
    setLoading(true);
    try {
        const getVehiclesOptions = {
            make,
            model,
            variant,
            year
        }
        const response = await getVehicles(getVehiclesOptions);
        if (response?.success && response.data.length === 1) {
            setVehicleToSave(response.data[0])
        }
        else {
            setVehicleToSave({
                make,
                model,
                variant,
                year
            })
        }
    }
    catch (e) {
        console.error(e);
    }
    finally {
        setLoading(false);
    }

};

const ChooseVehicleModal = ({
    open,
    setOpen,
    setClose
}) => {
    const navigate = useNavigate();
    const [yearOptions, setYearOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userInput, setUserInput] = useState({
        manufacturer: null,
        model: null,
        variant: null,
        year: null
    });
    const [vehicleToSave, setVehicleToSave] = useState(null);

    const fetchManufacturersDataAsync = async () => {
        await fetchManufacturersData({
            userInput,
            setManufacturerOptions,
            setLoading
        });
    }

    const fetchModelsDataAsync = async () => {
        await fetchModelsData({
            userInput,
            setModelOptions,
            setLoading
        });
    }

    const fetchVariantsDataAsync = async () => {
        await fetchVariantsData({
            userInput,
            setVariantOptions,
            setLoading
        });
    }

    const fetchYearsDataAsync = async () => {
        await fetchYearsData({
            userInput,
            setYearOptions,
            setLoading
        });
    }

    useEffect(() => {
        fetchManufacturersDataAsync();
    }, []);

    useEffect(() => {
        fetchModelsDataAsync();
    }, [userInput.manufacturer]);

    useEffect(() => {
        fetchVariantsDataAsync();
    }, [userInput.model]);

    useEffect(() => {
        fetchYearsDataAsync();
    }, [userInput.variant]);

    useEffect(() => {
        if (!vehicleToSave) {
            return;
        }

        let savedVehiclesClone = [];
        const savedVehiclesString = getItem(storageKeys.GUEST_SAVED_VEHICLES);
        if (!!savedVehiclesString) {
            const savedVehiclesObject = JSON.parse(savedVehiclesString);
            savedVehiclesClone = cloneDeep(savedVehiclesObject);
        }

        const vehicleToSaveObject = {
            vyid: vehicleToSave.vehicleYearId ?? null,
            make: vehicleToSave.make,
            model: vehicleToSave.model,
            variant: vehicleToSave.variant,
            year: vehicleToSave.year
        }

        const existingVehicle = find(savedVehiclesClone, x => x.vyid === vehicleToSaveObject.vyid ||
            (x.make === vehicleToSaveObject.make &&
                x.model === vehicleToSaveObject.model &&
                x.year === vehicleToSaveObject.year &&
                x.variant === vehicleToSaveObject.variant))

        if (!existingVehicle) {
            savedVehiclesClone.push(vehicleToSaveObject);
        }

        const vehicleString = JSON.stringify(vehicleToSaveObject);
        const newSavedVehiclesString = JSON.stringify(savedVehiclesClone);
        setItem(storageKeys.GUEST_CURRENT_VEHICLE, vehicleString);
        setItem(storageKeys.GUEST_SAVED_VEHICLES, newSavedVehiclesString);
        closeModal();
        navigate(0);
    }, [vehicleToSave]);

    const handleUserVehicleChange = (_, {name, value}) => {
        let userInputCopy = cloneDeep(userInput);
        userInputCopy[name] = value;

        if (name === 'manufacturer') {
            userInputCopy.model = null;
            userInputCopy.variant = null;
            userInputCopy.year = null;
        } else if (name === 'model') {
            userInputCopy.variant = null;
            userInputCopy.year = null;
        } else if (name === 'variant') {
            userInputCopy.year = null;
        }
        setUserInput(userInputCopy);
    }

    const closeModal = () => {
        setClose();
    }

    const handleModalClose = (confirmationChoice) => async () => {
        const shouldSaveVehicle = confirmationChoices.confirm === confirmationChoice;

        if (shouldSaveVehicle) {
            const fetchVehicleDetailsOptions = {
                make: userInput.manufacturer,
                model: userInput.model,
                variant: userInput.variant,
                year: userInput.year,
                setVehicleToSave,
                setLoading
            }

            await fetchVehicleDetails(fetchVehicleDetailsOptions);
        }

        closeModal();
    };

    return (
        <Modal open={open}>
            <Box sx={style}>
                <Grid>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16} textAlign="center">
                            <Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="small" fluid centered/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <div className="argus-modal-title">Choose your vehicle</div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div className="argus-modal-text">
                                Specify the make, model, variant and year to get the most accurate parts for your vehicle
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form>
                                <Grid padded>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Form.Field>
                                                <Form.Select
                                                    fluid
                                                    name="manufacturer"
                                                    label='Make'
                                                    options={manufacturerOptions}
                                                    placeholder='Make'
                                                    value={userInput.manufacturer}
                                                    onChange={handleUserVehicleChange}
                                                    loading={loading}
                                                    search
                                                    clearable
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Form.Field>
                                                <Form.Select
                                                    fluid
                                                    name="model"
                                                    label='Model'
                                                    options={modelOptions}
                                                    placeholder='Model'
                                                    value={userInput.model}
                                                    onChange={handleUserVehicleChange}
                                                    loading={loading}
                                                    disabled={!userInput.manufacturer}
                                                    search
                                                    clearable
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Form.Field>
                                                <Form.Select
                                                    fluid
                                                    name="variant"
                                                    label='Variant'
                                                    options={variantOptions}
                                                    placeholder='Variant'
                                                    value={userInput.variant}
                                                    onChange={handleUserVehicleChange}
                                                    loading={loading}
                                                    disabled={!userInput.manufacturer && !userInput.model}
                                                    search
                                                    clearable
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Form.Field>
                                                <Form.Select
                                                    fluid
                                                    name="year"
                                                    label='Year'
                                                    options={yearOptions}
                                                    placeholder='Year'
                                                    value={userInput.year}
                                                    onChange={handleUserVehicleChange}
                                                    disabled={!userInput.manufacturer && !userInput.model && !userInput.variant}
                                                    loading={loading}
                                                    search
                                                    clearable
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="right">
                            <Button type="button"
                                    className="blue-button"
                                    floated='right'
                                    disabled={loading}
                                    onClick={handleModalClose(confirmationChoices.confirm)}>
                                Save
                            </Button>
                            <Button floated="right"
                                    onClick={handleModalClose(confirmationChoices.cancel)}
                                    type="button">
                                Cancel
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Box>
        </Modal>
    );
};
export default ChooseVehicleModal;