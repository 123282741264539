import React, {Fragment, useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCallStatus, LoggedInStatus } from "../../utils/constants";
import Cookies from "js-cookie";
import { storageKeys } from "../../api/storageKeys";
import { getCheckoutData, getOrderCheckoutData, getUserCheckoutData } from "../../api/checkoutController";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import Checkout from "../../components/Checkout";
import { isLoggedIn, removeAuthCookies } from "../../api/authentication";
import { getCurrentUser } from "../../api/authenticationController";
import { useCart } from "react-use-cart";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const clearCookies = async () => {
    await removeAuthCookies();
};

const fetchCheckoutData = async ({
    checkoutId,
    setCheckoutDataCallStatus
}) => {
    setCheckoutDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getCheckoutData(checkoutId);
        if (!!response?.success) {
            setCheckoutDataCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCheckoutDataCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setCheckoutDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchOrderCheckoutData = async ({
    setOrderCheckoutInfo,
    setOrderCheckoutInfoCallStatus
}) => {
    setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getOrderCheckoutData();
        if (!!response?.success) {
            setOrderCheckoutInfo(response.data);
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setOrderCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchUserCheckoutData = async ({
    setUserCheckoutInfo,
    setUserCheckoutInfoCallStatus
}) => {
    setUserCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getUserCheckoutData();
        if (!!response?.success) {
            setUserCheckoutInfo(response.data);
            setUserCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setUserCheckoutInfoCallStatus(ApiCallStatus.Failed)
        }
    } catch (error) {
        console.error(error);
        setUserCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const CheckoutPage = () => {
    const { cartTotal } = useCart();
    const location = useLocation();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
    const [userCheckoutInfo, setUserCheckoutInfo] = useState(null);
    const [vat, setVat] = useState(null);
    const [vatAmount, setVatAmount] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [userCheckoutInfoCallStatus, setUserCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoLoginPage = () => {
        return navigate('/login', { state: { from: location } });
    };

    const reloadPage = () => navigate(0);

    const gotoErrorPage = () => navigate("/error");
    const gotoCheckoutOrderInformationPage = () => navigate("/checkout/information");

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchCheckoutDataAsync = async () => {
        const checkoutId = Cookies.get(storageKeys.CHECKOUT_ID);
        await fetchCheckoutData({
            checkoutId,
            setCheckoutDataCallStatus,
        });
    }

    const fetchCheckoutUserDataAsync = async () => {
        await fetchUserCheckoutData({
            setUserCheckoutInfo,
            setUserCheckoutInfoCallStatus
        });
    }

    const fetchCheckoutOrderDataAsync = async () => {
        await fetchOrderCheckoutData({
            setOrderCheckoutInfo,
            setOrderCheckoutInfoCallStatus
        });
    }

    const authorizedPageLoad = async () => {
        await Promise.all([
            fetchCurrentUserAsync(),
            fetchCheckoutUserDataAsync()
        ]);
    }

    const unAuthorizedPageLoad = async () => {
        await Promise.all([
            fetchCheckoutOrderDataAsync(),
            fetchCheckoutDataAsync()
        ]);
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
            unAuthorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            gotoLoginPage();
        }
        // unAuthorizedPageLoad();
    }, [loggedIn]);


    useEffect(() => {
        if (checkoutDataCallStatus === ApiCallStatus.Succeeded) {
            gotoCheckoutOrderInformationPage();
        }
    }, [checkoutDataCallStatus]);

    useEffect(() => {
        if (!!orderCheckoutInfo?.vat && !!userCheckoutInfo) {
            const vatTemp = orderCheckoutInfo.vat;
            let vatAmountTemp = 0;

            if (!userCheckoutInfo.currentTradeAccount) {
                vatAmountTemp = cartTotal/(1 + (vatTemp/100));
            }
            else {
                vatAmountTemp = cartTotal * (vatTemp/100)
            }

            setVat(vatTemp);
            setVatAmount(vatAmountTemp);
        }

    }, [orderCheckoutInfo?.vat, userCheckoutInfo]);

    const allLoadingStates = [
        checkoutDataCallStatus,
        orderCheckoutInfoCallStatus,
        userCheckoutInfoCallStatus,
        currentUserCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser}/>
            <Checkout />
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <SiteFooter/>
        </Fragment>
    );
};
export default CheckoutPage;
