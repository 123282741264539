import React, {Fragment, useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {storageKeys} from "../../api/storageKeys";
import { AccountType, ApiCallStatus, FulfillmentStatus, LoggedInStatus, PriceCategory } from "../../utils/constants";
import _, { cloneDeep, find, sum } from "lodash";
import SiteHeader from "../../components/SiteHeader";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import CheckoutSummary from "../../components/CheckoutSummary";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import { getAccountInformation, getCurrentUser } from "../../api/authenticationController";
import {
    getAddressCheckoutData,
    getCheckoutData, getCheckoutLogisticsFeeData,
    getOrderCheckoutData,
    getUserCheckoutData, putCheckout
} from "../../api/checkoutController";
import {useCart} from "react-use-cart";
import {createOrder} from "../../api/orderController";
import { getPrices, getProductData } from "../../api/productController";
import { calculateTradePriceCategory } from "../../api/account";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const fetchAccountInformationData = async ({
    setAccountInformation,
    setAccountInformationCallStatus
}) => {
    setAccountInformationCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getAccountInformation();
        if (response?.success) {
            setAccountInformation(response.data);
            setAccountInformationCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setAccountInformationCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setAccountInformationCallStatus(ApiCallStatus.Error);
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};

const fetchUserCheckoutData = async ({
    setUserCheckoutInfo,
    setUserCheckoutInfoCallStatus
}) => {
    setUserCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getUserCheckoutData();
        if (response?.success) {
            setUserCheckoutInfo(response.data);
            setUserCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setUserCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setUserCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchOrderCheckoutData = async ({
    setOrderCheckoutInfo,
    setOrderCheckoutInfoCallStatus
}) => {
    setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getOrderCheckoutData();
        if (response?.success) {
            setOrderCheckoutInfo(response.data);
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setOrderCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchAddressCheckoutData = async ({
    setAddressCheckoutInfo,
    setAddressCheckoutInfoCallStatus
}) => {
    setAddressCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getAddressCheckoutData();
        if (response?.success) {
            const addressData = response.data;
            const addressDataClone = cloneDeep(addressData);

            const newShippingAddress = Cookies.get(storageKeys.NEW_CHECKOUT_SHIPPING_ADDRESS);
            if (!!newShippingAddress) {
                const guestTempShippingAddresses = [];
                guestTempShippingAddresses.push(JSON.parse(newShippingAddress));
                addressDataClone.shippingAddresses = guestTempShippingAddresses;
            }

            const newBillingAddress = Cookies.get(storageKeys.NEW_CHECKOUT_BILLING_ADDRESS);
            if (!!newBillingAddress) {
                const guestTempBillingAddresses = [];
                guestTempBillingAddresses.push(JSON.parse(newBillingAddress));
                addressDataClone.billingAddresses = guestTempBillingAddresses;
            }

            setAddressCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
            setAddressCheckoutInfo(addressDataClone);
        }
        else {
            setAddressCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setAddressCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchCheckoutLogisticsFeeData = async ({
    checkoutId,
    subtotal,
    setLogisticsFeeData,
    setLogisticsFeeDataCallStatus
}) => {
    setLogisticsFeeDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getCheckoutLogisticsFeeData(checkoutId, subtotal);
        if (response?.success) {
            setLogisticsFeeData(response.data);
            setLogisticsFeeDataCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setLogisticsFeeDataCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setLogisticsFeeDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchCheckoutData = async ({
    checkoutId,
    setCheckoutData,
    setCheckoutDataCallStatus
}) => {
    setCheckoutDataCallStatus(ApiCallStatus.InProgress);
    try {
        let checkoutUser = await getCheckoutData(checkoutId);
        if (checkoutUser?.success) {
            setCheckoutData(checkoutUser.data);
            setCheckoutDataCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCheckoutDataCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setCheckoutDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchProductData = async ({
    productSkus,
    setProductData,
    setProductDataCallStatus
}) => {
    setProductDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getProductData(productSkus);
        if (response?.success) {
            setProductData(response.data);
            setProductDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setProductDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setProductDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchPriceData = async ({
    productSkus,
    category,
    setPriceData,
    setPriceDataCallStatus
}) => {
    setPriceDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getPrices({
            productSkus,
            level: category
        });

        if (response?.success) {
            setPriceData(response.data);
            setPriceDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setPriceDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setPriceDataCallStatus(ApiCallStatus.Error);
    }
}

const createOrderData = async ({
    salesPersonCode,
    tradeAccountId,
    logisticsMethod,
    billingAddressId,
    shippingAddressId,
    collectionAddressId,
    cartItems,
    setCreateOrderCallStatus,
    setCreatedOrderId
}) => {
    setCreateOrderCallStatus(ApiCallStatus.InProgress);
    try {
        const body = {
            salesPersonCode: salesPersonCode ?? "155",
            tradeAccountId: tradeAccountId,
            logisticsMethod: logisticsMethod,
            orderItems: cartItems.map(item => ({
                sku: item.sku,
                quantity: item.quantity,
                priceSnapshot: item.price,
                fulfillmentStatusId: FulfillmentStatus.New
            })),
            billingAddressId: billingAddressId,
            shippingAddressId: shippingAddressId,
            collectionAddressId: collectionAddressId
        };
        let response = await createOrder(body);

        if (response?.success && response?.data) {
            setCreatedOrderId(response.data);
            setCreateOrderCallStatus(ApiCallStatus.Succeeded);
        } else {
            setCreateOrderCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setCreateOrderCallStatus(ApiCallStatus.Error);
    }
}

const updateCheckoutData = async ({
    checkoutId,
    checkoutData,
    setUpdateCheckoutDataCallStatus
}) => {
    setUpdateCheckoutDataCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await putCheckout(checkoutId, checkoutData);

        if (response.success) {
            setUpdateCheckoutDataCallStatus(ApiCallStatus.Succeeded);
        } else {
            setUpdateCheckoutDataCallStatus(ApiCallStatus.Failed);
        }
    } catch (e) {
        console.error(e);
        setUpdateCheckoutDataCallStatus(ApiCallStatus.Error);
    }
}

const CheckoutSummaryPage = () => {
    const {items, emptyCart, cartTotal} = useCart();
    const location = useLocation();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [accountInformation, setAccountInformation] = useState(null);
    const [priceCategory, setPriceCategory] = useState(PriceCategory.Retail);
    const [productData, setProductData] = useState(null);
    const [priceData, setPriceData] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [checkoutData, setCheckoutData] = useState(null);
    const [logisticsFeeData, setLogisticsFeeData] = useState(null);
    const [userCheckoutInfo, setUserCheckoutInfo] = useState(null);
    const [addressCheckoutInfo, setAddressCheckoutInfo] = useState(null);
    const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
    const [accountType, setAccountType] = useState(AccountType.Guest);
    const [tradeAccount, setTradeAccount] = useState(null);
    const [retailAccount, setRetailAccount] = useState(null);
    const [vat, setVat] = useState(null);
    const [cartSubtotal, setCartSubtotal] = useState(0);
    const [cartVatAmount, setCartVatAmount] = useState(0);
    const [cartGrandTotal, setCartGrandTotal] = useState(0);
    const [salesPeople, setSalesPeople] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [billingAddresses, setBillingAddresses] = useState([]);
    const [collectionAddresses, setCollectionAddresses] = useState([]);

    const [createdOrderId, setCreatedOrderId] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [accountInformationCallStatus, setAccountInformationCallStatus] = useState(ApiCallStatus.NotStarted)
    const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [userCheckoutInfoCallStatus, setUserCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [logisticsFeeDataCallStatus, setLogisticsFeeDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [addressCheckoutInfoCallStatus, setAddressCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [productDataCallStatus, setProductDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [priceDataCallStatus, setPriceDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [updateCheckoutDataCallStatus, setUpdateCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [createOrderCallStatus, setCreateOrderCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoLoginPage = () => {
        return navigate('/login', { state: { from: location } });
    };

    const reloadPage = () => navigate(0);
    const gotoErrorPage = () => navigate("/error");
    const gotoCheckoutOrderInformation = () => navigate("/checkout/information");
    const gotoCheckoutBillingAddress = () => navigate("/checkout/billing");
    const gotoCheckoutCollectionAddress = () => navigate("/checkout/collection");
    const gotoCheckoutDeliveryMethod = () => navigate("/checkout/delivery-method");
    const gotoCheckoutShippingAddress = () => navigate("/checkout/shipping");
    const gotoPaymentPage = (orderId) => navigate(`/payment/${orderId}`);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchAccountInformationDataAsync = async () => {
        await fetchAccountInformationData({
            setAccountInformation,
            setAccountInformationCallStatus
        });
    }

    const fetchCheckoutUserDataAsync = async () => {
        await fetchUserCheckoutData({
            setUserCheckoutInfo,
            setUserCheckoutInfoCallStatus
        });
    }

    const fetchCheckoutDataAsync = async () => {
        const checkoutId = Cookies.get(storageKeys.CHECKOUT_ID);
        await fetchCheckoutData({
            checkoutId,
            setCheckoutData,
            setCheckoutDataCallStatus
        });
    }

    const fetchCheckoutAddressDataAsync = async () => {
        await fetchAddressCheckoutData({
            setAddressCheckoutInfo,
            setAddressCheckoutInfoCallStatus
        });
    }

    const fetchCheckoutOrderDataAsync = async () => {
        await fetchOrderCheckoutData({
            setOrderCheckoutInfo,
            setOrderCheckoutInfoCallStatus
        });
    }

    const fetchProductDataAsync = async () => {
        const productSkus = items?.map(p => p.sku)
        await fetchProductData({
            productSkus,
            setProductData,
            setProductDataCallStatus
        });
    }

    const fetchPriceDataAsync = async (userPriceCategory) => {
        const productSkus = items?.map(p => p.sku)
        await fetchPriceData({
            productSkus,
            category: userPriceCategory,
            setPriceData,
            setPriceDataCallStatus
        });
    }

    const authorizedPageLoad = async () => {
        await Promise.all([
            fetchCurrentUserAsync(),
            fetchAccountInformationDataAsync(),
            fetchCheckoutUserDataAsync(),
            fetchCheckoutAddressDataAsync()
        ]);
    }

    const unAuthorizedPageLoad = async () => {
        await Promise.all([
            fetchCheckoutOrderDataAsync(),
            fetchCheckoutLogisticsFeeDataAsync(),
            fetchCheckoutDataAsync(),
            fetchProductDataAsync()
        ]);
    }

    const createOrderDataAsync = async (checkoutData) => {
        if (!!cartItems) {
            await createOrderData({
                salesPersonCode: checkoutData.salesPersonCode,
                tradeAccountId: accountType === AccountType.Trade && !!tradeAccount ? tradeAccount.tradeAccountId : null,
                logisticsMethod: checkoutData.logisticsMethod,
                billingAddressId: checkoutData.billingAddressId,
                shippingAddressId: checkoutData.shippingAddressId,
                collectionAddressId: checkoutData.collectionAddressId,
                cartItems,
                setCreateOrderCallStatus,
                setCreatedOrderId
            });
        }
    }

    const fetchCheckoutLogisticsFeeDataAsync = async () => {
        const checkoutId = Cookies.get(storageKeys.CHECKOUT_ID);
        await fetchCheckoutLogisticsFeeData({
            checkoutId,
            subtotal: cartTotal,
            setLogisticsFeeData,
            setLogisticsFeeDataCallStatus
        });
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
            unAuthorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            gotoLoginPage();
            // setAccountInformationCallStatus(ApiCallStatus.Succeeded);
        }
        // unAuthorizedPageLoad();
    }, [loggedIn]);

    useEffect(() => {
        if (!!userCheckoutInfo) {
            if (userCheckoutInfo.currentTradeAccount) {
                setAccountType(AccountType.Trade);
            } else {
                setAccountType(AccountType.Retail);
            }
        }
    }, [userCheckoutInfo]);

    useEffect(() => {
        if (!!addressCheckoutInfo) {
            let shippingAddressesTemp = [];
            let billingAddressesTemp = [];
            let collectionAddressesTemp = [];
            let countries = [];
            let provinces = [];

            if (addressCheckoutInfo?.countries) {
                const countriesInAlphabeticalOrder = _.sortBy(addressCheckoutInfo.countries, ({name}) => name);
                countries = _.sortBy(countriesInAlphabeticalOrder, ({name}) => name === 'South Africa' ? 0 : 1);
            }

            if (addressCheckoutInfo?.provinces) {
                provinces = addressCheckoutInfo.provinces;
            }

            if (addressCheckoutInfo?.userAddresses) {
                shippingAddressesTemp = addressCheckoutInfo.userAddresses;
                billingAddressesTemp = addressCheckoutInfo.userAddresses;
            }

            if (addressCheckoutInfo?.pickupLocations) {
                collectionAddressesTemp = addressCheckoutInfo.pickupLocations;
            }

            setProvinces(provinces);
            setCountries(countries);
            setShippingAddresses(shippingAddressesTemp);
            setBillingAddresses(billingAddressesTemp);
            setCollectionAddresses(collectionAddressesTemp);
        }
    }, [addressCheckoutInfo]);

    useEffect(() => {
        if (!!orderCheckoutInfo?.salesPeople) {
            let salesPeopleTemp = []

            if (orderCheckoutInfo.salesPeople) {
                salesPeopleTemp = orderCheckoutInfo.salesPeople;
            }

            setSalesPeople(salesPeopleTemp);
        }
    }, [orderCheckoutInfo?.salesPeople]);

    useEffect(() => {
        if (accountType === AccountType.Trade) {
            setTradeAccount(userCheckoutInfo.currentTradeAccount);
        } else if (accountType === AccountType.Retail) {
            setRetailAccount(userCheckoutInfo)
        }
    }, [accountType]);

    useEffect(() => {
        if (!!orderCheckoutInfo?.vat) {
            const vatTemp = orderCheckoutInfo.vat;
            setVat(vatTemp);
        }
    }, [orderCheckoutInfo?.vat]);

    useEffect(() => {
        if (accountInformationCallStatus === ApiCallStatus.Succeeded) {
            if (!!accountInformation?.currentTradeAccount) {
                const userPriceCategory = calculateTradePriceCategory(accountInformation.currentTradeAccount.category);
                setPriceCategory(userPriceCategory);
                fetchPriceDataAsync(userPriceCategory);
            }
            else {
                fetchPriceDataAsync(PriceCategory.Retail);
            }
        }
    }, [accountInformation, accountInformationCallStatus]);

    useEffect(() => {
        if (cartTotal > 0 && !!productData && !!priceData) {
            const cartItemsData = items.map((item, index) => {
                const product = find(productData, p => p.sku === item.sku);
                const price = find(priceData, p => p.sku === item.sku);

                return {
                    id: product?.productId ?? item.id,
                    image: product?.image ?? item.image,
                    name: product?.name ?? item.name,
                    sku: product?.sku ?? item.sku,
                    quantity: item.quantity,
                    price: price.price
                }
            })
            setCartItems(cartItemsData);
        }

    }, [cartTotal, productData, priceData]);

    useEffect(() => {
        if (!!cartItems && !!vat) {
            const lineItemTotals = cartItems.map(i => i.price * i.quantity);
            if (priceCategory === PriceCategory.Retail) {
                const totalTemp = sum(lineItemTotals);
                const subtotalTemp = totalTemp / (1 + (vat /100));
                const vatAmountTemp = totalTemp - subtotalTemp;

                setCartGrandTotal(totalTemp);
                setCartSubtotal(subtotalTemp);
                setCartVatAmount(vatAmountTemp);
            }
            else {
                const subtotalTemp = sum(lineItemTotals);
                const vatAmountTemp = subtotalTemp * (vat/100);
                const totalTemp = subtotalTemp + vatAmountTemp;

                setCartGrandTotal(totalTemp);
                setCartSubtotal(subtotalTemp);
                setCartVatAmount(vatAmountTemp);
            }
        }
    }, [cartItems, vat]);

    useEffect(() => {
        if (updateCheckoutDataCallStatus === ApiCallStatus.Succeeded) {
            Cookies.remove(storageKeys.CHECKOUT_ID);
            emptyCart();
            gotoPaymentPage(createdOrderId);
        }
    }, [updateCheckoutDataCallStatus]);

    useEffect(() => {
        if (!!createdOrderId) {
            Cookies.set(storageKeys.ORDER_ID, createdOrderId, { secure: true, sameSite: 'strict' });
            const checkoutId = Cookies.get(storageKeys.CHECKOUT_ID);
            const checkoutDataClone = cloneDeep(checkoutData);

            checkoutDataClone.orderId = createdOrderId;

            const updateCheckoutDataOptions = {
                checkoutId,
                orderId: createdOrderId,
                checkoutData: checkoutDataClone,
                gotoPaymentPage,
                gotoErrorPage,
                setUpdateCheckoutDataCallStatus
            }

            updateCheckoutData(updateCheckoutDataOptions);
        }
    }, [createdOrderId]);

    const allLoadingStates = [
        currentUserCallStatus,
        accountInformationCallStatus,
        checkoutDataCallStatus,
        userCheckoutInfoCallStatus,
        orderCheckoutInfoCallStatus,
        addressCheckoutInfoCallStatus,
        createOrderCallStatus,
        updateCheckoutDataCallStatus,
        logisticsFeeDataCallStatus,
        productDataCallStatus,
        priceDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return(
        <Fragment>
            <SiteHeader currentUser={currentUser}/>
            <CheckoutSummary currentUser={currentUser}
                             accountType={accountType}
                             tradeAccount={tradeAccount}
                             retailAccount={retailAccount}
                             checkoutData={checkoutData}
                             logisticsFee={logisticsFeeData}
                             subtotal={cartSubtotal}
                             vatAmount={cartVatAmount}
                             total={cartGrandTotal}
                             cartItems={cartItems}
                             vat={vat}
                             countries={countries}
                             provinces={provinces}
                             shippingAddresses={shippingAddresses}
                             billingAddresses={billingAddresses}
                             collectionAddresses={collectionAddresses}
                             salesPeople={salesPeople}
                             gotoCheckoutOrderInformation={gotoCheckoutOrderInformation}
                             gotoCheckoutBillingAddress={gotoCheckoutBillingAddress}
                             gotoCheckoutCollectionAddress={gotoCheckoutCollectionAddress}
                             gotoCheckoutDeliveryMethod={gotoCheckoutDeliveryMethod}
                             gotoCheckoutShippingAddress={gotoCheckoutShippingAddress}
                             onSubmit={createOrderDataAsync} />
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <SiteFooter/>
        </Fragment>
    )


}

export default CheckoutSummaryPage;