import axios from "axios";
import Cookies from "js-cookie";
import {storageKeys} from "./storageKeys";

const url = process.env.REACT_APP_ORDER_API_URL;
const authenticationUrl = process.env.REACT_APP_IDP_URL;

export const getPaymentData = async (paymentId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/payment/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `${paymentId}`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const postPayment = async (paymentData) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/payment/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const body = {
            paymentId: paymentData.paymentId,
            orderId: paymentData.orderId,
            amount: paymentData.amount,
            paymentTypeId: paymentData.type,
            paymentStatusId: paymentData.status,
        }

        return await instance.post(``, body)
                                .then((response) => {
                                    if (response.status === 204) return {
                                        error: false,
                                        success: true
                                    };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const postAccountCreditPayment = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/payment/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const body = {
            orderId
        }

        return await instance.post(`account-credit`, body)
                                .then((response) => {
                                    if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const postEftPayment = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/payment/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const body = {
            orderId
        }

        return await instance.post(`eft`, body)
                                .then((response) => {
                                    if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const postCreditCardOnCollectionPayment = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/payment/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const body = {
            orderId
        }

        return await instance.post(`credit-card-on-collection`, body)
                                .then((response) => {
                                    if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getPaymentUserData = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${authenticationUrl}/api/payment/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `user`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}
