import React, {useEffect, useState} from "react";
import {Box, Modal} from "@mui/material";
import { Button, Card, Form, Grid, Header, Icon, Image, Label, Menu, Message, Popup, Table } from "semantic-ui-react";
import {storageKeys} from "../../api/storageKeys";
import {getItem, removeItem, setItem} from "../../utils/storageHelper";
import {Link, useNavigate} from "react-router-dom";
import "./style.css";
import { cloneDeep, find, remove, filter } from "lodash";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25%",
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: 4
};

const SavedVehiclesModal = ({
    open,
    setOpen,
    openChooseVehicleModal,
    setClose
}) => {
    const navigate = useNavigate();
    const [savedVehicles, setSavedVehicles] = useState([])
    const [currentVehicle, setCurrentVehicle] = useState(null);
    const [currentVehicleId, setCurrentVehicleId] = useState("");

    useEffect(() => {
        const savedVehiclesString = getItem(storageKeys.GUEST_SAVED_VEHICLES);
        const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);

        if (!!savedVehiclesString) {
            const savedVehiclesObject = JSON.parse(savedVehiclesString);
            setSavedVehicles(savedVehiclesObject)
        }

        if (!!vehicleString) {
            const vehicle = JSON.parse(vehicleString);
            setCurrentVehicle(vehicle)
        }
    }, []);

    useEffect(() => {
        if (!!currentVehicle) {

            setCurrentVehicleId(currentVehicle.id);
        }
    }, [currentVehicle]);

    const closeModal = () => {
        setClose();
    }

    const handleRemoveVehicleClick = (e, {id}) => {
        let savedVehiclesClone = [];
        let currentVehicleClone ;

        const savedVehiclesString = getItem(storageKeys.GUEST_SAVED_VEHICLES);
        const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);

        if (!!savedVehiclesString) {
            const savedVehiclesObject = JSON.parse(savedVehiclesString);
            savedVehiclesClone = cloneDeep(savedVehiclesObject);
        }

        if (!!vehicleString) {
            const vehicleObject = JSON.parse(vehicleString);
            currentVehicleClone = cloneDeep(vehicleObject);
        }

        if (id === currentVehicleId) {
            const otherVehicles = filter(savedVehiclesClone, x => x.id !== id);

            if (otherVehicles.length > 0) {
                currentVehicleClone = otherVehicles[0];
            }
            else {
                currentVehicleClone = null;
            }
        }

        remove(savedVehiclesClone, x => x.id === id)

        if (!!currentVehicleClone) {
            setItem(storageKeys.GUEST_CURRENT_VEHICLE, JSON.stringify(currentVehicleClone));
        }
        else {
            removeItem(storageKeys.GUEST_CURRENT_VEHICLE)
        }

        const newSavedVehiclesString = JSON.stringify(savedVehiclesClone);
        setItem(storageKeys.GUEST_SAVED_VEHICLES, newSavedVehiclesString);
        closeModal();
        navigate(0);
    };

    const handleChangeVehicleClick = (vehicleToMakeActive) => {
        let savedVehiclesClone = [];

        const savedVehiclesString = getItem(storageKeys.GUEST_SAVED_VEHICLES);

        if (!!savedVehiclesString) {
            const savedVehiclesObject = JSON.parse(savedVehiclesString);
            savedVehiclesClone = cloneDeep(savedVehiclesObject);
        }

        const vehicleToMakeActiveString = JSON.stringify(vehicleToMakeActive);

        const vehicleToUse = find(savedVehiclesClone, x => JSON.stringify(x) === vehicleToMakeActiveString);

        if (!!vehicleToUse) {
            setItem(storageKeys.GUEST_CURRENT_VEHICLE, JSON.stringify(vehicleToUse));
            closeModal()
            navigate(0);
        }

    };

    const handleClearSavedVehiclesClick = () => {
        removeItem(storageKeys.GUEST_SAVED_VEHICLES);
        removeItem(storageKeys.GUEST_CURRENT_VEHICLE);
        closeModal();
        navigate(0);
    };

    const handleAddVehicleClick = () => {
        openChooseVehicleModal()
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <Box sx={style}>
                <Grid>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16} textAlign="center">
                            <Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="small" fluid centered/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <div className="argus-modal-title">Your garage</div>
                        </Grid.Column>
                    </Grid.Row>
                    {!!savedVehicles && savedVehicles.length > 0 &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Table basic="very" selectable size="large">
                                    <Table.Body>
                                        {!!savedVehicles && savedVehicles.map((v, index) => {
                                            let name = "";
                                            name += v.make ? `${v.make} ` : "";
                                            name += v.model ? `${v.model} ` : "";
                                            name += v.variant ? `${v.variant} ` : "";
                                            name += v.year ? `${v.year} ` : "";

                                            return (
                                                <Table.Row key={v.id}>
                                                    <Table.Cell>
                                                        <Icon name="car" size="big"  />
                                                    </Table.Cell>
                                                    <Table.Cell>{name}</Table.Cell>
                                                    <Table.Cell textAlign="right">
                                                        <Popup trigger={<Icon name="ellipsis vertical" />}
                                                               flowing
                                                               basic
                                                               hoverable>
                                                            <Menu vertical className="argus-menu" borderless fluid>
                                                                <Menu.Item id={v.id}
                                                                           onClick={() => handleChangeVehicleClick(v)}>
                                                                    Set as active
                                                                </Menu.Item>
                                                                <Menu.Item id={v.id}
                                                                           onClick={() => handleRemoveVehicleClick(v)}>
                                                                    Remove
                                                                </Menu.Item>
                                                            </Menu>
                                                        </Popup>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>

                            </Grid.Column>
                        </Grid.Row>
                    }
                    {!savedVehicles || savedVehicles.length === 0 &&
                        <Grid.Row>
                            <Grid.Column width={16} textAlign="center" verticalAlign="middle">
                                <p className="b1">No vehicles saved</p>
                            </Grid.Column>
                        </Grid.Row>
                    }
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Link className="argus-modal-link" to="#" onClick={handleAddVehicleClick}>
                                Add another vehicle
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Link className="argus-modal-negative-link" to="#" onClick={handleClearSavedVehiclesClick}>
                                Remove all vehicles
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="right">
                            <Button type="button"
                                    className="blue-button"
                                    onClick={closeModal}>
                                Close
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Box>
        </Modal>
    );
};
export default SavedVehiclesModal;