import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Form,
  Grid,
  Header,
  Button,
  Container,
  Segment,
  Divider,
  Icon,
  Image,
  Message,
  Popup
} from "semantic-ui-react";
import { register } from "../../api/authenticationController";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { Link } from "react-router-dom";

const createRegistrationData = async ({
  firstName,
  lastName,
  email,
  mobile,
  password,
  wholesaleTrader,
  personalIdNumber,
  companyRegNo,
  personalCapacity,
  setLoading,
  setRegistrationResult
}) => {
  try {
    setLoading(true);
    const registrationDataOptions = {
      firstName,
      lastName,
      email,
      mobile,
      password,
      wholesaleTrader,
      personalIdNumber: personalCapacity ? personalIdNumber : null,
      companyRegNo: personalCapacity ? null : companyRegNo
    };

    let response = await register(registrationDataOptions);

    setRegistrationResult({
      isSuccess: response.success,
      isError: response.error,
      message: response.message
    });
  } catch (error) {
    console.error(error)
  } finally {
    setLoading(false);
  }
}

const Register = ({ setLoading }) => {
  const schema = yup.object({
    firstName: yup.string().required("First Name is required to register"),
    lastName: yup.string().required("Last Name is required to register"),
    email: yup.string().email().required("Email is required to register"),
    mobile: yup.string().required("Mobile number is required to register")
                        .matches(/^(\+27|0)[6-8][0-9]{8}$/, "Must be a valid mobile number"),
    password: yup.string().min(8, "Password must have at least 8 characters")
                          .max(20, "Password must have a maximum of 20 characters")
                          .matches(/\d+/, "Password must have at least one number")
                          .matches(/[a-z]+/, "Password must have at least one lowercase character")
                          .matches(/[A-Z]+/, "Password must have at least one uppercase character")
                          .matches(/[!@#$%^&*()-+]+/, "Password must have at least one special character")
                          .required("Password is required to register"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    termsAndConditions: yup.boolean().required(),
    wholesaleTrader: yup.boolean(),
    personalIdNumber: yup.string(),
    companyRegNo: yup.string()
  }).required();
  const { control, watch, getValues, setValue, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
      wholesaleTrader: false,
      personalIdNumber: "",
      companyRegNo: ""
    }
  });

  const watchTermsAndConditions = watch("termsAndConditions", false);
  const watchWholesaleTrader = watch("wholesaleTrader", false);
  const [registrationResult, setRegistrationResult] = useState(null);
  const [showWholesaleTraderOptions, setShowWholesaleTraderOptions] = useState(false);
  const [personalCapacity, setPersonalCapacity] = useState(false);

  useEffect(() => {
    if (!!watchTermsAndConditions) {
      clearErrors("termsAndConditions")
    }
  }, [watchTermsAndConditions])

  useEffect(() => {
    setShowWholesaleTraderOptions(watchWholesaleTrader)
  }, [watchWholesaleTrader])

  const onSubmit = async values => {
    if (!values.termsAndConditions) {
      setError("termsAndConditions", "You must accept the terms and conditions to register.")
    }
    else {
      const createRegistrationDataOptions = {
        firstName: values.firstName,
        lastName: values.lastName,
        mobile: values.mobile,
        email: values.email,
        password: values.password,
        wholesaleTrader: values.wholesaleTrader,
        personalIdNumber: values.personalIdNumber,
        companyRegNo: values.companyRegNo,
        personalCapacity,
        setLoading,
        setRegistrationResult
      }

      await createRegistrationData(createRegistrationDataOptions);
    }
  };

  return (
      <Container className="argus-full-page-container login-background" fluid>
        <Grid centered padded stretched verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={16}>
              <Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="medium" centered as={Link} to="/"/>
            </Grid.Column>
          </Grid.Row>
          {!registrationResult &&
              <Grid.Row>
                <Grid.Column width={2}/>
                <Grid.Column width={12} textAlign="center">
                  <Segment padded>
                    <Grid padded>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as="h1">Create an Argus account</Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={2}/>
                        <Grid.Column width={12}>
                          <Form onSubmit={handleSubmit(onSubmit)} size="medium">
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width={8}>
                                  <Controller
                                      name="firstName"
                                      control={control}
                                      render={({field: {onChange, value}}) => (
                                          <Form.Input
                                              label="First Name"
                                              onChange={onChange}
                                              value={value}
                                              placeholder="John"/>
                                      )}
                                  />
                                  <ErrorMessage
                                      errors={errors}
                                      name="firstName"
                                      render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                  />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Controller
                                      name="lastName"
                                      control={control}
                                      render={({field: {onChange, value}}) => (
                                          <Form.Input
                                              label="Last Name"
                                              onChange={onChange}
                                              value={value}
                                              placeholder="Doe"/>
                                      )}
                                  />
                                  <ErrorMessage
                                      errors={errors}
                                      name="lastName"
                                      render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width={8}>
                                  <Controller
                                      name="email"
                                      control={control}
                                      render={({field: {onChange, value}}) => (
                                          <Form.Input
                                              label="Email"
                                              onChange={onChange}
                                              value={value}
                                              placeholder="email@yourdomain.com"/>
                                      )}
                                  />
                                  <ErrorMessage
                                      errors={errors}
                                      name="email"
                                      render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                  />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Controller
                                      name="mobile"
                                      control={control}
                                      render={({field: {onChange, value}}) => (
                                          <Form.Input
                                              label="Mobile"
                                              onChange={onChange}
                                              value={value}
                                              placeholder="Mobile"/>
                                      )}
                                  />
                                  <ErrorMessage
                                      errors={errors}
                                      name="mobile"
                                      render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width={8}>
                                  <Controller
                                      name="password"
                                      control={control}
                                      render={({field: {onChange, value}}) => (
                                          <Form.Input
                                              label="Password"
                                              type="password"
                                              onChange={onChange}
                                              value={value}
                                              placeholder="Password"/>
                                      )}
                                  />
                                  <ErrorMessage
                                      errors={errors}
                                      name="password"
                                      render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                  />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Controller
                                      name="confirmPassword"
                                      control={control}
                                      render={({field: {onChange, value}}) => (
                                          <Form.Input
                                              label="Confirm Password"
                                              type="password"
                                              onChange={onChange}
                                              value={value}
                                              placeholder="Password"/>
                                      )}
                                  />
                                  <ErrorMessage
                                      errors={errors}
                                      name="confirmPassword"
                                      render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              {showWholesaleTraderOptions &&
                                  <Grid.Row>
                                    <Grid.Column width={16}>
                                      <Divider horizontal>Trade account details</Divider>
                                      <div className="mb-5 mt-5">
                                        <Header as="h6">Are you registering for a company or in a personal
                                          capacity?</Header>
                                        <Button.Group className="mb-4 mt-5" size="large">
                                          <Button type="button"
                                                  className={!!personalCapacity ? "blue-basic-button" : "blue-button"}
                                                  onClick={() => setPersonalCapacity(false)}>
                                            <Icon name='building outline'/>
                                            Company
                                          </Button>
                                          <Button type="button"
                                                  className={!personalCapacity ? "blue-basic-button" : "blue-button"}
                                                  compact
                                                  onClick={() => setPersonalCapacity(true)}>
                                            <Icon name='user outline'/>
                                            Personal
                                          </Button>
                                        </Button.Group>
                                        {!personalCapacity &&
                                            <>
                                              <Controller
                                                  name="companyRegNo"
                                                  control={control}
                                                  render={({field: {onChange, value}}) => (
                                                      <Form.Input
                                                          label="Company registration number/Registered trading name"
                                                          onChange={onChange}
                                                          value={value}
                                                          placeholder="Company registration number/registered trading name"/>
                                                  )}
                                              />
                                              <ErrorMessage
                                                  errors={errors}
                                                  name="companyRegNo"
                                                  render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                              />
                                            </>
                                        }
                                        {personalCapacity &&
                                            <>
                                              <Controller
                                                  name="personalIdNumber"
                                                  control={control}
                                                  render={({field: {onChange, value}}) => (
                                                      <Form.Input
                                                          label="Id number"
                                                          onChange={onChange}
                                                          value={value}
                                                          placeholder="Id number"/>
                                                  )}
                                              />
                                              <ErrorMessage
                                                  errors={errors}
                                                  name="personalIdNumber"
                                                  render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                              />
                                            </>
                                        }
                                      </div>
                                      <Divider/>
                                    </Grid.Column>
                                  </Grid.Row>
                              }
                              <Grid.Row>
                                <Grid.Column width={14}>
                                  <Controller
                                      name="wholesaleTrader"
                                      control={control}
                                      render={({field: {value}}) => (
                                          <Form.Checkbox
                                              onChange={(e, data) => setValue("wholesaleTrader", data.checked)}
                                              checked={value}
                                              label="Register a trade account"/>
                                      )}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width={16}>
                                  <Controller
                                      name="termsAndConditions"
                                      control={control}
                                      render={({field: {value}}) => (
                                          <Form.Checkbox
                                              onChange={(e, data) => setValue("termsAndConditions", data.checked)}
                                              checked={value}
                                              label="Accept terms & conditions"/>
                                      )}
                                  />
                                  <ErrorMessage
                                      errors={errors}
                                      name="termsAndConditions"
                                      render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width={16}>
                                  <Button className="blue-button"
                                          type="submit">
                                    Register
                                  </Button>
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width={16}>
                                  <span className="b2">Already have an account? </span>
                                  <Link to="/login">Login</Link>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Form>
                        </Grid.Column>
                        <Grid.Column width={2}/>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
                <Grid.Column width={2}/>
              </Grid.Row>
          }
          {!!registrationResult &&
              <Grid.Row>
                <Grid.Column width={4}/>
                <Grid.Column width={8}>
                  <Segment padded>
                    <Grid padded>
                      <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                          <Header as="h2">Registration {registrationResult.isSuccess ? "successful" : "failed"}</Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={2}/>
                        <Grid.Column width={12} textAlign="center">
                          <p className="b2">{registrationResult.message}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Column width={2}/>
                    </Grid>
                  </Segment>
                </Grid.Column>
                <Grid.Column width={4}/>
              </Grid.Row>
          }
        </Grid>
      </Container>
  );
};

export default Register;
