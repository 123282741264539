import React, {useEffect, useState} from 'react'
import {Button, Form, Grid, Header, Container, Image} from "semantic-ui-react";
import {cloneDeep, remove} from "lodash";
import _ from "lodash";
import GridFilters from "../GridFilters";
import {GridProducts} from "../GridProducts";
import "./style.css";
import { getCommonProductsExp, getFiltersExp, getPricesExp } from "../../api/commonFilterController";
import {getAccessories} from "../../api/accessoryFilterController";
import ConfirmationModal, {confirmationChoices} from "../ConfirmationModal";
import {useLocation, useNavigate} from "react-router-dom";
import AddedToCartModal from "../AddedToCart";

const fetchProductsData = async ({
    pageSize,
    pageNumber,
    productCategory,
    productType,
    manufacturers,
    models,
    vendors,
    years,
    variants,
    service_parts,
    accessoriesOnly,
    defaultFilterValue,
    setProducts,
    setLoading,
    setPageSize,
    setFilters,
    setPageNumber,
    setTotalResults,
    setAccessoriesOnly,
    setVendorOptions
}) => {
    setLoading(true);
    try {
        const getProductsOptions = {
            pageSize,
            pageNumber,
            productCategory,
            productType,
            manufacturers,
            models,
            vendors,
            years,
            variants,
            service_parts,
            defaultFilterValue
        };

        const processProductsResponse = r => {
            if (r?.data) {
                const products = _.uniqBy(r.data, p => p.sku);
                setProducts(products);

                setTotalResults(r ? r.total : 0);
                setLoading(false);
            }
        }

        const processFiltersResponse = r => {
            if (r.length > 0) {
                const vendorFilters = r.find(f => f.type === "VENDOR");
                if (vendorFilters && vendorFilters.values && vendorFilters.values.length > 0) {
                    const options = vendorFilters.values.map((v, index) => ({
                        key: index,
                        value: v.name,
                        text: v.name
                    }));
                    setVendorOptions(options);
                }

                const newFilters = { filters: r, refresh: false };
                setFilters(newFilters);
                setLoading(false);
            }
            else {
                const newFilters = { filters: [], refresh: false };
                setFilters(newFilters);
                setLoading(false);
            }
        }

        if (accessoriesOnly) {
            await getAccessories(getProductsOptions).then(r => processProductsResponse(r));
            setAccessoriesOnly(true);
        }
        else {
            await getCommonProductsExp(getProductsOptions).then(r => processProductsResponse(r));
        }

        await getFiltersExp(getProductsOptions).then(r => processFiltersResponse(r));

    } catch (error) {
        console.error(error)
    } finally {
        setLoading(false);
    }
}

const fetchPricesForSkusData = async ({
    setLoading,
    skus,
    priceCategory,
    setPrices
}) => {
    setLoading(true);
    try {
        const getPricesOptions = {
            skus,
            level: priceCategory
        };

        const processPricesResponse = r => {
            if (r) {
                setPrices(r);
            }
        }

        await getPricesExp(getPricesOptions).then(r => processPricesResponse(r));

    } catch (error) {
        console.error(error)
    } finally {
        setLoading(false);
    }
}

const fetchPartNumbersByVendorData = async ({
    setPartNumberDropdownLoading,
    vendorChosen,
    setPartNumberOptions
}) => {
    setPartNumberDropdownLoading(true);
    try {
        let response;

        if (response) {
            const options = response.map((r, index) => {
                const url = `/product/${r.product_Reference_Number}`
                return {
                    key: index,
                    text: r.product_Reference_Number,
                    value: url
                }
            });
            if (options.length > 0) {
                setPartNumberOptions(options);
            }
        }
    } catch (error) {
        console.error(error)
    } finally {
        setPartNumberDropdownLoading(false);
    }
}

const ResultGrid = ({setLoading}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [filters, setFilters] = useState({
        filters: [],
        refresh: true
    });
    const [products, setProducts] = useState([]);
    const [prices, setPrices] = useState([]);
    const [priceCategory, setPriceCategory] = useState("ret");
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(null);
    const [pageSize, setPageSize] = useState(24);
    const [accessoriesOnly, setAccessoriesOnly] = useState(false);
    const [vendorOptions, setVendorOptions] = useState(null);
    const [vendorChosen, setVendorChosen] = useState(null);
    const [partNumberChosen, setPartNumberChosen] = useState(null);
    const [partNumberOptions, setPartNumberOptions] = useState(null);
    const [partNumberDropdownLoading, setPartNumberDropdownLoading] = useState(false);
    const [addedToCartModelOpen, setAddedToCartModalOpen] = useState(false);
    const [productAddedInformation, setProductAddedInformation] = useState(null);

    useEffect(() => {
        const applyFiltersAsync = async () => {
            await applyFilters({
                tempFilters: null,
                newPageNumber: pageNumber
            });
        }

        applyFiltersAsync();
        scrollTop();
    }, [filters]);

    useEffect(() => {
        const filtersCopy = cloneDeep(filters);
        filtersCopy.refresh = true;
        setFilters(filtersCopy);
    }, [pageNumber]);

    useEffect(() => {
        if (!!productAddedInformation) {
            setAddedToCartModalOpen(true);
        }
    }, [productAddedInformation]);

    useEffect(() => {
        if (!addedToCartModelOpen) {
            setAddedToCartModalOpen(false);
        }
    }, [addedToCartModelOpen]);

    useEffect(() => {
        const fetchPartNumbersByVendorAsync = async () => {
            if (vendorChosen) {
                const getPartNumbersByVendorOptions = {
                    setPartNumberDropdownLoading,
                    vendorChosen,
                    setPartNumberOptions
                }
                await fetchPartNumbersByVendorData(getPartNumbersByVendorOptions)
            }
        }

        fetchPartNumbersByVendorAsync();
    }, [vendorChosen]);

    useEffect(() => {
        const fetchPricesForSkusDataAsync = async () => {
            const skus = products.map(p => p.sku);
            const getPricesForSkusOptions =  {
                setLoading,
                skus,
                priceCategory,
                setPrices
            }
            await fetchPricesForSkusData(getPricesForSkusOptions);
        }
        if (products.length > 0) {
            fetchPricesForSkusDataAsync()
        }
    }, [products, priceCategory])

    const applyFilters = async ({tempFilters, newPageNumber}) => {

        let filtersToUse = filters.filters;

        if (tempFilters) {
            filtersToUse = tempFilters
        }

        let filterObjects = {};
        filtersToUse.map(filter => {
            filterObjects[filter.name] = filter.values.filter(x => x.active === true).map(x => x.value);
        });

        let productCategoryArray = filterObjects["Product_category"] ?? [];
        let productTypeArray = filterObjects["Product_type"] ?? [];
        let manufacturerArray = filterObjects["Manufacturers"] ?? [];
        let modelArray = filterObjects["Models"] ?? [];
        let yearArray = filterObjects["Years"] ?? [];
        let variantArray = filterObjects["Variants"] ?? [];
        let servicePartArray = filterObjects["Service_parts"] ?? [];
        let accessoriesOnlyParam = false;

        const chosenVehicleProductType = getUrlParameter("pt");
        const chosenVehicleProductCategory = getUrlParameter("pc");
        const chosenVehicleManufacturer = getUrlParameter("ma");
        const chosenVehicleModel = getUrlParameter("mo");
        const chosenVehicleYear = getUrlParameter("y");
        const chosenVehicleVariant = getUrlParameter("v");
        accessoriesOnlyParam = getUrlParameter("a");

        if (chosenVehicleProductCategory && chosenVehicleProductCategory !== '' && !productCategoryArray.includes(chosenVehicleProductCategory)) {
            productCategoryArray.push(chosenVehicleProductCategory)
        }
        if (chosenVehicleProductType && chosenVehicleProductType !== '' && !productTypeArray.includes(chosenVehicleProductType)) {
            productTypeArray.push(chosenVehicleProductType)
        }
        if (chosenVehicleManufacturer && chosenVehicleManufacturer !== '' && !manufacturerArray.includes(chosenVehicleManufacturer)){
            manufacturerArray.push(chosenVehicleManufacturer)
        }
        if (chosenVehicleModel && chosenVehicleModel !== '' && !modelArray.includes(chosenVehicleModel)){
            modelArray.push(chosenVehicleModel)
        }
        if (chosenVehicleYear && chosenVehicleYear !== '' && !yearArray.includes(chosenVehicleYear)){
            yearArray.push(chosenVehicleYear)
        }
        if (chosenVehicleVariant && chosenVehicleVariant !== '' && !variantArray.includes(chosenVehicleVariant)) {
            variantArray.push(chosenVehicleVariant)
        }

        const getDataOptions = {
            pageSize,
            pageNumber: newPageNumber ?? pageNumber,
            productCategory: productCategoryArray.join(","),
            productType: productTypeArray.join(","),
            manufacturers: [...new Set(manufacturerArray)].join(","),
            models: [...new Set(modelArray)].join(","),
            vendors: filterObjects["Vendors"] ? filterObjects["Vendors"].join(",") : "",
            years: [...new Set(yearArray)].join(",").trim(),
            variants: [...new Set(variantArray)].join(","),
            service_parts: servicePartArray[0],
            accessoriesOnly: accessoriesOnlyParam,
            setProducts,
            setFilters,
            setLoading,
            setPageSize,
            setPageNumber,
            setTotalResults,
            setAccessoriesOnly,
            setVendorOptions
        }
        if (filters.refresh) {
            await fetchProductsData(getDataOptions);
        }
    }

    const getCurrentCollectionName = () => {
        let pathArray = location.pathname.split('/');
        for (let i=0; i < pathArray.length -1; i++) {
            if (pathArray[i] === "collections") {
                return pathArray[i + 1];
            }
        }
    };

    const getUrlParameter = parameterName => {
        let sPageURL = location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === parameterName) {
                return sParameterName[1] === undefined ? false : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    const onCheckboxChange = (_, {checked, value, filtername}) => {
        let filtersCopy = cloneDeep(filters);
        filtersCopy.refresh = false;
        let filtersArray = filtersCopy.filters.find(x => x.name === filtername);
        let filterToChange = filtersArray.values.find(x => x.value === value);
        filterToChange.active = checked;

        setFilters(filtersCopy);
    }

    const onRadioChange = (_, {checked, value, filtername}) => {
        let filtersCopy = cloneDeep(filters);
        filtersCopy.refresh = false;
        let filtersArray = filtersCopy.filters.find(x => x.name === filtername);
        filtersArray.values.forEach(x => x.active = false);
        let filterToChange = filtersArray.values.find(x => x.value === value);
        filterToChange.active = checked;

        setFilters(filtersCopy);
    }

    const onFilterRemove = (_, {type, value}) => {
        let currentCollectionName = getCurrentCollectionName();
        let parameterName = `pfa_${type.toLowerCase().replaceAll(" ", "_")}`;
        let parameterFound = getUrlParameter(parameterName);
        if (currentCollectionName === "all" && parameterFound) {
            let url = window.location.href;
            url = decodeURI(url)
            let parameterValues = parameterFound.split(",");
            if (parameterValues.length === 1) {
                url = url.replaceAll(`pfa_${type.toLowerCase().replaceAll(" ", "_")}=${parameterValues[0]}`, "");
                url = url.replaceAll("&&", "&");
                url = url.replace(/&+$/, "");
            }
            else if (parameterValues.length > 1) {
                parameterValues = remove(parameterValues, x => x === value);
                let newParameterValues = parameterValues.join(",");
                url = url.replaceAll(`pfa_${type.toLowerCase().replaceAll(" ", "_")}=${parameterFound}`, `pfa_${type.toLowerCase().replaceAll(" ", "_")}=${newParameterValues}`)
            }
            return navigate(url);
        }
        else {
            const filtersCopy = cloneDeep(filters);
            filtersCopy.refresh = true;
            const filtersArray = filtersCopy.filters.find(x => x.type === type);
            const filterToChange = filtersArray.values.find(x => x.value === value);
            filterToChange.active = false;

            setFilters(filtersCopy);
            applyFilters({tempFilters: filtersCopy, newPageNumber: 0});
        }
    }

    const handleVendorDropdownChange = (_, {value}) => {
        setPartNumberChosen(null);
        setVendorChosen(value);
    }

    const handlePartNumberDropdownChange = (_, {value}) => {
        setPartNumberChosen(value);
    }

    const onFiltersReset = () => {
        return navigate(0);
    }

    const onPageNumberChange = (_, {activePage}) => {
        setPageNumber(activePage);
    };

    const onSearchSubmit = () => {
        const filtersCopy = cloneDeep(filters);
        filtersCopy.refresh = true;
        setFilters(filtersCopy);
    }

    const handlePartNumberSubmit = () => {
        navigate(partNumberChosen);
    }

    const handleClearPartNumberFilterClick = () => {
        setVendorChosen(null);
        setPartNumberChosen(null);
    }

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    const closeModalHandle = () => {
        setProductAddedInformation(null);
    }

    const handleProductAddedModalClose = (confirmationChoice) => async () => {
        const shouldGoToCart = confirmationChoices.confirm === confirmationChoice;

        if (shouldGoToCart) {
            return navigate("/cart");
        }

        closeModalHandle();
    };

    return (
        <Container className="argus-container" fluid>
            {totalResults === 0 &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header as="h1" textAlign="center">No products matching your criteria.</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center" verticalAlign="middle">
                            <Button compact
                                    className="blue-button"
                                    onClick={() =>  navigate(-1)}
                                    size="small">
                                Try again
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            {totalResults > 0 &&
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={4} />
                        <Grid.Column width={4} verticalAlign="middle">
                            <Header as="h5">Search within this collection</Header>
                        </Grid.Column>
                        <Grid.Column width={8} verticalAlign="middle">
                            <Form size="tiny">
                                <Form.Group inline>
                                    <Form.Dropdown
                                        selection
                                        clearable
                                        label="Vendor"
                                        options={vendorOptions}
                                        onChange={handleVendorDropdownChange}
                                        value={vendorChosen}
                                        loading={partNumberDropdownLoading}
                                    />
                                    <Form.Dropdown
                                        selection
                                        search
                                        label="Part Number"
                                        disabled={!vendorChosen}
                                        options={partNumberOptions}
                                        onChange={handlePartNumberDropdownChange}
                                        value={partNumberChosen}
                                        loading={partNumberDropdownLoading}
                                    />
                                    <Button onClick={handlePartNumberSubmit}
                                            className="green-basic-button"
                                            compact
                                            type="button"
                                            disabled={!partNumberChosen}>
                                        Go
                                    </Button>
                                    {vendorChosen && partNumberChosen &&
                                        <Button className="red-basic-button"
                                                compact
                                                type="button"
                                                onClick={handleClearPartNumberFilterClick}>
                                            Reset
                                        </Button>
                                    }
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <GridFilters
                                onCheckboxChange={onCheckboxChange}
                                onFilterRemove={onFilterRemove}
                                onFiltersReset={onFiltersReset}
                                filters={filters.filters}
                                onSearchSubmit={onSearchSubmit}
                                onRadioChange={onRadioChange}
                            />
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <GridProducts
                                products={products}
                                prices={prices}
                                pageNumber={pageNumber}
                                filters={filters.filters}
                                pageNumberUpdate={onPageNumberChange}
                                totalResults={totalResults}
                                pageSize={pageSize}
                                itemsPerRow={3}
                                setProductAddedInformation={setProductAddedInformation}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            {!!productAddedInformation &&
                <AddedToCartModal open={addedToCartModelOpen}
                                  setOpen={setAddedToCartModalOpen}
                                  item={productAddedInformation}/>

            }
        </Container>
    );
}

export default ResultGrid;