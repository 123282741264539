import React from "react";
import { Container, Grid, Header, Image, Card } from "semantic-ui-react";
import { useNavigate, Link } from "react-router-dom";

const cataloguePreviews = [
    {
        name: "Ignition coils and harnesses",
        previewImage: "https://argusweb.azureedge.net/argus-web/catalogues/catalogue-ignition-coils-and-harnesses-preview.jpg",
        link: "https://argusweb.azureedge.net/argus-web/catalogues/catalogue-ignition-coils-and-harnesses.pdf"
    },
    {
        name: "Fuel pumps",
        previewImage: "https://argusweb.azureedge.net/argus-web/catalogues/catalogue-fuel-pumps-preview.jpg",
        link: "https://argusweb.azureedge.net/argus-web/catalogues/catalogue-fuel-pumps.pdf"
    }
]

const Catalogues = () => {
    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Catalogues</div>
            </div>
            <Grid container>
                <Card.Group centered>
                    {cataloguePreviews.map((c, index) => {
                        return (
                            <Card key={index} className="transparent-card" as="a" href={c.link} target="_blank">
                                <Image src={c.previewImage} size="small" centered />
                                <Card.Content>
                                    <Card.Header textAlign="center">{c.name}</Card.Header>
                                </Card.Content>
                            </Card>
                        )
                    })}
                </Card.Group>
            </Grid>
        </div>
    );
};
export default Catalogues;
