import React, {useEffect, useState} from "react";
import {cloneDeep, remove} from "lodash";
import {LargeGridFilters} from "../LargeGridFilters";

const GridFilters = ({
    filters,
    activeFilters
}) => {

    return (
        <LargeGridFilters
            activeFilters={activeFilters}
            filters={filters}
        />
    )
}

export default GridFilters;