import React, {Fragment, useEffect, useState} from "react";
import Login from "../../components/Login";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import { useLocation, useNavigate } from "react-router-dom";
import {ApiCallStatus, LoggedInStatus} from "../../utils/constants";
import {authenticate} from "../../api/authenticationController";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};

const createLoginData = async ({
    email,
    password,
    setLoggedIn,
    setCreateLoginDataCallStatus
}) => {
  setCreateLoginDataCallStatus(ApiCallStatus.InProgress);
  try {
    let response = await authenticate({
      email,
      password
    });

    if (response?.success) {
        setCreateLoginDataCallStatus(ApiCallStatus.Succeeded);
        setLoggedIn(LoggedInStatus.Yes);
    }
    else {
        setCreateLoginDataCallStatus(ApiCallStatus.Failed);
    }
  } catch (error) {
      setCreateLoginDataCallStatus(ApiCallStatus.Error);
  }
}

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);

    const [createLoginDataCallStatus, setCreateLoginDataCallStatus] = useState(ApiCallStatus.NotStarted);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const createLoginDataAsync = async ({
        email,
        password
    }) => {
        await createLoginData({
            email,
            password,
            setLoggedIn,
            setCreateLoginDataCallStatus
        })
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (createLoginDataCallStatus === ApiCallStatus.Succeeded && loggedIn === LoggedInStatus.Yes) {
            const timer = setTimeout(() => {
                const from = location.state?.from || '/';
                navigate(from);
            }, 2000);
            return () => clearTimeout(timer);
        }
        else if (createLoginDataCallStatus === ApiCallStatus.NotStarted && (loggedIn === LoggedInStatus.Yes || loggedIn === LoggedInStatus.Refreshed)) {
            const from = location.state?.from || '/';
            navigate(from);
        }
        else if (createLoginDataCallStatus === ApiCallStatus.NotStarted && loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
        }
    }, [createLoginDataCallStatus, loggedIn]);

    const allLoadingStates = [
        createLoginDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader />
            <Login createLoginDataAsync={createLoginDataAsync}
                   createLoginDataCallStatus={createLoginDataCallStatus} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default LoginPage;
