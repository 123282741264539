import React, {useEffect, useState} from "react";
import {Button, Card, Grid, Header, Icon, Image, Label, Popup} from 'semantic-ui-react'
import DOMPurify from 'dompurify';
import "./style.css";
import {round} from "lodash";
import {SouthAfricanRand} from "../../utils/helpers";
import { PriceCategory } from "../../utils/constants";

const GridItem = ({
    title,
    price,
    priceCategory,
    vendor,
    image,
    productId,
    handle,
    vendorImage,
    quality,
    fitment,
    availability,
    warranty,
    warrantyText,
    applicationPosition,
    applicationPositionImage,
    vendorDescription,
    showVehicleSpecificInformation,
    isKiosk,
    bodyHtml,
    sku,
    addToCart,
    updateCart,
    quantityInCart,
    hideAddToCart = false
}) => {

    const [updatedQuantity, setUpdatedQuantity] = useState(quantityInCart);
    const fitmentImage = "https://argusweb.azureedge.net/argusblob01/web/fitment.jpg"
    const qualityImage = "https://argusweb.azureedge.net/argusblob01/web/quality.jpg"
    const availabilityImage = "https://argusweb.azureedge.net/argusblob01/web/availability.jpg"

    useEffect(() => {
        setUpdatedQuantity(Number(quantityInCart))
    }, [quantityInCart])

    let formattedPrice;
    if (!!price && price > 0)
    {
        formattedPrice = SouthAfricanRand.format(round(price, 2))
    }
    const applicationPositionParts = applicationPosition?.split(":");
    if (applicationPositionParts && applicationPositionParts.length > 1) {
        applicationPosition = applicationPositionParts[1].trim();
    }

    let fitmentText = fitment?.split("$");
    let fitmentTextSplit = [];
    if (fitmentText) {
        if (fitmentText.length > 1) {
            fitmentTextSplit = fitmentText[1].split("|");
        }
        else {
            fitmentTextSplit = fitmentText;
        }
    }
    let productUrl = `/product/${sku}`
    let colourForAvailability;
    let colourForQuality;
    switch (availability) {
        case "In Stock":
            colourForAvailability = "green";
            break;
        case "Low Stock":
            colourForAvailability = "yellow";
            break;
        case "3-5 Days":
            colourForAvailability = "orange";
            break;
        case "No Stock":
            colourForAvailability = "red";
            break
        default:
            colourForAvailability = "";
            break;
    }
    switch (quality) {
        case "A+":
            colourForQuality = "violet";
            break;
        case "A":
            colourForQuality = "blue";
            break;
        case "B":
            colourForQuality = "teal";
            break;
        case "Other":
            colourForQuality = "olive";
            break;
        default:
            colourForQuality = "";
            break;
    }
    const sanitizedData = () => ({
        __html: DOMPurify.sanitize(bodyHtml)
    })

    const handleAddToCartClick = () => {
        const cartProduct = {
            id: productId,
            name: title,
            brand: vendor,
            price: price,
            image: image,
            sku
        }
        addToCart(cartProduct)
    }

    return (
        <Card raised>
            <Image className="product-grid-item-image" src={image} wrapped ui={false} />
            <div>
                {vendorImage &&
                    <Popup hoverable size="small" trigger={<div className="top-left">
                        <Image src={vendorImage} size='mini'/>
                    </div>} >
                        <Popup.Content>
                            <Grid centered>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>{vendorDescription}</Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Popup.Content>
                    </Popup>
                }
                {applicationPositionImage && showVehicleSpecificInformation &&
                    <Popup hoverable size="small" trigger={<div className="top-right">
                        <Image src={applicationPositionImage} size='mini'/>
                    </div>}>
                        <Popup.Content className="text-align-center">
                            <p>Fitting position</p>
                            <Header as="h2">{applicationPosition}</Header>
                        </Popup.Content>
                    </Popup>
                }
                {fitment && showVehicleSpecificInformation &&
                    <Popup hoverable size="small" trigger={<div className="bottom-right">
                        <Image src={fitmentImage} size='mini'/>
                    </div>}>
                        <Popup.Content className="text-align-center">
                            <Header as="h2">Fitment instructions</Header>
                            <div>
                                {fitmentTextSplit?.map(text => {
                                    const isBold = (text.includes("Critical specs") || text.includes("Special Fitment Note")) ? "bold" : "normal";
                                    return <p style={{fontWeight: isBold}}>{text}</p>
                                })}
                            </div>
                        </Popup.Content>
                    </Popup>
                }
            </div>
            <Card.Content className="no-top-border no-padding">
                {isKiosk &&
                    <Card.Description className="product-grid-item-title padding-1em">
                        <div className="product-grid-item-title">
                            <Header as="h6">
                                {title ?? ""}
                            </Header>
                        </div>
                    </Card.Description>
                }
                {!isKiosk &&
                    <Card.Description className="product-grid-item-title" as="a" href={productUrl}>
                        <p className="b3">
                            {title ?? ""}
                        </p>
                    </Card.Description>
                }
                <div>
                    {availability && <Popup hoverable wide='very' size="small"
                           trigger={<Label size="tiny" className={`${colourForAvailability}-label`}>
                               {availability}
                           </Label>}>
                        <Popup.Content className="text-align-center">
                            <a href="https://www.startmycar.co.za/pages/delivery-policy">
                                <Image className="popup-image"
                                       src={availabilityImage}
                                       size='huge'/>
                            </a>
                        </Popup.Content>
                    </Popup>}
                    {warranty && <Popup hoverable size="small"
                           trigger={<Label size="tiny" className="green-label">
                               {warranty}
                           </Label>}>
                        <Popup.Content>
                            <Grid centered>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>{warrantyText}</Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Popup.Content>
                    </Popup>}
                     {quality &&
                         <Popup hoverable
                                wide='very'
                                size="small"
                                trigger={<Label size="tiny" className={`${colourForQuality}-label`}>{quality}</Label>}>
                        <Popup.Content className="text-align-center">
                            <Image src={qualityImage}
                                   href="https://www.startmycar.co.za/pages/delivery-policy"
                                   size='large'/>
                        </Popup.Content>
                    </Popup>}
                    <Popup hoverable wide="very" size="small" trigger={<Icon name="info circle" />}>
                        <div className="product-description-modal-container">
                            <div className="product-description-modal" dangerouslySetInnerHTML={sanitizedData()} />
                        </div>
                    </Popup>
                </div>
                <Card.Meta className="mt-3 mb-3 product-grid-item-money">
                    {!!formattedPrice &&
                        <>
                            <Header as="h5">
                                {formattedPrice}
                            </Header>
                            {!!priceCategory && priceCategory !== PriceCategory.Retail && <sub>(Ex. VAT)</sub>}
                        </>
                    }
                </Card.Meta>
                {!hideAddToCart &&
                    <div className={isKiosk ? "mt-1 mb-2" : "mb-2"}>
                        <Button
                            className="blue-button"
                            size="small"
                            type="button"
                            fluid
                            disabled={!formattedPrice}
                            onClick={handleAddToCartClick}>
                            Add to cart
                        </Button>
                    </div>
                }

            </Card.Content>
        </Card>
    )
}

export default GridItem;