import React, {useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { cloneDeep, find, remove, round, sum } from "lodash";
import "./style.css";
import {useCart} from "react-use-cart";
import { Grid, Segment, Image, Input, Header, Button, Icon } from "semantic-ui-react";
import ConfirmationModal, {confirmationChoices} from "../ConfirmationModal";
import { SouthAfricanRand} from "../../utils/helpers";
import { PriceCategory } from "../../utils/constants";



const Cart = ({
    loading,
    priceCategory,
    cartItems,
    vat,
    subtotal,
    vatAmount,
    total,
    createCheckout
}) => {
    const {
        items,
        setItems,
        removeItem,
        cartTotal,
        emptyCart,
        clearCartMetadata,
    } = useCart();
    const [clearCartModalOpen, setClearCartModalOpen] = useState(false);

    const scrollToTop = () => {
        window.scroll(0, 0);
    }

    useEffect(() => {
        scrollToTop();
    }, []);

    const clearCart = () => {
        emptyCart();
        clearCartMetadata();
        setClearCartModalOpen(false);
    }

    const handleClearCartModalOpen = () => setClearCartModalOpen(true);

    const closeModalHandle = () => {
        setClearCartModalOpen(false);
    }

    const handleClearCartModalClose = (confirmationChoice) => async () => {
        const shouldClear = confirmationChoices.confirm === confirmationChoice;

        if (shouldClear) {
            clearCart();
        }

        closeModalHandle();
    };

    const onRemoveItemClick = (e) => {
        e.preventDefault();
        const cartItemsCopy = cloneDeep(items);
        remove(cartItemsCopy, x => x.sku === e.target.dataset.sku);
        removeItem(e.target.dataset.sku);
        setItems(cartItemsCopy);
    }

    const handleItemQuantityTextChange = (e, {value, sku}) => {
        const cartItemsClone = cloneDeep(items);
        const itemToChange = cartItemsClone.find(item => item.sku === sku);
        let newQuantity = Number(value);

        if (newQuantity < 0) newQuantity = 1;

        itemToChange.quantity = newQuantity;

        setItems(cartItemsClone);
    }

    const handleCheckoutClick = async () => {
        await createCheckout();
    }

    const goToCheckout = async (e) => {
        e.preventDefault();
        const jsonCartItems = JSON.stringify(cartItems);
        const jsonGlobalItems = JSON.stringify(items);

        if (jsonCartItems !== jsonGlobalItems) {
            setItems(cartItems);
        }

        await handleCheckoutClick();
    }

    return (
      <div className="argus-min-height">
          <div className="argus-title-background mb-5">
              <div className="argus-title">Cart</div>
          </div>
          {!loading &&
              <>
                  {cartTotal > 0 &&
                      <Grid container>
                          <Grid.Row>
                              <Grid.Column width={10} textAlign="right">
                                  <Button className="red-button" size="tiny" type="button" compact
                                          onClick={handleClearCartModalOpen}>Clear</Button>
                              </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                              <Grid.Column width={10}>
                                  {!!cartItems && cartItems.length > 0 &&
                                      <>
                                          {cartItems.map((item, index) => {
                                              return (
                                                  <Segment key={index} padded>
                                                      <Grid container>
                                                          <Grid.Row stretched>
                                                              <Grid.Column width={4}>
                                                                  <Image src={item.image}/>
                                                              </Grid.Column>
                                                              <Grid.Column width={8}>
                                                                  <Link to={`/product/${item.sku}`}>
                                                                      <Header as="h4">{item.name}</Header>
                                                                      <p className="b3">{item.sku}</p>
                                                                      <p className="b3">{SouthAfricanRand.format(round(item.price, 2))} ea</p>
                                                                      {!!priceCategory && priceCategory !== PriceCategory.Retail && <><sub>(Ex. VAT)</sub></>}
                                                                  </Link>
                                                              </Grid.Column>
                                                              <Grid.Column width={4}>
                                                                  <Grid container>
                                                                      <Grid.Row textAlign="right">
                                                                          <Grid.Column verticalAlign="bottom"
                                                                                       width={16}>
                                                                              <Header as="h4">
                                                                                  {SouthAfricanRand.format(round(item.price * item.quantity, 2))}
                                                                              </Header>
                                                                              {!!priceCategory && priceCategory !== PriceCategory.Retail && <><sub>(Ex. VAT)</sub></>}
                                                                          </Grid.Column>
                                                                      </Grid.Row>
                                                                      <Grid.Row textAlign="center">
                                                                          <Grid.Column width={8}/>
                                                                          <Grid.Column width={8}>
                                                                              <label className="b3">Quantity</label>
                                                                              <Input
                                                                                  id={item.sku}
                                                                                  sku={item.sku}
                                                                                  className="text-align-center"
                                                                                  onChange={handleItemQuantityTextChange}
                                                                                  fluid
                                                                                  value={item.quantity}
                                                                              />
                                                                          </Grid.Column>
                                                                      </Grid.Row>
                                                                      <Grid.Row verticalAlign='bottom'
                                                                                textAlign="right">
                                                                          <Grid.Column width={16}>
                                                                              <a id={item.id}
                                                                                 data-sku={item.sku}
                                                                                 href="#"
                                                                                 onClick={() => console.log("")}>
                                                                                  Favourite
                                                                              </a>
                                                                              {" | "}
                                                                              <a id={item.id}
                                                                                 data-sku={item.sku}
                                                                                 href="#"
                                                                                 onClick={onRemoveItemClick}>
                                                                                  Remove
                                                                              </a>
                                                                          </Grid.Column>
                                                                      </Grid.Row>
                                                                  </Grid>
                                                              </Grid.Column>
                                                          </Grid.Row>
                                                      </Grid>
                                                  </Segment>
                                              )
                                          })}
                                      </>
                                  }
                              </Grid.Column>
                              <Grid.Column width={6}>
                                  <Segment padded>
                                      <Grid container>
                                          <Grid.Row>
                                              <Grid.Column width={16}>
                                                  <Header as="h4">
                                                      <Icon name="box" />
                                                      Order summary
                                                  </Header>
                                              </Grid.Column>
                                          </Grid.Row>
                                          <Grid.Row>
                                              <Grid.Column width={8}>
                                                  <p className="b3">Subtotal</p>
                                              </Grid.Column>
                                              <Grid.Column width={8} textAlign="right">
                                                  {!!subtotal && <p className="b3">{SouthAfricanRand.format(round(subtotal, 2))}</p>}
                                              </Grid.Column>
                                          </Grid.Row>
                                          <Grid.Row>
                                              <Grid.Column width={8}>
                                                  <p className="b3">VAT</p>
                                                  <sub>(VAT: {vat}%)</sub>
                                              </Grid.Column>
                                              <Grid.Column width={8} textAlign="right">
                                                  {!!vatAmount && <p className="b3">{SouthAfricanRand.format(round(vatAmount, 2))}</p>}
                                              </Grid.Column>
                                          </Grid.Row>
                                          <Grid.Row>
                                              <Grid.Column width={8}>
                                                  <p className="b3">Total</p>
                                              </Grid.Column>
                                              <Grid.Column width={8} textAlign="right">
                                                  {!!total && <p className="b3">{SouthAfricanRand.format(round(total, 2))}</p>}
                                              </Grid.Column>
                                          </Grid.Row>
                                          <Grid.Row>
                                              <Grid.Column width={16}>
                                                  {cartItems && cartItems.length > 0 &&
                                                      <Button
                                                          className="blue-button"
                                                          fluid
                                                          onClick={goToCheckout}
                                                      >
                                                          Checkout
                                                      </Button>
                                                  }
                                              </Grid.Column>
                                          </Grid.Row>
                                      </Grid>
                                  </Segment>
                              </Grid.Column>
                          </Grid.Row>
                      </Grid>
                  }
                  {cartTotal === 0 &&
                      <Grid container>
                          <Grid.Row>
                              <Grid.Column width={16} textAlign="center" verticalAlign="middle">
                                  <Header as="h4">Your cart is empty</Header>
                              </Grid.Column>
                          </Grid.Row>
                      </Grid>
                  }
              </>
          }
          <ConfirmationModal open={clearCartModalOpen}
                             closeModal={handleClearCartModalClose}
                             heading="Clear cart"
                             subHeading="Are you sure you want to clear the cart?" />
      </div>
    );
};

export default Cart;
