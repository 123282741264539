import React, {useEffect, useState} from "react";
import { Container, Grid, Header, Icon, Segment } from "semantic-ui-react";
import {
    ApiCallStatus, OrderNumberPrefix,
    PaymentConfirmationMessage,
    PaymentMethod,
    PaymentStatus,
    PaymentType
} from "../../utils/constants";
import "./style.css";
import {useCart} from "react-use-cart";

const PaymentConfirmation = ({ paymentData, orderData }) => {
    const { emptyCart } = useCart();
    const [heading, setHeading] = useState("");
    const [message, setMessage] = useState("");
    const [icon, setIcon] = useState("");
    const [iconColour, setIconColour] = useState("");

    useEffect(() => {
        if (!!paymentData) {
            if (paymentData.paymentStatusId === PaymentStatus.Complete) {
                setHeading(PaymentConfirmationMessage.HeadingComplete);
                setMessage(PaymentConfirmationMessage.MessageComplete);
                setIcon("check circle outline");
                setIconColour("green")
                emptyCart();
            }
            else if (paymentData.paymentStatusId === PaymentStatus.Pending) {
                setHeading(PaymentConfirmationMessage.HeadingPending);
                setMessage(PaymentConfirmationMessage.MessagePending);
                setIcon("check circle outline");
                setIconColour("yellow")
            }
            else if (paymentData.paymentStatusId === PaymentStatus.Failed) {
                setHeading(PaymentConfirmationMessage.HeadingFailed);
                setMessage(PaymentConfirmationMessage.MessageFailed);
                setIcon("times circle outline");
                setIconColour("red")
            }
        }
    }, [paymentData])

    return (
        <div className="argus-min-height">
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment padded>
                            <Grid padded>
                                <Grid.Row verticalAlign="middle" textAlign="center">
                                    <Grid.Column width={16}>
                                        <Icon name={icon} color={iconColour} size="massive"/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row verticalAlign="middle" textAlign="center">
                                    <Grid.Column width={16}>
                                        <Header as="h1">{heading}</Header>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row verticalAlign="middle" textAlign="center">
                                    <Grid.Column width={16}>
                                        <Header as="h3">{message}</Header>
                                    </Grid.Column>
                                </Grid.Row>
                                {paymentData?.paymentTypeId === PaymentType.EFT &&
                                    <>
                                        {!!orderData?.orderNumber &&
                                            <Grid.Row textAlign="center">
                                                <Grid.Column width={16}>
                                                    <p className="b1">
                                                        {`Reference: ${OrderNumberPrefix}${orderData.orderNumber}`}
                                                    </p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                <p className="b1">Your order is almost complete! We are just waiting for your EFT payment.</p>
                                                <p className="b1">To make the payment, please use one of the following EFT details:</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                <p className="b2">Standard Bank Benoni</p>
                                                <p className="b2">Account No: 020971117</p>
                                                <p className="b2">Branch Code: 013042</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                <p className="b2">Nedbank Business East Rand</p>
                                                <p className="b2">Account No: 1207564400</p>
                                                <p className="b2">Code: 198765</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                }
                                {paymentData?.paymentTypeId === PaymentType.CreditCardOnCollection &&
                                    <>
                                        {!!orderData?.orderNumber &&
                                            <Grid.Row textAlign="center">
                                                <Grid.Column width={16}>
                                                    <p className="b1">
                                                        {`Reference: ${OrderNumberPrefix}${orderData.orderNumber}`}
                                                    </p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                <p className="b1">Your order has been received.</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                <p className="b1 mb-2">Collection Address</p>
                                                <p className="b2">Argus Motor Company</p>
                                                <p className="b2">28 Great North Road</p>
                                                <p className="b2">Brentwood Park</p>
                                                <p className="b2">Benoni</p>
                                                <p className="b2">1501</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                <p className="b1 mb-2">Collection Times</p>
                                                <p className="b2">Mon - Thurs 8:00am – 4:30pm</p>
                                                <p className="b2">Fri 8:00am – 1:30pm</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                <p className="b1">
                                                    Please note that we will only process your order once you arrive and make payment.
                                                    Please be aware that additional time is required for order processing.
                                                    If you would like your order to be ready before you arrive,
                                                    please contact us to arrange this.
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                }
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default PaymentConfirmation;