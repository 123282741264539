import React, {useEffect, useState} from 'react'
import { Card, Container, Dropdown, Grid, Pagination } from 'semantic-ui-react'
import GridItem from "../GridItem";
import {useCart} from "react-use-cart";
import { cloneDeep, find } from "lodash";

export const GridProducts = ({
    products,
    prices,
    priceCategory,
    filters,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    totalResults,
    itemsPerRow,
    isKiosk,
    setProductAddedInformation
}) => {
    const { addItem, items, setItems } = useCart();
    const [showVehicleSpecificInformation, setShowVehicleSpecificInformation] = useState(false);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        // if (filters) {
        //     const manufacturers = filters.filter(x => x.type === "MANUFACTURER")[0];
        //     const models = filters.filter(x => x.type === "MODEL")[0];
        //     const variants = filters.filter(x => x.type === "VARIANT")[0];
        //     const years = filters.filter(x => x.type === "YEAR")[0];
        //
        //     const anyActiveManufacturers = manufacturers?.values.some(x => x.active);
        //     const anyActiveModels = models?.values.some(x => x.active);
        //     const anyActiveVariants = variants?.values.some(x => x.active);
        //     const anyActiveYears = years?.values.some(x => x.active);
        //
        //     setShowVehicleSpecificInformation(anyActiveManufacturers && anyActiveModels && anyActiveVariants && anyActiveYears)
        // }
    }, []);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#FFFFFF',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleAddToCart = (product) => {
        const cartProduct = {
            id: product.id,
            name: product.name,
            brand: product.vendor,
            sku: product.sku,
            price: product.price,
            image: product.image,
        }
        addItem(cartProduct, 1);
        setProductAddedInformation(product);
    }

    const handleUpdateQuantity = (productId, quantity) => {
        const itemsCopy = cloneDeep(items);
        const itemToUpdate = itemsCopy.find(x => x.id === productId)
        itemToUpdate.quantity = quantity;
        setItems(itemsCopy);
    }

    const handleGoToCartClick = () => {
        window.location.href = "/cart";
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Card.Group itemsPerRow={itemsPerRow}>
                        {!!products && !!prices && products.map((product, itemNumber) => {
                            const priceObject = find(prices, p => p.sku === product.sku)
                            const itemPrice = priceObject?.price ?? product.price;
                            return <GridItem
                                key={itemNumber}
                                title={product.name}
                                price={itemPrice}
                                priceCategory={priceCategory}
                                vendor={product.vendor}
                                image={product.image}
                                productId={product.productId}
                                vendorImage={product.vendorImage}
                                handle={product.handle}
                                vendorDescription={product.vendorDescription}
                                quality={product.quality}
                                fitment={product.fitment}
                                availability={product.availability}
                                warranty={product.warranty}
                                warrantyText={product.warrantyText}
                                applicationPosition={product.applicationPosition}
                                applicationPositionImage={product.applicationPositionImage}
                                // showVehicleSpecificInformation={showVehicleSpecificInformation}
                                // isKiosk={isKiosk}
                                bodyHtml={product.html}
                                sku={product.sku}
                                addToCart={handleAddToCart}
                                updateCart={handleUpdateQuantity}
                            />
                        })}
                    </Card.Group>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16} textAlign="right">
                    {totalResults && totalResults > 0 &&
                        <Pagination
                            activePage={pageNumber}
                            boundaryRange={1}
                            size="mini"
                            onPageChange={handlePageNumberChange}
                            siblingRange={1}
                            totalPages={Math.ceil(totalResults / pageSize)}
                        />
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
