import React from "react";
import AllRoute from "../router";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import PageLayout from "../PageLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive";


const DesktopContent = () => (
    <Router>
        <PageLayout>
            <AllRoute />
            <ToastContainer />
        </PageLayout>
    </Router>
);

const NonDesktopMessage = () => (
    <div style={styles.messageBox}>
        <p>
            Our application is currently optimized for desktop use.
            We are working hard to bring you a mobile-friendly version very soon.
        </p>
    </div>
);

// Styles for the message box
const styles = {
    messageBox: {
        padding: '20px',
        margin: '20px',
        textAlign: 'center',
        backgroundColor: '#f7f7f7',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        border: '1px solid #eaeaea',
    }
};

const App = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    });

    return (
        <div>
            {isDesktopOrLaptop ? <DesktopContent /> : <NonDesktopMessage />}
        </div>
    );
};



export default App;
