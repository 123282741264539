import React, { useEffect, useMemo, useRef, useState } from "react";
import {Box, Modal} from "@mui/material";
import { Button, Grid, Header, Image, Input, Item, Card } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { capitalize, concat, debounce, round, uniqBy } from "lodash";
import { getSearchSuggestions } from "../../api/searchController";
import { SouthAfricanRand } from "../../utils/helpers";
import "./style.css";
import { PriceCategory } from "../../utils/constants";

const fetchSearchSuggestions = async ({
    searchTerm,
    category,
    setSearchResults,
    setSearchLoading
}) => {
    setSearchLoading(true);
    try {
        const response = await getSearchSuggestions(searchTerm, category);
        if (response.success) {
            setSearchResults(response.data);
        }
    } catch (e) {
        console.error(e);
    } finally {
        setSearchLoading(false);
    }
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '95%',
    width: "75%",
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: 4,
};


const SearchModal = ({
    open,
    setOpen,
    setClose,
    gotoProductPage,
    currentUser
}) => {
    const navigate = useNavigate();
    const searchInputRef = useRef(null);
    const [searchInput, setSearchInput] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [tradeAccount, setTradeAccount] = useState(null);
    const [products, setProducts] = useState(null);

    const fetchSearchSuggestionsAsync = async () => {
        const fetchSearchSuggestionsOptions = {
            searchTerm,
            category: tradeAccount?.category ?? PriceCategory.Retail,
            setSearchResults,
            setSearchLoading
        }
        await fetchSearchSuggestions(fetchSearchSuggestionsOptions);
    }

    const changeHandler = (value) => {
        setSearchTerm(value);
    };

    const debouncedChangeHandler = useMemo(() => {
        return debounce(changeHandler, 300);
    }, []);

    useEffect(() => {
        if (!!currentUser?.tradeAccounts && currentUser.tradeAccounts.length > 0) {
            setTradeAccount(currentUser.tradeAccounts[0]);
        }
    }, [currentUser]);


    useEffect(() => {
        debouncedChangeHandler(searchInput);
    }, [searchInput]);

    useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            fetchSearchSuggestionsAsync();
        }
    }, [searchTerm]);

    useEffect(() => {
        if (!!searchResults.products && !!searchResults.skuProducts) {
            const newProductsArray = concat(searchResults.products, searchResults.skuProducts);
            const uniqueArray = uniqBy(newProductsArray, x => x.sku)
            setProducts(uniqueArray);
        }
    }, [searchResults?.products, searchResults?.skuProducts]);

    const onSearchInputChange = (_, {value}) => {
        setSearchInput(value);
    }

    const closeModal = () => {
        setClose();
    }

    return (
        <Modal open={open} onClose={closeModal}>
            <Box sx={style}>
                <Grid divided="vertically">
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <Input
                                className="borderless-input"
                                focus
                                autoFocus
                                onChange={onSearchInputChange}
                                value={searchInput}
                                placeholder="Search products, vehicles or categories..."
                                ref={searchInputRef}
                                fluid
                                size="massive"
                                loading={searchLoading}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {!!searchResults &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Grid padded divided>
                                    {!!products && products.length > 0 &&
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Header as="h3">Products</Header>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Card.Group itemsPerRow={5}>
                                                                {products.map((p, index) => {
                                                                    return (
                                                                        <Card key={index}>
                                                                            {!!p?.image &&
                                                                                <Image size="medium" src={p.image}/>
                                                                            }
                                                                            <Card.Content>
                                                                                {!!p?.name &&
                                                                                    <Card.Description as={Button}
                                                                                                      className="background-white borderless no-pad-all"
                                                                                                      onClick={() => gotoProductPage(p.sku)}>
                                                                                        {p.name}
                                                                                    </Card.Description>
                                                                                }
                                                                                {!!p?.price &&
                                                                                    <Card.Meta>
                                                                                        <span>{SouthAfricanRand.format(round(p.price, 2))}</span>
                                                                                    </Card.Meta>
                                                                                }
                                                                            </Card.Content>

                                                                        </Card>
                                                                    )
                                                                })}
                                                            </Card.Group>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>

                                            </Grid.Column>
                                        </Grid.Row>

                                    }
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </Box>
        </Modal>
    );
};
export default SearchModal;