import React, {Fragment, useEffect, useState} from "react";
import PartNumberSearchResults from "../../components/PartNumberSearchResults";
import SiteHeader from "../../components/SiteHeader";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getCurrentUser} from "../../api/authenticationController";
import {useNavigate} from "react-router-dom";
import {ApiCallStatus, LoggedInStatus} from "../../utils/constants";

const checkIsLoggedIn = async ({
   setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        setLoggedIn(LoggedInStatus.No);

    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const clearCookies = async () => {
    await removeAuthCookies();
};

const PartNumberSearchResultPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {

        if (loggedIn === LoggedInStatus.Yes) {
            fetchCurrentUserAsync();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            return navigate(0);
        }
        else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
        }
    }, [loggedIn])

    const allLoadingStates = [
        currentUserCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser} />
            {/*<PartNumberSearchResults setLoading={setLoading} />*/}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default PartNumberSearchResultPage;