import axios from "axios";
import {local_storage_refresh_token, local_storage_token, session_storage_token} from "../utils/constants";

export const createAxios = url => {
    const authenticateUrl = `${process.env.REACT_APP_IDP_URL}/api/authentication/authenticate`;
    const token = JSON.parse(localStorage.getItem(local_storage_token));
    const sessionToken = JSON.parse(localStorage.getItem(session_storage_token));

    const axiosInstance = axios.create({
        baseURL: url,
        headers: {
            "Content-Type": "application/json",
        },
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            } else if (sessionToken) {
                config.headers["Authorization"] = `Bearer ${sessionToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (originalConfig.url !== authenticateUrl && err.response) {
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const authAxiosInstance = axios.create({
                            baseURL: `${process.env.REACT_APP_IDP_URL}/api/authentication`,
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });
                        const rs = await authAxiosInstance.post("refresh-token", {
                            refreshToken: JSON.parse(localStorage.getItem(local_storage_refresh_token)),
                        });

                        const { token, refreshToken } = rs.data;
                        localStorage.setItem(local_storage_token, token);
                        localStorage.setItem(local_storage_refresh_token, refreshToken);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );

    return axiosInstance;
};
