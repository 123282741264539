import React, {useEffect, useState} from "react";
import {Button, Grid, Image, Segment} from "semantic-ui-react";
import { round } from "lodash";

const PaymentPayfast = ({
    paymentOrderInfo,
    paymentUserInfo
}) => {
    const [userId, setUserId] = useState(null);
    const [payfast, setPayfast] = useState(null);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        if (!!paymentOrderInfo?.payfast){
            setPayfast(paymentOrderInfo.payfast);
        }
    }, [paymentOrderInfo?.payfast]);

    useEffect(() => {
        if (!!paymentUserInfo?.userId){
            setUserId(paymentUserInfo.userId);
        }
    }, [paymentUserInfo?.userId]);

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Payment</div>
            </div>
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment padded>
                            <Grid container>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Image src="https://argusweb.azureedge.net/argus-web/payment/payfast.svg" size="small" fluid centered/>
                                    </Grid.Column>
                                </Grid.Row>
                                {!!payfast &&
                                    <Grid.Row textAlign="center">
                                        <Grid.Column width={16}>
                                            <p className="b1 mb-4">
                                                You will be securely redirect to Payfast to complete this transaction.
                                            </p>
                                            <form action="https://sandbox.payfast.co.za/eng/process" method="post">
                                                <input type="hidden" name="merchant_id" value={payfast.merchantId} />
                                                <input type="hidden" name="m_payment_id" value={payfast.merchantPaymentId} />
                                                <input type="hidden" name="merchant_key" value={payfast.merchantKey} />
                                                <input type="hidden" name="custom_str1" value={paymentOrderInfo.orderId} />
                                                {!!userId && <input type="hidden" name="custom_str2" value={userId} />}
                                                <input type="hidden" name="amount" value={round(paymentOrderInfo.total, 2)} />
                                                <input type="hidden" name="item_name" value={paymentOrderInfo.orderNumber} />
                                                <input type="hidden" name="return_url" value={`${payfast.returnUrl}`} />
                                                <input type="hidden" name="cancel_url" value={`${payfast.cancelUrl}`} />
                                                <input type="hidden" name="notify_url" value={payfast.notifyUrl} />
                                                <Button className="blue-button"
                                                        type="submit">
                                                    Pay now
                                                </Button>
                                            </form>
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    )

}

export default PaymentPayfast;