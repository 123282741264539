import React from "react";
import {Grid, Header} from "semantic-ui-react";

const Faq = () => {
    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Frequently asked questions</div>
            </div>
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h2" className="underline">Shipping information</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5" className="underline">What are the shipping options available for orders from Argus?</Header>
                        <p className="b1">
                            At Argus, we offer convenient shipping options to ensure your motor spares reach you
                            efficiently. For orders within our local and regional areas, we utilize our own fleet of
                            vehicles for delivery. We take pride in providing reliable and timely service using our
                            dedicated team. For orders that require delivery further afield, we have partnered with RAM
                            couriers, a trusted logistics provider, to ensure your order reaches you securely.
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5"  className="underline">Are there any shipping fees associated with orders from Argus?</Header>
                        <p className="b1">
                            We are pleased to inform you that Argus offers free shipping on orders over R2500. This
                            applies to both local and regional deliveries within our coverage areas. It's our way of
                            providing added value and convenience to our valued customers.
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5"  className="underline">Do you ship internationally?</Header>
                        <p className="b1">
                            Yes, at Argus, we cater to international customers as well. We regularly ship to countries
                            within the Southern African Development Community (SADC) region. If you are located outside
                            of the SADC countries and require international shipping, we can accommodate your request.
                            We have experience in shipping to various destinations worldwide and can provide the
                            necessary documentation to facilitate the process.
                        </p>
                        <p className="b1">
                            For further details on international shipping, including shipping fees, delivery times, and
                            specific requirements for your location, we kindly request international customers to
                            contact our dedicated customer support team.
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5"  className="underline">Can I split my order to ship to different locations?</Header>
                        <p className="b1">
                            Yes, we can accommodate splitting your order to different locations, provided that each
                            location meets the minimum order value required for free shipping. At Argus, we understand
                            that our customers may have specific requirements for shipping items to multiple
                            destinations.
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5"  className="underline">How might I obtain an estimated date of delivery?</Header>
                        <p className="b1">
                            At Argus, we understand the importance of timely delivery for our customers. Here's how you
                            can obtain an estimated date of delivery for your order:
                        </p>
                        <br />
                        <ol>
                            <li>
                                <span className="b2">
                                    <span><b>In-stock items: </b></span>
                                    If you have ordered an item that is marked as in stock
                                    on our website, we strive to ship it as quickly as possible. In-stock items ordered
                                    before 8am on a business day will generally be shipped on the same day. Orders
                                    placed after 8am will be shipped on the next business day. Please note that delivery
                                    times may vary depending on your location.
                                </span>

                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Leadtime items: </b></span>
                                    For items that are marked as leadtime items on our website, the estimated shipping
                                    time will be specified on each particular item. Leadtime items may have longer
                                    processing times before they are shipped. We clearly indicate the leadtime on the
                                    website to provide you with accurate information regarding when you can expect your
                                    order to be dispatched.
                                </span>
                            </li>
                        </ol>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row />
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h2" className="underline">Payment information</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5"  className="underline">What payments methods are available at Argus?</Header>
                        <p className="b1">
                            At Argus, we offer various convenient payment methods to accommodate our customers'
                            preferences. Here are the available options:
                        </p>
                        <br/>
                        <ol>
                            <li>
                                <span className="b2">
                                    <span><b>Credit accounts: </b></span>
                                    Registered customers who have opened an account with Argus can make purchases on
                                    credit, up to their approved credit limit. This option allows eligible customers to
                                    pay for their orders within the agreed-upon credit terms.
                                </span>

                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Payfast or EFT: </b></span>
                                    All customers, regardless of whether they have a credit account, can make payments
                                    securely through our website using Payfast or Electronic Funds Transfer (EFT).
                                    Payfast enables quick and secure online payments using various payment methods,
                                    including credit cards, debit cards, and instant EFT.
                                </span>
                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Pay on Collection: </b></span>
                                    For customers who prefer to collect their orders directly from our premises, we
                                    offer the option to pay on collection. This allows you to pay for your order when
                                    you arrive to pick it up. Please note that the processing time of your order will
                                    depend on the chosen payment method and how long it takes for the payment to clear.
                                </span>
                            </li>
                        </ol>
                        <p className="b1">
                            We understand that each payment method has different processing times, and we strive to
                            process orders promptly once payment has been confirmed. Please note that for credit
                            accounts, the order processing time is subject to credit limit availability and account
                            verification.
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5"  className="underline">Can I split my payment?</Header>
                        <p className="b1">
                            Yes, at Argus, we offer the flexibility to split your payment. Here's how you can do it:
                        </p>
                        <br/>
                        <ol>
                            <li>
                                <span className="b2">
                                    <span><b>For Credit Account Holders: </b></span>
                                    If you have a credit account with Argus, you can use your available credit to pay
                                    for your orders. However, if the total order amount exceeds your credit limit, you
                                    have the option to split the payment. You can pay for the remaining balance using
                                    Payfast, Electronic Funds Transfer (EFT), or another convenient payment method
                                    available on our website.
                                </span>

                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Using Payfast, EFT, or other payment methods: </b></span>
                                    For customers who do not have a credit account, or for credit account holders who
                                    wish to split their payment beyond their credit limit, you can utilize Payfast, EFT,
                                    or other payment methods available on our website. These options allow you to make
                                    separate payments for different portions of your order, providing you with the
                                    flexibility to split your payment according to your needs.
                                </span>
                            </li>
                        </ol>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h2" className="underline">Orders and Returns</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5" className="underline">How do I return or exchange an item?</Header>
                        <p className="b1">
                            Returning or exchanging an item at Argus is a straightforward process. Here are the steps to
                            follow:
                        </p>
                        <br/>
                        <ol>
                            <li>
                                <span className="b2">
                                    <span><b>Log the return request: </b></span>
                                    To initiate a return or exchange, please get in touch with your salesperson and log
                                    the return request. They will guide you through the process and provide you with any
                                    necessary instructions.
                                </span>

                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Arrange for item return: </b></span>
                                    Once your return request is logged, we will arrange for the item to be returned to
                                    us. Our team will provide you with the necessary details and assistance to ensure a
                                    smooth return process.
                                </span>
                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Evaluate the returned item: </b></span>
                                    Upon receiving the returned item, our team will thoroughly evaluate its condition
                                    and verify its eligibility for a return or exchange. We will assess factors such as
                                    the item's condition and adherence to our return policy.
                                </span>
                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Replacement or refund: </b></span>
                                    If the returned item passes the inspection process, we will proceed with either
                                    replacing the item with a suitable alternative or issuing a refund.
                                    Refunds will be processed through the same payment channel that was used for the
                                    original purchase.
                                </span>
                            </li>
                        </ol>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5" className="underline">How do I cancel an order?</Header>
                        <ol>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Order Cancellation: </b></span>
                                    You can cancel your order before it has been dispatched from our facility.
                                    Once the order has been dispatched, cancellation becomes more complex,
                                    and certain conditions apply.
                                </span>

                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Restocking Fee: </b></span>
                                    If an order has been dispatched and you wish to cancel it,
                                    please note that a 10% restocking fee will apply. This fee covers the costs associated with
                                    returning the item to our inventory and restocking it for future orders.
                                </span>
                            </li>
                        </ol>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h2" className="underline">Contact information</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5" className="underline">How can I contact the Argus customer support team?</Header>
                        <p className="b1">
                            Our dedicated customer support team is available to assist you with any queries or concerns
                            you may have. Here are the contact options:
                        </p>
                        <br/>
                        <ol>
                            <li>
                                <span className="b2">
                                    <span><b>Email: </b></span>
                                    You can email us at
                                    <a href="mailto:info@argusmotoring.co.za"> info@argusmotoring.co.za</a>.
                                    We strive to respond to all emails within 24 hours.
                                </span>

                            </li>
                            <li className="py-3">
                                <span className="b2">
                                    <span><b>Phone: </b></span>
                                    You can reach us by phone at 011 747 5200 during our business hours.
                                </span>
                            </li>
                            <p className="b1">
                                Our customer support team is committed to providing prompt and helpful assistance to
                                ensure a satisfactory experience for all our customers.
                            </p>
                        </ol>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};
export default Faq;
