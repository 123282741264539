import React, {Fragment, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "flag-icon-css/css/flag-icons.min.css";
import "./style.css";
import {Button, Dropdown, Form, Grid, Header, Icon, Image, Label, Menu} from "semantic-ui-react";
import {getItem} from "../../utils/storageHelper";
import {storageKeys} from "../../api/storageKeys";
import ChooseVehicleModal from "../ChooseVehicleModal";
import SavedVehicleModal from "../SavedVehicleModal";
import SavedVehiclesModal from "../SavedVehiclesModal";
import { search } from "../../api/searchController";
import TopCategoriesItem from "../TopCategoriesItem";
import { capitalize, cloneDeep, lowerCase, upperCase } from "lodash";
import SearchModal from "../SearchModal";
import TrackOrderModal from "../TrackOrderModal";

const fetchCategoriesData = async ({
    setProductTypeSearchResults,
}) => {
    try {
        const response = await search("", null);
        if (response.success) {
            const productTypeSearchResults = response.data.productTypeSearchResults;
            productTypeSearchResults.map(type => {
                type.productTypes.unshift("View all")
            });

            setProductTypeSearchResults(productTypeSearchResults);
        }

    }
    catch (error) {
        console.error(error);
    }
}

const modal_name = {
    savedVehicle: "savedVehicle",
    savedVehicles: "savedVehicles",
    chooseVehicle: "chooseVehicle",
    search: "search",
    trackOrder: "trackOrder",
}

const SiteSubheader = ({currentUser}) => {
    const [openModal, setOpenModal] = useState('');
    const [currentPage, setCurrentPage] = useState(null);
    const [currentVehicle, setCurrentVehicle] = useState(null);
    const [currentVehicleName, setCurrentVehicleName] = useState("");
    const [productTypeSearchResults, setProductTypeSearchResults] = React.useState([]);


    const location = useLocation();
    const navigate = useNavigate();

    const pagesToExclude = ["login", "register", "account-verification", "forgot-password"];

    useEffect(() => {
        const fetchCategoriesDataAsync = async () => {
            const callGetCategoriesOptions = {
                setProductTypeSearchResults,
            }
            await fetchCategoriesData(callGetCategoriesOptions);
        }

        fetchCategoriesDataAsync();
    }, [])

    useEffect(() => {
        const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);

        if (!!vehicleString) {
            const vehicle = JSON.parse(vehicleString);
            setCurrentVehicle(vehicle)
        }
    }, [window.localStorage]);

    useEffect(() => {
        if (!!currentVehicle) {
            let name = "";
            name += currentVehicle.make ? `${currentVehicle.make} ` : "";
            name += currentVehicle.model ? `${currentVehicle.model} ` : "";
            name += currentVehicle.variant ? `${currentVehicle.variant} ` : "";
            name += currentVehicle.year ? `${currentVehicle.year} ` : "";

            setCurrentVehicleName(name);
        }
    }, [currentVehicle]);

    useEffect(() => {
        const pathName = location.pathname;
        const pageName = pathName.split("/").pop();
        setCurrentPage(pageName);
    }, [location]);

    const handleAddVehicleClick = (e) => {
        e.preventDefault();
        openChooseVehicleModal();
    };

    const handleSavedVehicleClick = (e) => {
        e.preventDefault();
        openSavedVehicleModal();
    };

    const handleTrackOrderClick = (e) => {
        e.preventDefault();
        openTrackOrderModal();
    };

    const openSavedVehiclesModal = () => {
        setOpenModal(modal_name.savedVehicles)
    }

    const openSavedVehicleModal = () => {
        setOpenModal(modal_name.savedVehicle)
    }

    const openChooseVehicleModal = () => {
        setOpenModal(modal_name.chooseVehicle)
    }

    const openSearchModal = () => {
        setOpenModal(modal_name.search)
    }

    const openTrackOrderModal = () => {
        setOpenModal(modal_name.trackOrder)
    }

    const closeModals = () => {
        setOpenModal('');
        navigate(0);
    }

    return (
        <>
            {!pagesToExclude.includes(currentPage) &&
                <div className="subheader-container">
                    <Grid padded>
                        <Grid.Row verticalAlign="middle">
                            <Grid.Column width={16}>
                                <Menu className="argus-menu" borderless fluid size="large">
                                    <Menu.Menu position="left">
                                        <Dropdown className="color-white" icon="bars" text="Menu" item fluid>
                                            <Dropdown.Menu fluid>
                                                {!!productTypeSearchResults && productTypeSearchResults.map((category, index) => {
                                                    let categoryName = capitalize(category.category.replaceAll("_", " ").trim());

                                                    return(
                                                        <Dropdown key={index} text={categoryName} item fluid>
                                                            <Dropdown.Menu fluid>
                                                                {category.productTypes.map((type, typeIndex) => {
                                                                    let typeName = "";
                                                                    let typeSlug = "";
                                                                    if (lowerCase(type) === "view all") {
                                                                        const categorySlug = lowerCase(category.category).replaceAll(" ", "_").trim();
                                                                        typeName = upperCase(type);
                                                                        typeSlug = encodeURI(`/products?product_category=${categorySlug}`)
                                                                    }
                                                                    else {
                                                                        typeName = capitalize(type.replaceAll("_", " ").trim());
                                                                        typeSlug = encodeURI(`/products?product_type=${type}`);
                                                                    }

                                                                    return (
                                                                        <Dropdown.Item key={typeIndex} as={Link} to={typeSlug}>
                                                                            {typeName}
                                                                        </Dropdown.Item>
                                                                    )
                                                                })}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Menu.Menu>
                                    <Menu.Menu position='right'>
                                        <Menu.Item as={Link}
                                                   to="/catalogues">
                                            <span className="color-white">Catalogues</span>
                                        </Menu.Item>
                                        <Menu.Item as={Link}
                                                   to="#"
                                                   onClick={handleTrackOrderClick}>
                                            <span className="color-white">Track my order</span>
                                        </Menu.Item>
                                        {!currentVehicleName &&
                                            <>
                                                <Menu.Item
                                                    as={Link}
                                                    to="#"
                                                    onClick={handleAddVehicleClick}
                                                >
                                                    <span className="color-white">Add vehicle</span>
                                                    <Icon name="angle right" className="color-white"/>
                                                </Menu.Item>
                                            </>
                                        }
                                        {!!currentVehicleName &&
                                            <>
                                                <Menu.Item
                                                    as={Link}
                                                    to="#"
                                                    onClick={handleSavedVehicleClick}
                                                >
                                                    <Icon name="car" className="color-white"/>
                                                    <span className="color-white">{currentVehicleName}</span>
                                                    <Icon name="angle right" className="color-white" />
                                                </Menu.Item>
                                            </>
                                        }
                                    </Menu.Menu>
                                </Menu>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            }

            {openModal === modal_name.savedVehicle &&
                <SavedVehicleModal open={openModal === modal_name.savedVehicle}
                                   setOpen={openSavedVehicleModal}
                                   setClose={closeModals}
                                   openSavedVehiclesModal={openSavedVehiclesModal}
                />
            }
            {openModal === modal_name.savedVehicles &&
                <SavedVehiclesModal open={openModal === modal_name.savedVehicles}
                                    setOpen={openSavedVehiclesModal}
                                    setClose={closeModals}
                                    openChooseVehicleModal={openChooseVehicleModal}
                />
            }
            {openModal === modal_name.chooseVehicle &&
                <ChooseVehicleModal
                    open={openModal === modal_name.chooseVehicle}
                    setOpen={openChooseVehicleModal}
                    setClose={closeModals}
                />
            }
            {openModal === modal_name.search &&
                <SearchModal
                    open={openModal === modal_name.search}
                    setOpen={openSearchModal}
                    setClose={closeModals}
                />
            }
            {openModal === modal_name.trackOrder &&
                <TrackOrderModal
                    open={openModal === modal_name.trackOrder}
                    setOpen={openTrackOrderModal}
                    setClose={closeModals}
                />
            }

        </>
    );
};

export default SiteSubheader;
