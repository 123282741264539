import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountType } from "../../utils/constants";
import { Button, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { ErrorMessage } from "@hookform/error-message";
import { cloneDeep, round } from "lodash";
import { SouthAfricanRand } from "../../utils/helpers";

const UIState = {
    NotLoaded: Symbol(1000),
    DataFetchStarted: Symbol(1001),
    DataFetchComplete: Symbol(1002),
    DataFetchFailed: Symbol(1003),
    DataCreateStarted: Symbol(1004),
    DataCreateComplete: Symbol(1005),
    DataCreateFailed: Symbol(1006),
}

const CheckoutOrderInformation = ({
    currentUser,
    salesPeople,
    accountType,
    tradeAccount,
    retailAccount,
    checkoutData,
    cartItems,
    vat,
    subtotal,
    vatAmount,
    total,
    onSubmit
}) => {
    const [salesPersonOptions, setSalesPersonOptions] = useState([]);
    const [formDropdownOptionsChosen, setFormDropdownOptionsChosen] = useState({
        salesPerson: null
    });
    const schema = yup.object({
        salesPerson: yup.string(),
        email: yup.string().email().required("Email is required"),
        phone: yup.string().required("Mobile number is required to register")
            .matches(/^(27|0)[0-9]{9}/, "Must be a valid mobile number")
    }).required();
    const {
        control,
        handleSubmit,
        trigger,
        getValues,
        setValue,
        formState: {errors},
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            salesPerson: "155",
            email: "",
            phone: ""
        }
    });

    useEffect(() => {
        if (!!salesPeople) {
            const options = salesPeople.map(x => {
                return {
                    key: String(x.code.trim()),
                    text: x.name.trim(),
                    value: String(x.code.trim())
                }
            });
            setSalesPersonOptions(options);
        }
    }, [salesPeople]);

    useEffect(() => {
        if (tradeAccount) {
            setValue("phone", currentUser.phoneNumber)
            setValue("email", currentUser.email)
            setValue("firstName", currentUser.firstName);
            setValue("lastName", currentUser.lastName);

            if (salesPersonOptions.length > 0) {
                setValue("salesPerson", String(tradeAccount.salesman));
                const optionsChosenCopy = cloneDeep(formDropdownOptionsChosen);
                optionsChosenCopy["salesPerson"] = String(tradeAccount.salesman);
                setFormDropdownOptionsChosen(optionsChosenCopy);
            }
        }
    }, [tradeAccount]);

    useEffect(() => {
        if (retailAccount) {
            setValue("phone", currentUser.phoneNumber);
            setValue("email", currentUser.email);
            setValue("firstName", currentUser.firstName);
            setValue("lastName", currentUser.lastName);
        }
    }, [retailAccount]);

    const onSaveOrderInformationClick = async () => {
        const result = await trigger([
            "phone",
            "email"
        ]);

        if (!!result && checkoutData) {
            const checkoutDataClone = cloneDeep(checkoutData);

            checkoutDataClone.salesPersonCode = getValues("salesPerson") ?? "155";

            const onSubmitOptions = {
                checkoutData: checkoutDataClone
            }

            await onSubmit(onSubmitOptions);
        }
    }

    const onOptionChange = (_, {name, value}) => {
        const optionsChosenCopy = cloneDeep(formDropdownOptionsChosen);
        optionsChosenCopy[name] = value;
        setValue(name, value);
        clearErrors(name)
        setFormDropdownOptionsChosen(optionsChosenCopy);
    }

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Checkout</div>
            </div>
            {!!checkoutData &&
                <Grid container>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Segment padded>
                                <Grid container>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Form onSubmit={handleSubmit(onSubmit)} size="small">
                                                {accountType === AccountType.Trade && !!tradeAccount &&
                                                    <Grid container>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Header as="h4">
                                                                    <Icon name="info circle" />
                                                                    Account
                                                                </Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <Form.Input
                                                                    icon="user"
                                                                    iconPosition="left"
                                                                    fluid
                                                                    value={`${tradeAccount.name} - ${tradeAccount.accountNumber}`}
                                                                    name="currentAccount"
                                                                    label="Account"
                                                                    disabled
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <Controller
                                                                    name="salesPerson"
                                                                    control={control}
                                                                    render={({field: {onChange, value}}) => (
                                                                        <Form.Select
                                                                            name="salesPerson"
                                                                            fluid
                                                                            error={!!errors?.salesPerson}
                                                                            value={formDropdownOptionsChosen.salesPerson}
                                                                            label='Sales Person'
                                                                            options={salesPersonOptions}
                                                                            placeholder='Sales Person'
                                                                            search
                                                                            selection
                                                                            onChange={onOptionChange}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                }
                                                <Grid container>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Header as="h4">
                                                                <Icon name="address card outline" />
                                                                Contact information
                                                            </Header>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Controller
                                                                name="email"
                                                                control={control}
                                                                render={({field: {onChange, value}}) => (
                                                                    <Form.Input
                                                                        icon="mail"
                                                                        iconPosition="left"
                                                                        fluid
                                                                        label="Email"
                                                                        type="email"
                                                                        onChange={onChange}
                                                                        disabled={accountType === AccountType.Trade || accountType === AccountType.Retail}
                                                                        value={value}
                                                                        placeholder="Email"/>
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="email"
                                                                render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Controller
                                                                name="phone"
                                                                control={control}
                                                                render={({field: {onChange, value}}) => (
                                                                    <Form.Input
                                                                        icon="phone"
                                                                        iconPosition="left"
                                                                        fluid
                                                                        value={value}
                                                                        disabled={accountType === AccountType.Trade || accountType === AccountType.Retail}
                                                                        label='Phone'
                                                                        placeholder='Phone'
                                                                        type="text"
                                                                        onChange={onChange}
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="phone"
                                                                render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Form>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="right">
                                            <Button className="blue-button"
                                                    onClick={onSaveOrderInformationClick}>
                                                <Icon name="save" />
                                                Save
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Segment padded>
                                <Grid container>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Header as="h6">
                                                <Icon name="box" />
                                                Order summary
                                            </Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <p className="b3">Subtotal</p>
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right">
                                            {!!subtotal && <p className="b3">{SouthAfricanRand.format(round(subtotal, 2))}</p>}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <p className="b3">VAT</p>
                                            <sub>(VAT: {vat}%)</sub>
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right">
                                            {!!vatAmount && <p className="b3">{SouthAfricanRand.format(round(vatAmount, 2))}</p>}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <p className="b3">Total</p>
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right">
                                            {!!total && <p className="b3">{SouthAfricanRand.format(round(total, 2))}</p>}
                                        </Grid.Column>
                                    </Grid.Row>
                              </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </div>
    )
}

export default CheckoutOrderInformation;