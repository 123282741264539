import React, {Fragment, useEffect, useState} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import PaymentConfirmation from "../../components/PaymentConfirmation";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getCurrentUser} from "../../api/authenticationController";
import {
    ApiCallStatus,
    LoggedInStatus
} from "../../utils/constants";
import { getPaymentData } from "../../api/paymentController";
import { getOrderData } from "../../api/orderController";

const checkIsLoggedIn = async ({
   setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e){
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const fetchPaymentData = async ({
    paymentId,
    setPaymentData,
    setFetchPaymentDataCallStatus
}) => {
    setFetchPaymentDataCallStatus(ApiCallStatus.InProgress);
    try{
        let response = await getPaymentData(paymentId);
        if (response?.success) {
            setPaymentData(response?.data);
            setFetchPaymentDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setFetchPaymentDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error){
        console.error(error);
        setFetchPaymentDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchOrderData = async ({
    orderId,
    setOrderData,
    setFetchOrderDataCallStatus
}) => {
    setFetchOrderDataCallStatus(ApiCallStatus.InProgress);
    try{
        let response = await getOrderData(orderId);
        if (response?.success) {
            setOrderData(response?.data);
            setFetchOrderDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setFetchOrderDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error){
        console.error(error);
        setFetchOrderDataCallStatus(ApiCallStatus.Error);
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};

const PaymentConfirmationPage = () => {
    const { paymentId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchPaymentDataCallStatus, setFetchPaymentDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchOrderDataCallStatus, setFetchOrderDataCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoLoginPage = () => {
        return navigate('/login', { state: { from: location } });
    };

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchPaymentDataAsync = async () => {
        await fetchPaymentData({
            paymentId,
            setPaymentData,
            setFetchPaymentDataCallStatus
        });
    }

     const fetchOrderDataAsync = async (orderId) => {
        await fetchOrderData({
            orderId,
            setOrderData,
            setFetchOrderDataCallStatus
        });
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes){
            fetchCurrentUserAsync();
            fetchPaymentDataAsync();
        }
        else if (loggedIn === LoggedInStatus.Refreshed) {
            navigate(0);
        }
        else if (loggedIn === LoggedInStatus.No){
            clearCookiesAsync();
            gotoLoginPage();
        }
    }, [loggedIn])

    useEffect(() => {
        if (!!paymentData?.orderId) {
            fetchOrderDataAsync(paymentData.orderId)
        }
    }, [paymentData?.orderId]);

    const allLoadingStates = [
        currentUserCallStatus,
        fetchPaymentDataCallStatus,
        fetchOrderDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser} />
            <PaymentConfirmation paymentData={paymentData}
                                 orderData={orderData}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default PaymentConfirmationPage;
