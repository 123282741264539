import React, {Fragment, useEffect, useState} from "react";
import Payment from "../../components/Payment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getCurrentUser} from "../../api/authenticationController";
import {getPaymentUserData, postPayment} from "../../api/paymentController";
import {AccountType, ApiCallStatus, LoggedInStatus, PaymentStatus, PaymentType} from "../../utils/constants";
import { getUserCheckoutData } from "../../api/checkoutController";
import {getFullTradeAccount} from "../../api/tradeAccountController";
import {CreateUUID} from "../../utils/helpers";
import {getOrderPaymentData} from "../../api/orderController";
import Cookies from "js-cookie";
import {storageKeys} from "../../api/storageKeys";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try{
        const response = await isLoggedIn();
        setLoggedIn(response);
    }
    catch (e){
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const fetchFullTradeAccountData = async ({
    tradeAccountId,
    setFullTradeAccount,
    setFullTradeAccountCallStatus
}) => {
    setFullTradeAccountCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getFullTradeAccount(tradeAccountId);
        if (response?.success) {
            setFullTradeAccount(response.data);
            setFullTradeAccountCallStatus(ApiCallStatus.Succeeded);
        } else {
            setFullTradeAccountCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setFullTradeAccountCallStatus(ApiCallStatus.Error);
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};


const fetchPaymentOrderData = async ({
    orderId,
    setPaymentOrderInfo,
    setPaymentOrderInfoCallStatus
}) => {
    setPaymentOrderInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getOrderPaymentData(orderId);
        if (response?.success) {
            setPaymentOrderInfo(response.data);
            setPaymentOrderInfoCallStatus(ApiCallStatus.Succeeded);
        } else {
            setPaymentOrderInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setPaymentOrderInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchPaymentUserData = async ({
    setPaymentUserInfo,
    setPaymentUserInfoCallStatus
}) => {
    setPaymentUserInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getPaymentUserData();
        if (response?.success) {
            setPaymentUserInfo(response.data);
            setPaymentUserInfoCallStatus(ApiCallStatus.Succeeded);
        } else {
            setPaymentUserInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setPaymentUserInfoCallStatus(ApiCallStatus.Error);
    }
}


const PaymentPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { orderId } = useParams();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [paymentOrderInfo, setPaymentOrderInfo] = useState(null);
    const [paymentUserInfo, setPaymentUserInfo] = useState(null);
    const [accountType, setAccountType] = useState(AccountType.Guest);
    const [tradeAccount, setTradeAccount] = useState(null);
    const [fullTradeAccount, setFullTradeAccount] = useState(null);
    const [retailAccount, setRetailAccount] = useState(null);
    const [logisticsMethod, setLogisticsMethod] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [paymentOrderInfoCallStatus, setPaymentOrderInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [paymentUserInfoCallStatus, setPaymentUserInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fullTradeAccountCallStatus, setFullTradeAccountCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoLoginPage = () => {
        return navigate('/login', { state: { from: location } });
    };

    const gotoPaymentPayfastPage = () => navigate(`/payment/payfast/${orderId}`);
    const gotoPaymentAccountCredit = () => navigate(`/payment/account-credit/${orderId}`);
    const gotoPaymentEftPage = () => navigate(`/payment/eft/${orderId}`);
    const gotoPaymentCreditCardOnCollectionPage = () => navigate(`/payment/credit-card-on-collection/${orderId}`);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchPaymentOrderDataAsync = async () => {
        const storedOrderId = Cookies.get(storageKeys.ORDER_ID);
        if (storedOrderId === orderId) {
            await fetchPaymentOrderData({
                orderId,
                setPaymentOrderInfo,
                setPaymentOrderInfoCallStatus
            });
        }
    }

    const fetchPaymentUserDataAsync = async () => {
        await fetchPaymentUserData({
            setPaymentUserInfo,
            setPaymentUserInfoCallStatus
        });
    }

    const fetchFullTradeAccountDataAsync = async () => {
        if (tradeAccount?.tradeAccountId) {
            await fetchFullTradeAccountData({
                tradeAccountId: tradeAccount.tradeAccountId,
                setFullTradeAccount,
                setFullTradeAccountCallStatus
            });
        }
    }

    const authorizedPageLoad = async () => {
        await fetchCurrentUserAsync();
        await fetchPaymentUserDataAsync();
    }

    const unAuthorizedPageLoad = async () => {
        await fetchPaymentOrderDataAsync();
    }


    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes){
            authorizedPageLoad();
            unAuthorizedPageLoad();
        }
        else if (loggedIn === LoggedInStatus.Refreshed){
            return navigate(0);
        }
        else if (loggedIn === LoggedInStatus.No){
            clearCookiesAsync();
            gotoLoginPage();
        }
        // unAuthorizedPageLoad();
    }, [loggedIn])

    useEffect(() => {
        if (!!paymentUserInfo) {
            if (paymentUserInfo.currentTradeAccount) {
                setAccountType(AccountType.Trade);
            } else {
                setAccountType(AccountType.Retail);
            }
        }
    }, [paymentUserInfo]);

    useEffect(() => {
        if (!!paymentOrderInfo) {
            if (paymentOrderInfo.logisticsMethod) {
                setLogisticsMethod(paymentOrderInfo.logisticsMethod);
            }
        }
    }, [paymentOrderInfo]);

    useEffect(() => {
        if (accountType === AccountType.Trade) {
            setTradeAccount(paymentUserInfo.currentTradeAccount);
        } else if (accountType === AccountType.Retail) {
            setRetailAccount(paymentUserInfo)
        }
    }, [accountType]);

    useEffect(() => {
        if (!!tradeAccount) {
            fetchFullTradeAccountDataAsync();
        }
    }, [tradeAccount]);

    const allLoadingStates = [
        currentUserCallStatus,
        paymentOrderInfoCallStatus,
        paymentUserInfoCallStatus,
        fullTradeAccountCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser} />
            <Payment currentUser={currentUser}
                     paymentOrderInfo={paymentOrderInfo}
                     paymentUserInfo={paymentUserInfo}
                     tradeAccount={tradeAccount}
                     fullTradeAccount={fullTradeAccount}
                     retailAccount={retailAccount}
                     logisticsMethod={logisticsMethod}
                     gotoPaymentPayfastPage={gotoPaymentPayfastPage}
                     gotoPaymentAccountCredit={gotoPaymentAccountCredit}
                     gotoPaymentEftPage={gotoPaymentEftPage}
                     gotoPaymentCreditCardOnCollectionPage={gotoPaymentCreditCardOnCollectionPage}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default PaymentPage;
