import Cookies from "js-cookie";
import { storageKeys } from "./storageKeys";
import axios from "axios";

const url = process.env.REACT_APP_WEB_API_URL;

export const getHomePageContent = async () => {
	try {
		const token = Cookies.get(storageKeys.ACCESS_TOKEN);
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/pageContent/`
		});

		if (!!token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		}

		const websiteId = process.env.REACT_APP_WEBSITE_ID;

		const requestUrl = `${websiteId}/home`;

		return await instance.get(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	} catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const editAnnouncement = async (websiteId, announcementId, announcementData) => {
	try {
		const token = Cookies.get(storageKeys.ACCESS_TOKEN);
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/announcement/`
		});

		if (!!token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		}

		const requestUrl = `${websiteId}/${announcementId}`

		return await instance.put(requestUrl, announcementData)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	} catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getAnnouncementsOverview = async ({pageSize, pageNumber}) => {
	try {
		const token = Cookies.get(storageKeys.ACCESS_TOKEN);
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/announcement/`
		});
		instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const websiteId = process.env.REACT_APP_PUBLIC_WEBSITE_ID;
		const skip = !!pageNumber ? Number(pageNumber) - 1 : 0;
		const params = {
			limit: pageSize,
			skip,
			websiteId
		}

		const requestUrl = "";

		return await instance.get(requestUrl, { params: params })
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getAnnouncement = async (announcementId) => {
	try {
		const token = Cookies.get(storageKeys.ACCESS_TOKEN);
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/announcement/`
		});

		if (!!token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		}

		const requestUrl = `${process.env.REACT_APP_PUBLIC_WEBSITE_ID}/${announcementId}`;

		return await instance.get(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	} catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
}

export const activateAnnouncement = async (websiteId, announcementId) => {
	try {
		const token = Cookies.get(storageKeys.ACCESS_TOKEN);
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/announcement/`
		});

		if (!!token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		}

		const requestUrl = `activate/${websiteId}/${announcementId}`

		return await instance.put(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	} catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const deactivateAnnouncement = async (websiteId, announcementId) => {
	try {
		const token = Cookies.get(storageKeys.ACCESS_TOKEN);
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/announcement/`
		});

		if (!!token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		}

		const requestUrl = `deactivate/${websiteId}/${announcementId}`

		return await instance.put(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	} catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};