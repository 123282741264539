import React, {Fragment, useState} from "react";
import Register from "../../components/Register";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";

const RegisterPage = () => {
    const [loading, setLoading] = useState(false);

    return (
        <Fragment>
            <SiteHeader />
            <Register setLoading={setLoading} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default RegisterPage;
