import axios from "axios";
import {storageKeys} from "./storageKeys";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_ORDER_API_URL;

export const getPayfastRequestByMerchantPaymentId = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/payfast/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `request-by-order-id/${orderId}`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const getPayfastResponse = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/payfast/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `response/${orderId}`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const postPayfastResponse = async (payfastResponse) => {
    try{
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/payfast/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `response`
        return await instance.post(requestUrl, payfastResponse)
                                .then((response) => {
                                    if (response.status === 204) return {
                                        error: false,
                                        success: true
                                    };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}
