import {createAxios} from "./axios";


const url = `https://argus-product-filtering-app.azurewebsites.net/api`;

const accessoryFilterApi = () => createAxios(`${url}/accessoryFilter`);

export const getAccessories = async ({
    pageSize,
    pageNumber,
    productCategory,
    productType,
    vendors
}) => {
    try {
        let requestUrl = `?`;
        requestUrl += `pfa_limit=${pageSize ? pageSize : "24"}`;
        requestUrl += `&pfa_skip=${pageNumber ? pageNumber - 1 : "0"}`;
        requestUrl += productCategory !== "" ? `&pfa_product_category=${encodeURIComponent(productCategory)}` : "";
        requestUrl += productType !== "" ? `&pfa_product_type=${encodeURIComponent(productType)}` : "";
        requestUrl += vendors !== "" ? `&pfa_vendors=${encodeURIComponent(vendors)}` : "";
        const response = await accessoryFilterApi().get(requestUrl);
        return response? response.data : [];
    } catch (error) {
        console.error(error)
    }
};