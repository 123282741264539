import React, {useState} from "react";
import "./style.css";
import {Box, Tab, Tabs} from "@mui/material";
import TabPanel from "../TabPanel/TabPanel";
import MakeModelYear from "../MakeModelYear";
import PartNumberSearch from "../PartNumberSearch";
import RegistrationVinSearch from "../RegistrationVinSearch";
import GeneralSearch from "../GeneralSearch";
import { Container, Grid, Header } from "semantic-ui-react";

const SearchForParts = () => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Grid padded>
            <Grid.Row>
                <Grid.Column width={16}>
                    <div className="pt-5 backgroundGrey26">
                        <Grid container>
                            <Grid.Row textAlign="center">
                                <Grid.Column width={16}>
                                    <Header as="h1" className="color-white">Search for your parts</Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Box sx={{ backgroundColor: '#262626', color: '#FFFFFF' }}>
                                        <Tabs className="textWhite" value={tabValue} onChange={handleTabChange} centered variant="fullWidth">
                                            <Tab sx={{ textTransform: "none", fontSize: "12px", fontFamily: '"Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif'}} label="Make Model Year" />
                                            <Tab sx={{ textTransform: "none", fontSize: "12px", fontFamily: '"Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif'}} label="Part Number" />
                                            {/*<Tab sx={{ textTransform: "none", fontSize: "12px", fontFamily: '"Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif'}} label="Registration/VIN Number" />*/}
                                            {/*<Tab sx={{ textTransform: "none", fontSize: "12px", fontFamily: '"Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif'}} label="General" />*/}
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={tabValue} index={0}>
                                        <MakeModelYear />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                        <PartNumberSearch />
                                    </TabPanel>
                                    {/*<TabPanel value={tabValue} index={2}>*/}
                                    {/*    <RegistrationVinSearch />*/}
                                    {/*</TabPanel>*/}
                                    {/*<TabPanel value={tabValue} index={2}>*/}
                                    {/*    <GeneralSearch />*/}
                                    {/*</TabPanel>*/}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>

    );
};

export default SearchForParts;