import React from "react";
import { Button, Container, Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {ApiCallStatus} from "../../utils/constants";

const AccountVerification = ({
	verificationResult
}) => {
	return (
		<Container className="argus-full-page-container login-background" fluid>
			<Grid centered padded stretched verticalAlign="middle">
				<Grid.Row>
					<Grid.Column width={16}>
						<Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="medium" centered as={Link} to="/"/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={4}/>
					<Grid.Column width={8}>
						<Segment padded>
							{(verificationResult === ApiCallStatus.NotStarted ||
								verificationResult === ApiCallStatus.InProgress ) &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<Header as="h2">Verifying email</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Please wait while we verify your email...
											</p>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
							{verificationResult === ApiCallStatus.Succeeded &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16}>
											<Icon className="center-element"
												  name="check circle outline"
												  color="green"
												  size="big"/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<Header as="h2">Account verification successful</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Your email address was successfully verified.
											</p>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<Button as={Link}
													to={"/login"}
													className="blue-button">
												Login
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
							{(verificationResult === ApiCallStatus.Error ||
								verificationResult === ApiCallStatus.Failed) &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16}>
											<Icon className="center-element"
												  name="times circle outline"
												  color="red"
												  size="big"/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<Header as="h2">Account verification failed</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Your email address could not be verified.
												Please try again later or contact support.
											</p>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
						</Segment>
					</Grid.Column>
					<Grid.Column width={4}/>
				</Grid.Row>
			</Grid>
		</Container>
	)
}

export default AccountVerification;