import React, {useEffect, useState} from "react";
import { Button, Grid, Icon, Segment, Message, Header } from "semantic-ui-react";
import { DeliveryMethod, LogisticsMethod } from "../../utils/constants";
import { cloneDeep, round } from "lodash";
import { SouthAfricanRand } from "../../utils/helpers";
import { useCart } from "react-use-cart";

const CheckoutDeliveryMethod = ({
    checkoutData,
    cartItems,
    vat,
    subtotal,
    vatAmount,
    total,
    gotoCheckoutShippingAddressPage,
    gotoCheckoutCollectionAddressPage,
    onSubmit,
}) => {
    const {cartTotal} = useCart();
    const [chosenLogisticsMethod, setChosenLogisticsMethod] = useState(checkoutData?.logisticsMethod ?? null);
    const [showError, setShowError] = useState(false);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    const handleDeliveryMethodClick = async (deliveryMethod) => {
        let logisticsMethod;
        if (deliveryMethod === DeliveryMethod.Ship) {
            logisticsMethod = LogisticsMethod.Ship;
        }
        else if (deliveryMethod === DeliveryMethod.Collection) {
            logisticsMethod = LogisticsMethod.Collection;
        }

        setShowError(false);
        setChosenLogisticsMethod(logisticsMethod);
    };

    const onSaveClick = async () => {
        if (!!chosenLogisticsMethod) {
            const checkoutDataClone = cloneDeep(checkoutData);
            checkoutDataClone.logisticsMethod = chosenLogisticsMethod;
            checkoutDataClone.shippingAddressId = null;
            checkoutDataClone.collectionAddressId = null;

            const onSubmitOptions = {
                checkoutData: checkoutDataClone
            }

            await onSubmit(onSubmitOptions)
                .then(() => setChosenLogisticsMethod(null));
        }
    }

    const onProceedClick = () => {
        const savedLogisticsMethod = checkoutData?.logisticsMethod;
        if (!chosenLogisticsMethod && !savedLogisticsMethod) {
            setShowError(true);
            scrollTop();
        }

        if (!!chosenLogisticsMethod) {
            if (chosenLogisticsMethod === LogisticsMethod.Collection) {
                gotoCheckoutCollectionAddressPage();
            }
            else if (chosenLogisticsMethod === LogisticsMethod.Ship) {
                gotoCheckoutShippingAddressPage();
            }
        }
        else if (!!savedLogisticsMethod) {
            if (savedLogisticsMethod === LogisticsMethod.Collection) {
                gotoCheckoutCollectionAddressPage();
            }
            else if (savedLogisticsMethod === LogisticsMethod.Ship) {
                gotoCheckoutShippingAddressPage();
            }
        }
    }

    let showAsCurrent = null;
    if (!!chosenLogisticsMethod) {
        showAsCurrent = chosenLogisticsMethod;
    }
    else if (!!checkoutData?.logisticsMethod) {
        showAsCurrent = checkoutData.logisticsMethod;
    }

    const showSaveButton = !!chosenLogisticsMethod;

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Checkout</div>
            </div>
            {!!checkoutData &&
                <Grid container>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Grid container>
                                {!!showError &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Message negative>
                                                <Message.Header>Something went wrong</Message.Header>
                                                <p>Please choose a delivery method</p>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Segment padded>
                                            <Grid container divided="vertically">
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Grid container>
                                                            <Grid.Row>
                                                                <Grid.Column width={14}>
                                                                    <Header as="h4">
                                                                        <Icon name="truck" />
                                                                        Delivery
                                                                    </Header>
                                                                </Grid.Column>
                                                                <Grid.Column width={2}>
                                                                    {showAsCurrent !== LogisticsMethod.Ship &&
                                                                        <Button type="button"
                                                                                compact
                                                                                size="tiny"
                                                                                className="blue-basic-button"
                                                                                floated='right'
                                                                                onClick={() => handleDeliveryMethodClick(DeliveryMethod.Ship)}>
                                                                            Deliver
                                                                        </Button>
                                                                    }
                                                                    {showAsCurrent === LogisticsMethod.Ship &&
                                                                        <Button type="button"
                                                                                compact
                                                                                size="tiny"
                                                                                disabled
                                                                                icon
                                                                                className="green-button"
                                                                                floated='right'>
                                                                            <Icon name="check"/>
                                                                        </Button>
                                                                    }
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <p className="b2">
                                                                        Orders received Monday to Thursday before 9am will be
                                                                        dispatched the same day.
                                                                    </p>
                                                                    <br />
                                                                    <p className="b2">
                                                                        Orders received after 9am on Friday to Sunday will be
                                                                        dispatched on the following Monday.
                                                                    </p>
                                                                    <br />
                                                                    <p className="b2">
                                                                        Delivery typically takes between 2 and 3 working days to
                                                                        deliver to all main centres.
                                                                    </p>
                                                                    <br />
                                                                    <p className="b2">
                                                                        Smaller cities and rural areas may take 5 to 7 working
                                                                        days.
                                                                    </p>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Grid container>
                                                            <Grid.Row>
                                                                <Grid.Column width={14}>
                                                                    <Header as="h4">
                                                                        <Icon name="handshake" />
                                                                        Collection
                                                                    </Header>
                                                                </Grid.Column>
                                                                <Grid.Column width={2}>
                                                                    {showAsCurrent !== LogisticsMethod.Collection &&
                                                                        <Button type="button"
                                                                                size="tiny"
                                                                                compact
                                                                                className="blue-basic-button"
                                                                                floated='right'
                                                                                onClick={() => handleDeliveryMethodClick(DeliveryMethod.Collection)}>
                                                                            Collect
                                                                        </Button>
                                                                    }
                                                                    {showAsCurrent === LogisticsMethod.Collection &&
                                                                        <Button type="button"
                                                                                size="tiny"
                                                                                compact
                                                                                disabled
                                                                                icon
                                                                                className="green-button"
                                                                                floated='right'>
                                                                            <Icon name="check"/>
                                                                        </Button>
                                                                    }
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <p className="b2">
                                                                        Orders are generally ready within 60 minutes from
                                                                        confirmation of payment.
                                                                    </p>
                                                                    <br />
                                                                    <div>
                                                                        <p className="b2"><b>Collection address:</b></p>
                                                                        <p className="b2">28 Great North Road, Brentwood Park, Benoni, 1501</p>
                                                                    </div>
                                                                    <br />
                                                                    <div>
                                                                        <p className="b2"><b>Collection times:</b></p>
                                                                        <p className="b2">Mon-Thu 8:00am-4:30pm</p>
                                                                        <p className="b2">Fri: 8:00am-1:30pm</p>
                                                                    </div>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                {!!showSaveButton &&
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="right">
                                            <Button type="button"
                                                    className="blue-button"
                                                    onClick={onSaveClick}>
                                                <Icon name="save" />
                                                Save
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Grid container>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Segment padded>
                                            <Grid container>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Header as="h6">
                                                            <Icon name="box" />
                                                            Order summary
                                                        </Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <p className="b3">Subtotal</p>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        {!!subtotal && <p className="b3">{SouthAfricanRand.format(round(subtotal, 2))}</p>}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <p className="b3">VAT</p>
                                                        <sub>(VAT: {vat}%)</sub>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        {!!vatAmount && <p className="b3">{SouthAfricanRand.format(round(vatAmount, 2))}</p>}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <p className="b3">Total</p>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        {!!total && <p className="b3">{SouthAfricanRand.format(round(total, 2))}</p>}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {!showSaveButton &&
                                            <Grid.Row>
                                                <Grid.Column width={16} textAlign="right">
                                                    <Button type="button"
                                                            fluid
                                                            className="blue-button"
                                                            onClick={onProceedClick}>
                                                        Continue
                                                        <Icon name="arrow right"/>
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </div>
    )
}

export default CheckoutDeliveryMethod;