import { useNavigate, useSearchParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { isLoggedIn, removeAuthCookies } from "../../api/authentication";
import {ApiCallStatus, LoggedInStatus, PageStatus} from "../../utils/constants";
import { confirmEmail } from "../../api/authenticationController";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import AccountVerification from "../../components/AccountVerification";

const checkIsLoggedIn = async ({
	setLoggedIn
}) => {
	try {
		const response = await isLoggedIn();
		setLoggedIn(response);
	}
	catch (e) {
		console.error(e);
		setLoggedIn(LoggedInStatus.No);
	}
}

const createConfirmEmailData = async ({
	email,
	code,
	setVerificationResultCallStatus
}) => {
	setVerificationResultCallStatus(ApiCallStatus.InProgress)
	try{
		const confirmEmailOptions = {
			email: encodeURI(email),
			code: encodeURI(code)
		}

		const response = await confirmEmail(confirmEmailOptions)

		if (response.success) {
			setVerificationResultCallStatus(ApiCallStatus.Succeeded);
		}
		else {
			setVerificationResultCallStatus(ApiCallStatus.Failed);
		}
	}
	catch (error) {
		console.error(error);
		setVerificationResultCallStatus(ApiCallStatus.Error);
	}
}

const clearCookies = async () => {
	await removeAuthCookies();
};

const AccountVerificationPage = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);

	const [verificationResultCallStatus, setVerificationResultCallStatus] = useState(ApiCallStatus.NotStarted);

	const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

	const clearCookiesAsync = async () => {
        await clearCookies();
    }

	const createConfirmEmailDataAsync = async (code, email) => {
		await createConfirmEmailData({
			email,
			code,
			setVerificationResultCallStatus
		});
	}

	useEffect(() => {
		performLoggedInCheck();
	}, []);

	useEffect(() => {
		if (loggedIn === LoggedInStatus.Yes) {
			navigate("/");
		}
		else if (loggedIn === LoggedInStatus.No) {
			clearCookiesAsync();
			const verificationCodeParam = searchParams.get('code');
			const emailParam = searchParams.get('email');

			if (!verificationCodeParam || !emailParam) {
				return navigate("/error")
			}

			createConfirmEmailDataAsync(verificationCodeParam, emailParam);
		}
	}, [loggedIn]);

	const allLoadingStates = [
		verificationResultCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Fragment>
			<SiteHeader/>
			<AccountVerification verificationResult={verificationResultCallStatus} />
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={isLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
			<SiteFooter/>
		</Fragment>
	);
}

export default AccountVerificationPage;