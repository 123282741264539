import React, {useEffect} from "react";
import { Grid, Header, Segment, Table, Image, Button, Message } from "semantic-ui-react";
import {SouthAfricanRand} from "../../utils/helpers";
import {round} from "lodash";


const PaymentAccountCredit = ({
    paymentOrderInfo,
    fullTradeAccount,
    gotoPaymentPage,
    onSubmit
}) => {
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Payment</div>
            </div>
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment padded>
                            <Grid container>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="small" fluid centered/>
                                    </Grid.Column>
                                </Grid.Row>
                                {!!fullTradeAccount && !!paymentOrderInfo &&
                                    <>
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                <Table celled collapsing>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <Header as="h4">Available Credit</Header>
                                                            </Table.Cell>
                                                            <Table.Cell>{SouthAfricanRand.format(round(fullTradeAccount.availableCredit, 2))}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <Header as="h4">Credit Limit</Header>
                                                            </Table.Cell>
                                                            <Table.Cell>{SouthAfricanRand.format(round(fullTradeAccount.creditLimit, 2))}</Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row textAlign="center">
                                            <Grid.Column width={16}>
                                                {(fullTradeAccount.availableCredit >= paymentOrderInfo.total) &&
                                                    <Button className="blue-button"
                                                            type="button"
                                                            onClick={onSubmit}>
                                                        Pay
                                                    </Button>
                                                }
                                                {(fullTradeAccount.availableCredit < paymentOrderInfo.total) &&
                                                    <>
                                                        <Message warning>
                                                            <Message.Header>Not enough credit available</Message.Header>
                                                            <p>
                                                                Your account does not have enough credit to complete this
                                                                transaction. Please use a different payment method.
                                                            </p>
                                                        </Message>
                                                        <Button className="blue-button"
                                                                type="button"
                                                                onClick={gotoPaymentPage}>
                                                            Choose different payment method
                                                        </Button>

                                                    </>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>


                                    </>
                                }
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )

}

export default PaymentAccountCredit;