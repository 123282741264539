import React, {Fragment, useEffect, useState} from "react";
import SiteHeader from "../../components/SiteHeader";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import PaymentAccountCredit from "../../components/PaymentAccountCredit";
import { AccountType, ApiCallStatus, LoggedInStatus, PaymentMethod, PaymentType } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {storageKeys} from "../../api/storageKeys";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getCurrentUser} from "../../api/authenticationController";
import { getPaymentUserData, postAccountCreditPayment } from "../../api/paymentController";
import {getOrderPaymentData} from "../../api/orderController";
import { getFullTradeAccount } from "../../api/tradeAccountController";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const clearCookies = async () => {
    await removeAuthCookies();
};

const fetchPaymentOrderData = async ({
    orderId,
    setPaymentOrderInfo,
    setPaymentOrderInfoCallStatus
}) => {
    setPaymentOrderInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getOrderPaymentData(orderId);
        if (response?.success) {
            setPaymentOrderInfo(response.data);
            setPaymentOrderInfoCallStatus(ApiCallStatus.Succeeded);
        } else {
            setPaymentOrderInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setPaymentOrderInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchPaymentUserData = async ({
    setPaymentUserInfo,
    setPaymentUserInfoCallStatus
}) => {
    setPaymentUserInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getPaymentUserData();
        if (response?.success) {
            setPaymentUserInfo(response.data);
            setPaymentUserInfoCallStatus(ApiCallStatus.Succeeded);
        } else {
            setPaymentUserInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setPaymentUserInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchFullTradeAccountData = async ({
    tradeAccountId,
    setFullTradeAccount,
    setFullTradeAccountCallStatus
}) => {
    setFullTradeAccountCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getFullTradeAccount(tradeAccountId);
        if (response?.success) {
            setFullTradeAccount(response.data);
            setFullTradeAccountCallStatus(ApiCallStatus.Succeeded);
        } else {
            setFullTradeAccountCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setFullTradeAccountCallStatus(ApiCallStatus.Error);
    }
}

const createAccountCreditPaymentData = async ({
    orderId,
    setPaymentId,
    setCreateAccountCreditPaymentCallStatus
}) => {
    setCreateAccountCreditPaymentCallStatus(ApiCallStatus.InProgress)
    try {
        const response = await postAccountCreditPayment(orderId);
        if (response?.success) {
            setPaymentId(response.data);
            setCreateAccountCreditPaymentCallStatus(ApiCallStatus.Succeeded);
        } else {
            setCreateAccountCreditPaymentCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setCreateAccountCreditPaymentCallStatus(ApiCallStatus.Error);
    }
}

const PaymentAccountCreditPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [paymentOrderInfo, setPaymentOrderInfo] = useState(null);
    const [paymentUserInfo, setPaymentUserInfo] = useState(null);

    const [accountType, setAccountType] = useState(AccountType.Guest);
    const [fullTradeAccount, setFullTradeAccount] = useState(null);
    const [tradeAccount, setTradeAccount] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fullTradeAccountCallStatus, setFullTradeAccountCallStatus] = useState(ApiCallStatus.NotStarted);
    const [paymentOrderInfoCallStatus, setPaymentOrderInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [paymentUserInfoCallStatus, setPaymentUserInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [createAccountCreditPaymentCallStatus, setCreateAccountCreditPaymentCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoLoginPage = () => {
        return navigate('/login', { state: { from: location } });
    };

    const gotoPaymentPage = () => paymentOrderInfo?.orderId ? navigate(`/payment/${paymentOrderInfo.orderId}`) : navigate(-1);
    const gotoPaymentConfirmationPage = () => paymentId ? navigate(`/payment-confirmation/${paymentId}`) : null;

    const reloadPage = () => navigate(0);
    const gotoErrorPage = () => navigate("/error");

    const performLoggedInCheck = async () => {
        const checkIsLoggedInOptions = {
            setLoggedIn
        }
        await checkIsLoggedIn(checkIsLoggedInOptions);
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchPaymentOrderDataAsync = async () => {
        const orderId = Cookies.get(storageKeys.ORDER_ID);
        await fetchPaymentOrderData({
            orderId,
            setPaymentOrderInfo,
            setPaymentOrderInfoCallStatus
        });
    }

    const fetchPaymentUserDataAsync = async () => {
        await fetchPaymentUserData({
            setPaymentUserInfo,
            setPaymentUserInfoCallStatus
        });
    }

    const fetchFullTradeAccountDataAsync = async () => {
        if (tradeAccount?.tradeAccountId) {
            await fetchFullTradeAccountData({
                tradeAccountId: tradeAccount.tradeAccountId,
                setFullTradeAccount,
                setFullTradeAccountCallStatus
            });
        }
    }

    const createAccountCreditPaymentDataAsync = async () => {
        const orderId = Cookies.get(storageKeys.ORDER_ID);
        await createAccountCreditPaymentData({
            orderId,
            setPaymentId,
            setCreateAccountCreditPaymentCallStatus
        });
    }

    const authorizedPageLoad = async () => {
        await fetchCurrentUserAsync();
        await fetchPaymentUserDataAsync();
    }

    const unAuthorizedPageLoad = async () => {
        await fetchPaymentOrderDataAsync();
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
            unAuthorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            gotoLoginPage();
        }

        // unAuthorizedPageLoad();
    }, [loggedIn]);

    useEffect(() => {
        if (!!paymentUserInfo) {
            if (paymentUserInfo.currentTradeAccount) {
                setAccountType(AccountType.Trade);
            } else {
                setAccountType(AccountType.Retail);
            }
        }
    }, [paymentUserInfo]);

    useEffect(() => {
        if (accountType === AccountType.Trade) {
            setTradeAccount(paymentUserInfo.currentTradeAccount);
        }
    }, [accountType]);

    useEffect(() => {
        if (!!paymentId && createAccountCreditPaymentCallStatus === ApiCallStatus.Succeeded) {
            gotoPaymentConfirmationPage();
        }
    }, [createAccountCreditPaymentCallStatus, paymentId]);

    useEffect(() => {
        if (!!tradeAccount) {
            fetchFullTradeAccountDataAsync();
        }
    }, [tradeAccount]);

    const allLoadingStates = [
        currentUserCallStatus,
        paymentOrderInfoCallStatus,
        paymentUserInfoCallStatus,
        createAccountCreditPaymentCallStatus,
        fullTradeAccountCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);


    return (
        <Fragment>
            <SiteHeader currentUser={currentUser}/>
            <PaymentAccountCredit paymentOrderInfo={paymentOrderInfo}
                                  fullTradeAccount={fullTradeAccount}
                                  gotoPaymentPage={gotoPaymentPage}
                                  onSubmit={createAccountCreditPaymentDataAsync}
            />
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <SiteFooter/>
        </Fragment>
    );
}

export default PaymentAccountCreditPage;