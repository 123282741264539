import React, {Fragment, useEffect, useState} from "react";
import Product from "../../components/Product";
import {useNavigate, useParams} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import { getAccountInformation, getCurrentUser } from "../../api/authenticationController";
import { ApiCallStatus, LoggedInStatus, PriceCategory } from "../../utils/constants";
import { find } from "lodash";
import { getPrices, getProductData } from "../../api/productController";
import { calculateTradePriceCategory } from "../../api/account";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    }
    catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const clearCookies = async () => {
    await removeAuthCookies();
};

const fetchProductBySku = async ({
    productSku,
    setProduct,
    setProductFetchCallStatus
}) => {
    setProductFetchCallStatus(ApiCallStatus.InProgress)
    try {
        const productSkus = [
            productSku
        ]
        const response = await getProductData(productSkus);
        if (response.success) {
            if (response.data.length > 0) {
                setProduct(response.data[0]);
                setProductFetchCallStatus(ApiCallStatus.Succeeded);
            }

        }
        else {
            setProductFetchCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setProductFetchCallStatus(ApiCallStatus.Error);
    }
};

const fetchProductPrice = async ({
    productSku,
    level,
    setPrice,
    setPriceFetchCallStatus
}) => {
    setPriceFetchCallStatus(ApiCallStatus.InProgress)
    try {
        const productSkus = [
            productSku
        ];

        const getPricesOptions = {
            productSkus,
            level
        }
        const response = await getPrices(getPricesOptions);

        if (response?.success) {
            const prices = response.data;
            const price = find(prices, x => x.sku === productSku);
            setPrice(price);
            setPriceFetchCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setPriceFetchCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setPriceFetchCallStatus(ApiCallStatus.Error);
    }
};

const fetchAccountInformationData = async ({
    setAccountInformation,
    setAccountInformationCallStatus
}) => {
    setAccountInformationCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getAccountInformation();
        if (response?.success) {
            setAccountInformation(response.data);
            setAccountInformationCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setAccountInformationCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setAccountInformationCallStatus(ApiCallStatus.Error);
    }
}

const ProductPage = () => {
    const navigate = useNavigate();
    let { productSku } = useParams();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [accountInformation, setAccountInformation] = useState(null);
    const [priceCategory, setPriceCategory] = useState(PriceCategory.Retail);
    const [product, setProduct] = useState(null);
    const [price, setPrice] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [accountInformationCallStatus, setAccountInformationCallStatus] = useState(ApiCallStatus.NotStarted)
    const [productFetchCallStatus, setProductFetchCallStatus] = useState(ApiCallStatus.NotStarted);
    const [priceFetchCallStatus, setPriceFetchCallStatus] = useState(ApiCallStatus.NotStarted);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchProductBySkuAsync = async () => {
        await fetchProductBySku({
            productSku,
            setProduct,
            setProductFetchCallStatus
        });
    }

    const fetchProductPriceAsync = async (priceCategory) => {
        await fetchProductPrice({
            productSku,
            level: priceCategory,
            setPrice,
            setPriceFetchCallStatus
        });
    }

    const fetchAccountInformationDataAsync = async () => {
        const fetchUserInfoOptions = {
            setAccountInformation,
            setAccountInformationCallStatus
        }
        await fetchAccountInformationData(fetchUserInfoOptions);
    }

    const unauthorizedCalls = async () => {
        await Promise.all([
            fetchProductBySkuAsync()
        ]);
    }

    const authorizedCalls = async () => {
        await Promise.all([
            fetchCurrentUserAsync(),
            fetchAccountInformationDataAsync()
        ])
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedCalls();
        }
        else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            setAccountInformationCallStatus(ApiCallStatus.Succeeded);
        }

        unauthorizedCalls();
    }, [loggedIn]);

    useEffect(() => {
        if (accountInformationCallStatus === ApiCallStatus.Succeeded) {
            if (!!accountInformation?.currentTradeAccount) {
                const userPriceCategory = calculateTradePriceCategory(accountInformation.currentTradeAccount.category);
                setPriceCategory(userPriceCategory);
                fetchProductPriceAsync(userPriceCategory);
            }
            else {
                fetchProductPriceAsync(PriceCategory.Retail);
            }
        }
    }, [accountInformation, accountInformationCallStatus]);

    const allLoadingStates = [
        currentUserCallStatus,
        productFetchCallStatus,
        priceFetchCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser} />
            <Product product={product} price={price} priceCategory={priceCategory} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default ProductPage;
