import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { Button, Form, Grid, Image, Input, Message } from "semantic-ui-react";
import { isInteger, replace, startsWith } from "lodash";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: 4
};

const form_state = {
    typing: "typing",
    validating: "validating",
    error: "error",
    submitting: "submitting"
}

const TrackOrderModal = ({
                               open,
                               setOpen,
                               setClose
                           }) => {
    const [trackingNumber, setTrackingNumber] = useState("");
    const [formState, setFormState] = useState(form_state.typing);

    const closeModal = () => {
        setClose();
    }

    const goToRam = () => {
        let orderNumber;
        orderNumber = replace(trackingNumber.toLowerCase(), "arg", "");
        window.location.href = `https://www.ram.co.za/Track/ARG${orderNumber}`
    };

    const validateTrackingNumber = () => {
        let orderNumber;
        orderNumber = replace(trackingNumber.toLowerCase(), "arg", "");
        if (isInteger(parseInt(orderNumber))) {
            setFormState(form_state.submitting);
            goToRam()
        }
        else {
            setFormState(form_state.error)
        }
    };

    const handleTrackOrderClick = async () => {
        setFormState(form_state.validating);
        validateTrackingNumber();
    };

    const handleTrackingNumberChange = (e, {value}) => {
        setFormState(form_state.typing);
        setTrackingNumber(value);
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <Box sx={style}>
                <Grid>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16} textAlign="center">
                            <Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="small" fluid centered/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <div className="argus-modal-title">Track your order</div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div className="argus-modal-text">Enter your order tracking number below.
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div className="argus-modal-subtitle">This functionality is currently limited to orders shipped with RAM
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Form onSubmit={handleTrackOrderClick} loading={formState !== form_state.typing && formState !== form_state.error}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form.Input onChange={handleTrackingNumberChange}
                                            size="large"
                                            fluid
                                            value={trackingNumber}
                                            placeholder='Enter your tracking number'/>
                            </Grid.Column>
                        </Grid.Row>
                        {formState === form_state.error &&
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Message negative
                                             content="Enter a valid tracking number"/>
                                </Grid.Column>
                            </Grid.Row>
                        }
                        <Grid.Row>
                            <Grid.Column width={16} textAlign="right">
                                <Button type="submit" className="blue-button">
                                    Track order
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Box>
        </Modal>
    );
};
export default TrackOrderModal;