import React from "react";
import { Button, Container, Form, Grid, Header, Icon, Image, Segment } from "semantic-ui-react";

const ContactUs = () => {

    const handleSendUsAMessageSubmit = () => {

    };

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Contact us</div>
            </div>
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Image src="https://argusweb.azureedge.net/argus-web/contact-us/contact-us-storefront.jpg"
                               size="big"
                               centered/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid container>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h3" className="underline">Our address</Header>
                                    <Grid container>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <a href="https://goo.gl/maps/RbgTFmVshrD2WJYu8"
                                                   target="_blank">
                                                    28 Great North Road, Brentwood Park, 1501, South Africa
                                                </a>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Icon name="phone" />
                                                <a href="tel:0117475200">(+27)11 747 5200</a>

                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Icon name="whatsapp" />
                                                <a href="https://api.whatsapp.com/send?phone=27836545120"
                                                    className="b1">083 645 5120</a>

                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Icon name="at" />
                                                <a href="mailto:info@argusmotoring.co.za">info@argusmotoring.co.za</a>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <p className="b1">
                                                    <strong>Opening Hours</strong>
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <p className="b1">Monday to Thursday: 8am-4.30pm</p>
                                                <p className="b1">Friday: 8am-1.30pm</p>
                                                <p className="b1">Saturday & Sunday: Closed</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>


                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row />*/}
                {/*<Grid.Row>*/}
                {/*    <Grid.Column width={4} />*/}
                {/*    <Grid.Column width={8}>*/}
                {/*        <Segment padded>*/}
                {/*            <Grid container>*/}
                {/*                <Grid.Row>*/}
                {/*                    <Grid.Column width={16} textAlign="center">*/}
                {/*                        <Header as="h3">Send us a message</Header>*/}
                {/*                    </Grid.Column>*/}
                {/*                </Grid.Row>*/}
                {/*                <Grid.Row>*/}
                {/*                    <Grid.Column width={16} textAlign="center">*/}
                {/*                        <Form onSubmit={handleSendUsAMessageSubmit}>*/}
                {/*                            <Form.Field>*/}
                {/*                                <label>Name</label>*/}
                {/*                                <input type="text" placeholder="Your name"/>*/}
                {/*                            </Form.Field>*/}
                {/*                            <Form.Field>*/}
                {/*                                <label>Email</label>*/}
                {/*                                <input type="email" placeholder="Your email"/>*/}
                {/*                            </Form.Field>*/}
                {/*                            <Form.Field>*/}
                {/*                                <label>Subject</label>*/}
                {/*                                <input type="text" placeholder="Subject"/>*/}
                {/*                            </Form.Field>*/}
                {/*                            <Form.Field>*/}
                {/*                                <label>Message</label>*/}
                {/*                                <textarea placeholder="What is on your mind..."/>*/}
                {/*                            </Form.Field>*/}
                {/*                            <Form.Field>*/}
                {/*                                <Button type="submit"*/}
                {/*                                        className="blue-button">*/}
                {/*                                    Send*/}
                {/*                                </Button>*/}
                {/*                            </Form.Field>*/}
                {/*                        </Form>*/}
                {/*                    </Grid.Column>*/}
                {/*                </Grid.Row>*/}
                {/*            </Grid>*/}
                {/*        </Segment>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column width={4}/>*/}
                {/*</Grid.Row>*/}
            </Grid>
        </div>
    );
};
export default ContactUs;
