import React, {useEffect, useState} from "react";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import {AccountType, LogisticsMethod} from "../../utils/constants";
import { find, round } from "lodash";
import { SouthAfricanRand } from "../../utils/helpers";
import { useCart } from "react-use-cart";

const CheckoutSummary = ({
    checkoutData,
    accountType,
    logisticsFee,
    cartItems,
    vat,
    subtotal,
    vatAmount,
    total,
    billingAddresses,
    shippingAddresses,
    collectionAddresses,
    retailAccount,
    salesPeople,
    tradeAccount,
    countries,
    currentUser,
    provinces,
    gotoCheckoutOrderInformation,
    gotoCheckoutBillingAddress,
    gotoCheckoutCollectionAddress,
    gotoCheckoutDeliveryMethod,
    gotoCheckoutShippingAddress,
    onSubmit
}) => {
    const { cartTotal, items} = useCart();
    const [salesPerson, setSalesPerson] = useState(null);
    const [billingAddress, setBillingAddress] = useState(null);
    const [shippingAddress, setShippingAddress] = useState(null);
    const [collectionAddress, setCollectionAddress] = useState(null);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        if (!!billingAddresses && !!checkoutData?.billingAddressId && !!countries && !!provinces) {
            const address = find(billingAddresses, x => x.addressId === checkoutData.billingAddressId);
            if (!!address) {
                const country = find(countries, x => x.countryId === address.countryId);
                const province = find(provinces, x => x.provinceId === address.provinceId);

                address.country = country.name ?? "";
                address.province = province.name ?? "";

                setBillingAddress(address);
            }
        }
    }, [checkoutData?.billingAddressId, billingAddresses, countries, provinces]);

    useEffect(() => {
        if (!!shippingAddresses && !!checkoutData?.shippingAddressId && !!countries && !!provinces) {
            const address = find(shippingAddresses, x => x.addressId === checkoutData.shippingAddressId);
            if (!!address) {
                const country = find(countries, x => x.countryId === address.countryId);
                const province = find(provinces, x => x.provinceId === address.provinceId);

                address.country = country.name ?? "";
                address.province = province.name ?? "";

                setShippingAddress(address);
            }
        }
    }, [checkoutData?.shippingAddressId, shippingAddresses, countries, provinces]);

    useEffect(() => {
        if (!!collectionAddresses && !!checkoutData?.collectionAddressId && !!countries && !!provinces) {
            const address = find(collectionAddresses, x => x.addressId === checkoutData.collectionAddressId);
            if (!!address) {
                const country = find(countries, x => x.countryId === address.countryId);
                const province = find(provinces, x => x.provinceId === address.provinceId);

                address.country = country.name ?? "";
                address.province = province.name ?? "";

                setCollectionAddress(address);
            }
        }
    }, [checkoutData?.collectionAddressId, collectionAddresses, countries, provinces]);

    useEffect(() => {
        if (!!checkoutData?.salesPersonCode && !!salesPeople) {
            const person = find(salesPeople, x => x.code === checkoutData.salesPersonCode);
            setSalesPerson(person);
        }
    }, [checkoutData?.salesPersonCode]);

    const onGoToPaymentClick = async () => {
        await onSubmit(checkoutData);
    };

    const deliveryFeeToDisplay = () => {
        if (!!logisticsFee || logisticsFee === 0) {
            if (logisticsFee > 0) {
                return SouthAfricanRand.format(round(logisticsFee, 2))
            }
            else {
                return "Free";
            }
        }
        else {
            return "TBD"
        }
    }

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Checkout</div>
            </div>
            {!!checkoutData &&
                <Grid container>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Grid container>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Segment padded>
                                            <Grid container>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Header as="h4">
                                                            <Icon name="clipboard list" />
                                                            Order information
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        <Button type="button"
                                                                compact
                                                                className="blue-basic-button borderless"
                                                                size="tiny"
                                                                onClick={gotoCheckoutOrderInformation}>
                                                            Change
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        {accountType === AccountType.Trade && !!tradeAccount &&
                                                            <p className="b1">
                                                                <b>Account: </b>
                                                                {`${tradeAccount.name} - ${tradeAccount.accountNumber}`}
                                                            </p>
                                                        }
                                                        {!!salesPerson &&
                                                            <p className="b1">
                                                                <b>Sales person: </b>
                                                                {salesPerson.name}
                                                            </p>
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Segment padded>
                                            <Grid container>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Header as="h4">
                                                            <Icon name="building" />
                                                            Billing address
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        <Button type="button"
                                                                compact
                                                                className="blue-basic-button borderless"
                                                                size="tiny"
                                                                onClick={gotoCheckoutBillingAddress}>
                                                            Change
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        {!!billingAddress &&
                                                            <>
                                                                <p className="b1">{`${billingAddress.firstName} ${billingAddress.lastName}`}</p>
                                                                <p className="b1">{billingAddress.email}</p>
                                                                <p className="b1">{billingAddress.streetAddress}</p>
                                                                <p className="b1">{billingAddress.suburb}</p>
                                                                <p className="b1">{billingAddress.city}</p>
                                                                <p className="b1">{billingAddress.postalCode}</p>
                                                                <p className="b1">{billingAddress.province}</p>
                                                                <p className="b1">{billingAddress.country}</p>
                                                            </>
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Segment padded>
                                            <Grid container>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Header as="h4">
                                                            <Icon name="dolly" />
                                                            Delivery method
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        <Button type="button"
                                                                compact
                                                                className="blue-basic-button borderless"
                                                                size="tiny"
                                                                onClick={gotoCheckoutDeliveryMethod}>
                                                            Change
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        {checkoutData.logisticsMethod === LogisticsMethod.Ship &&
                                                            <>
                                                                <p className="b1">Method: <strong>Delivery</strong></p>
                                                                <p className="b1">Delivery fee: <b>{deliveryFeeToDisplay()}</b></p>
                                                            </>
                                                        }
                                                        {checkoutData.logisticsMethod === LogisticsMethod.Collection &&
                                                            <p className="b1">Collection</p>
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                {checkoutData.logisticsMethod === LogisticsMethod.Ship &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Segment padded>
                                                <Grid container>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Header as="h4">
                                                                <Icon name="truck" />
                                                                Shipping address
                                                            </Header>
                                                        </Grid.Column>
                                                        <Grid.Column width={8} textAlign="right">
                                                            <Button type="button"
                                                                    compact
                                                                    className="blue-basic-button borderless"
                                                                    size="tiny"
                                                                    onClick={gotoCheckoutShippingAddress}>
                                                                Change
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            {!!shippingAddress &&
                                                                <>
                                                                    <p className="b1">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</p>
                                                                    <p className="b1">{shippingAddress.email}</p>
                                                                    <p className="b1">{shippingAddress.streetAddress}</p>
                                                                    <p className="b1">{shippingAddress.suburb}</p>
                                                                    <p className="b1">{shippingAddress.city}</p>
                                                                    <p className="b1">{shippingAddress.postalCode}</p>
                                                                    <p className="b1">{shippingAddress.province}</p>
                                                                    <p className="b1">{shippingAddress.country}</p>
                                                                </>
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                                {checkoutData.logisticsMethod === LogisticsMethod.Collection &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Segment padded>
                                                <Grid container>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Header as="h4">
                                                                <Icon name="warehouse" />
                                                                Collection address
                                                            </Header>
                                                        </Grid.Column>
                                                        <Grid.Column width={8} textAlign="right">
                                                            <Button type="button"
                                                                    compact
                                                                    className="blue-basic-button borderless"
                                                                    size="tiny"
                                                                    onClick={gotoCheckoutCollectionAddress}>
                                                                Change
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            {!!collectionAddress &&
                                                                <>
                                                                    <p className="b1">{collectionAddress.email}</p>
                                                                    <p className="b1">{collectionAddress.streetAddress}</p>
                                                                    <p className="b1">{collectionAddress.suburb}</p>
                                                                    <p className="b1">{collectionAddress.city}</p>
                                                                    <p className="b1">{collectionAddress.postalCode}</p>
                                                                    <p className="b1">{collectionAddress.province}</p>
                                                                    <p className="b1">{collectionAddress.country}</p>
                                                                </>
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Grid container>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Segment padded>
                                            <Grid container>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Header as="h6">
                                                            <Icon name="box" />
                                                            Order summary
                                                        </Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Header as="h6">Subtotal</Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        <Header as="h5">{SouthAfricanRand.format(round(cartTotal, 2))}</Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Header as="h6">VAT</Header>
                                                        <sub>(VAT: {vat}%)</sub>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        <Header as="h5">{SouthAfricanRand.format(round(vatAmount, 2))}</Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Header as="h6">Delivery fee</Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        <Header as="h5">{deliveryFeeToDisplay()}</Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Header as="h6">Total</Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        <Header as="h5">{SouthAfricanRand.format(round(cartTotal + vatAmount + logisticsFee ?? 0, 2))}</Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign="right">
                                        <Button
                                            className="blue-button"
                                            fluid
                                            onClick={onGoToPaymentClick}>
                                            Go to payment
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </div>
    )
}

export default CheckoutSummary;