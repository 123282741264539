import React, { useContext, useEffect, useState } from 'react';

const defaultCheckoutData = {
    salesPersonCode: '',
    tradeAccountId: '',
    logisticsMethod: '',
    billingAddressId: '',
    shippingAddressId: '',
    collectionAddressId: ''
};

const CheckoutContext = React.createContext();

export const CheckoutProvider = ({ children }) => {
    const [checkoutData, setCheckoutData] = useState(() => {
        const savedData = localStorage.getItem('checkoutData');
        return savedData ? JSON.parse(savedData) : defaultCheckoutData;
    });

    useEffect(() => {
        localStorage.setItem('checkoutData', JSON.stringify(checkoutData));
    }, [checkoutData]);

    const getSalesPersonCode = () => checkoutData.salesPersonCode;
    const setSalesPersonCode = (value) => setCheckoutData(prevData => ({ ...prevData, salesPersonCode: value }));

    const getTradeAccountId = () => checkoutData.tradeAccountId;
    const setTradeAccountId = (value) => setCheckoutData(prevData => ({ ...prevData, tradeAccountId: value }));

    const getLogisticsMethod = () => checkoutData.logisticsMethod;
    const setLogisticsMethod = (value) => setCheckoutData(prevData => ({ ...prevData, logisticsMethod: value }));

    const getBillingAddressId = () => checkoutData.billingAddressId;
    const setBillingAddressId = (value) => setCheckoutData(prevData => ({ ...prevData, billingAddressId: value }));

    const getShippingAddressId = () => checkoutData.shippingAddressId;
    const setShippingAddressId = (value) => setCheckoutData(prevData => ({ ...prevData, shippingAddressId: value }));

    const getCollectionAddressId = () => checkoutData.collectionAddressId;
    const setCollectionAddressId = (value) => setCheckoutData(prevData => ({ ...prevData, collectionAddressId: value }));

    const getCheckoutData = () => checkoutData;

    const resetCheckoutData = () => {
        setCheckoutData(defaultCheckoutData);
    };

    return (
        <CheckoutContext.Provider value={{
            resetCheckoutData, getCheckoutData,
            getSalesPersonCode, setSalesPersonCode,
            getTradeAccountId, setTradeAccountId,
            getLogisticsMethod, setLogisticsMethod,
            getBillingAddressId, setBillingAddressId,
            getShippingAddressId, setShippingAddressId,
            getCollectionAddressId, setCollectionAddressId
        }}>
            {children}
        </CheckoutContext.Provider>
    );
};

export const useCheckout = () => {
    return useContext(CheckoutContext);
};

export default CheckoutContext;