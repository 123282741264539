import React, {Fragment, useEffect, useState} from "react";
import SiteHeader from "../../components/SiteHeader";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import CheckoutOrderInformation from "../../components/CheckoutOrderInformation";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {storageKeys} from "../../api/storageKeys";
import { AccountType, ApiCallStatus, LoggedInStatus, PriceCategory } from "../../utils/constants";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import { getAccountInformation, getCurrentUser } from "../../api/authenticationController";
import {
    getCheckoutData,
    getOrderCheckoutData,
    getUserCheckoutData,
    putCheckout
} from "../../api/checkoutController";
import { useCart } from "react-use-cart";
import { getPrices, getProductData } from "../../api/productController";
import { calculateTradePriceCategory } from "../../api/account";
import { find, sum } from "lodash";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const fetchAccountInformationData = async ({
    setAccountInformation,
    setAccountInformationCallStatus
}) => {
    setAccountInformationCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getAccountInformation();
        if (response?.success) {
            setAccountInformation(response.data);
            setAccountInformationCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setAccountInformationCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setAccountInformationCallStatus(ApiCallStatus.Error);
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};

const fetchUserCheckoutData = async ({
    setUserCheckoutInfo,
    setUserCheckoutInfoCallStatus
}) => {
    setUserCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let checkoutUser = await getUserCheckoutData();
        if (checkoutUser?.success) {
            setUserCheckoutInfo(checkoutUser.data);
            setUserCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setUserCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setUserCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchOrderCheckoutData = async ({
    setOrderCheckoutInfo,
    setOrderCheckoutInfoCallStatus
}) => {
    setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let checkoutUser = await getOrderCheckoutData();
        if (checkoutUser?.success) {
            setOrderCheckoutInfo(checkoutUser.data);
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setOrderCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchCheckoutData = async ({
  checkoutId,
  setCheckoutData,
  setCheckoutDataCallStatus
}) => {
    setCheckoutDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getCheckoutData(checkoutId);
        if (response?.success) {
            setCheckoutData(response.data);
            setCheckoutDataCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCheckoutDataCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setCheckoutDataCallStatus(ApiCallStatus.Error)
    }
}

const fetchProductData = async ({
    productSkus,
    setProductData,
    setProductDataCallStatus
}) => {
    setProductDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getProductData(productSkus);
        if (response?.success) {
            setProductData(response.data);
            setProductDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setProductDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setProductDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchPriceData = async ({
    productSkus,
    category,
    setPriceData,
    setPriceDataCallStatus
}) => {
    setPriceDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getPrices({
            productSkus,
            level: category
        });

        if (response?.success) {
            setPriceData(response.data);
            setPriceDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setPriceDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setPriceDataCallStatus(ApiCallStatus.Error);
    }
}

const updateCheckoutData = async ({
    checkoutId,
    checkoutData,
    setUpdateCheckoutDataCallStatus
}) => {
    setUpdateCheckoutDataCallStatus(ApiCallStatus.InProgress);
    try{
        const response = await putCheckout(checkoutId, checkoutData);

        if (response?.success) {
            setUpdateCheckoutDataCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setUpdateCheckoutDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (e) {
        console.error(e);
        setUpdateCheckoutDataCallStatus(ApiCallStatus.Error);
    }
}

const CheckoutOrderInformationPage = () => {
    const { items, cartTotal } = useCart();
    const location = useLocation();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [accountInformation, setAccountInformation] = useState(null);
    const [priceCategory, setPriceCategory] = useState(PriceCategory.Retail);
    const [productData, setProductData] = useState(null);
    const [priceData, setPriceData] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [checkoutData, setCheckoutData] = useState(null);
    const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
    const [userCheckoutInfo, setUserCheckoutInfo] = useState(null);
    const [vat, setVat] = useState(null);
    const [cartSubtotal, setCartSubtotal] = useState(0);
    const [cartVatAmount, setCartVatAmount] = useState(0);
    const [cartGrandTotal, setCartGrandTotal] = useState(0);
    const [salesPeople, setSalesPeople] = useState([]);
    const [accountType, setAccountType] = useState(AccountType.Guest);
    const [tradeAccount, setTradeAccount] = useState(null);
    const [retailAccount, setRetailAccount] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [accountInformationCallStatus, setAccountInformationCallStatus] = useState(ApiCallStatus.NotStarted)
    const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [userCheckoutInfoCallStatus, setUserCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [productDataCallStatus, setProductDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [priceDataCallStatus, setPriceDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [updateCheckoutDataCallStatus, setUpdateCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoLoginPage = () => {
        return navigate('/login', { state: { from: location } });
    };

    const reloadPage = () => navigate(0);
    const gotoErrorPage = () => navigate("/error");
    const gotoCheckoutBillingAddressPage = () => navigate("/checkout/billing");

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchAccountInformationDataAsync = async () => {
        await fetchAccountInformationData({
            setAccountInformation,
            setAccountInformationCallStatus
        });
    }

    const fetchCheckoutUserDataAsync = async () => {
        await fetchUserCheckoutData({
            setUserCheckoutInfo,
            setUserCheckoutInfoCallStatus
        });
    }

    const fetchCheckoutOrderDataAsync = async () => {
        await fetchOrderCheckoutData({
            setOrderCheckoutInfo,
            setOrderCheckoutInfoCallStatus
        });
    }

    const fetchCheckoutDataAsync = async () => {
        const checkoutId = await Cookies.get(storageKeys.CHECKOUT_ID);
        await fetchCheckoutData({
            checkoutId,
            setCheckoutData,
            setCheckoutDataCallStatus
        });
    }

    const fetchProductDataAsync = async () => {
        const productSkus = items?.map(p => p.sku)
        await fetchProductData({
            productSkus,
            setProductData,
            setProductDataCallStatus
        });
    }

    const fetchPriceDataAsync = async (userPriceCategory) => {
        const productSkus = items?.map(p => p.sku)
        await fetchPriceData({
            productSkus,
            category: userPriceCategory,
            setPriceData,
            setPriceDataCallStatus
        });
    }

    const authorizedPageLoad = async () => {
        await Promise.all([
            fetchCurrentUserAsync(),
            fetchAccountInformationDataAsync(),
            fetchCheckoutUserDataAsync()
        ]);
    }

    const unAuthorizedPageLoad = async () => {
        await Promise.all([
            fetchCheckoutOrderDataAsync(),
            fetchCheckoutDataAsync(),
            fetchProductDataAsync()
        ]);
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
            unAuthorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            gotoLoginPage();
            // setAccountInformationCallStatus(ApiCallStatus.Succeeded);
        }
        // unAuthorizedPageLoad();
    }, [loggedIn]);

    useEffect(() => {
        if (!!orderCheckoutInfo?.salesPeople) {
            let salesPeopleTemp = []

            if (!!orderCheckoutInfo.salesPeople) {
                salesPeopleTemp = orderCheckoutInfo.salesPeople;
            }

            setSalesPeople(salesPeopleTemp);
        }
    }, [orderCheckoutInfo?.salesPeople]);

    useEffect(() => {
        if (!!userCheckoutInfo) {
            if (userCheckoutInfo.currentTradeAccount) {
                setAccountType(AccountType.Trade);
            } else {
                setAccountType(AccountType.Retail);
            }
        }
    }, [userCheckoutInfo]);

    useEffect(() => {
        if (!!orderCheckoutInfo?.vat) {
            const vatTemp = orderCheckoutInfo.vat;
            setVat(vatTemp);
        }
    }, [orderCheckoutInfo?.vat]);

    useEffect(() => {
        if (accountType === AccountType.Trade) {
            setTradeAccount(userCheckoutInfo.currentTradeAccount);
        } else if (accountType === AccountType.Retail) {
            setRetailAccount(userCheckoutInfo)
        }
    }, [accountType]);

    useEffect(() => {
        if (updateCheckoutDataCallStatus === ApiCallStatus.Succeeded) {
            gotoCheckoutBillingAddressPage();
        }
    }, [updateCheckoutDataCallStatus]);

    useEffect(() => {
        if (accountInformationCallStatus === ApiCallStatus.Succeeded) {
            if (!!accountInformation?.currentTradeAccount) {
                const userPriceCategory = calculateTradePriceCategory(accountInformation.currentTradeAccount.category);
                setPriceCategory(userPriceCategory);
                fetchPriceDataAsync(userPriceCategory);
            }
            else {
                fetchPriceDataAsync(PriceCategory.Retail);
            }
        }
    }, [accountInformation, accountInformationCallStatus]);

    useEffect(() => {
        if (cartTotal > 0 && !!productData && !!priceData) {
            const cartItemsData = items.map((item, index) => {
                const product = find(productData, p => p.sku === item.sku);
                const price = find(priceData, p => p.sku === item.sku);

                return {
                    id: product?.productId ?? item.id,
                    image: product?.image ?? item.image,
                    name: product?.name ?? item.name,
                    sku: product?.sku ?? item.sku,
                    quantity: item.quantity,
                    price: price.price
                }
            })
            setCartItems(cartItemsData);
        }

    }, [cartTotal, productData, priceData]);

    useEffect(() => {
        if (!!cartItems && !!vat) {
            const lineItemTotals = cartItems.map(i => i.price * i.quantity);
            if (priceCategory === PriceCategory.Retail) {
                const totalTemp = sum(lineItemTotals);
                const subtotalTemp = totalTemp / (1 + (vat /100));
                const vatAmountTemp = totalTemp - subtotalTemp;

                setCartGrandTotal(totalTemp);
                setCartSubtotal(subtotalTemp);
                setCartVatAmount(vatAmountTemp);
            }
            else {
                const subtotalTemp = sum(lineItemTotals);
                const vatAmountTemp = subtotalTemp * (vat/100);
                const totalTemp = subtotalTemp + vatAmountTemp;

                setCartGrandTotal(totalTemp);
                setCartSubtotal(subtotalTemp);
                setCartVatAmount(vatAmountTemp);
            }
        }
    }, [cartItems, vat]);

    const updateCheckoutDataAsync = async ({
        checkoutData
    }) => {
        const checkoutId = Cookies.get(storageKeys.CHECKOUT_ID);

        if (!checkoutId) {
            gotoErrorPage()
        }

        setCheckoutDataCallStatus(ApiCallStatus.NotStarted);

        await updateCheckoutData({
            checkoutId,
            checkoutData,
            setUpdateCheckoutDataCallStatus
        });
        await fetchCheckoutDataAsync();
    }

    const allLoadingStates = [
        currentUserCallStatus,
        checkoutDataCallStatus,
        userCheckoutInfoCallStatus,
        orderCheckoutInfoCallStatus,
        updateCheckoutDataCallStatus,
        productDataCallStatus,
        priceDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser}/>
            <CheckoutOrderInformation currentUser={currentUser}
                                      salesPeople={salesPeople}
                                      accountType={accountType}
                                      tradeAccount={tradeAccount}
                                      retailAccount={retailAccount}
                                      checkoutData={checkoutData}
                                      subtotal={cartSubtotal}
                                      vatAmount={cartVatAmount}
                                      vat={vat}
                                      total={cartGrandTotal}
                                      cartItems={cartItems}
                                      onSubmit={updateCheckoutDataAsync} />
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <SiteFooter/>
        </Fragment>
    );
}

export default CheckoutOrderInformationPage;