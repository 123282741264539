import axios from "axios";
import Cookies from "js-cookie";
import { storageKeys } from "./storageKeys";

const url = process.env.REACT_APP_PRODUCT_API_URL;
const searchAppUrl = "https://argussearchapp.azurewebsites.net";

export const search = async (searchTerm, accessoriesOnly) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/search`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        let requestUrl = `?searchString=${searchTerm}`;
        requestUrl += accessoriesOnly ? `&accessoriesOnly=${accessoriesOnly}` : "";
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     data: response.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });

    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getVendorsAndManufacturers = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/search`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        let requestUrl = `/vendors-and-manufacturers`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getVendors = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/search`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        let requestUrl = `/vendors`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getPartNumbersByVendor = async (vendor) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/search`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        let requestUrl = `/part-numbers-by-vendor?vendor=${vendor}`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const getPartWithCrossReferences = async (sku) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/search`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        let requestUrl = `part-with-xrefs?sku=${sku}`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const getVehicleByRegistration = async (reg, vin) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/search`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        let requestUrl = `/reg/${reg}${vin ? `?vin=${vin}` : ""}`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const getVehicleByVin = async (vin, reg) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/search`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        let requestUrl = `/vin/${vin}${reg ? `?reg=${reg}` : ""}`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const getSearchSuggestions = async (searchTerm, category) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/search/suggestions`,
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        let requestUrl = `?searchString=${searchTerm}`;
        requestUrl += "&limitProducts=5";
        requestUrl += "&limitSkuProducts=5";
        requestUrl += "&limitSkuProducts=5";
        requestUrl += `&priceCategory=${category}`;


        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     data: response.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });

    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};