import { ApiCallStatus } from "../../utils/constants";
import { getSaleProducts } from "../../api/productController";
import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import GridItem from "../GridItem";

const fetchProductData = async ({
    priceCategory,
    setProductData,
    setProductDataCallStatus
}) => {
    setProductDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getSaleProducts(priceCategory);
        if (response?.success) {
            setProductData(response.data);
            setProductDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setProductDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setProductDataCallStatus(ApiCallStatus.Error);
    }
}

const SaleProducts = ({priceCategory}) => {

    const [productData, setProductData] = useState(null);

    const [productDataCallStatus, setProductDataCallStatus] = useState(ApiCallStatus.NotStarted);

    const fetchSaleProductDataAsync = async () => {
        await fetchProductData({
            priceCategory,
            setProductData,
            setProductDataCallStatus
        });
    }

    useEffect(() => {
        if (!!priceCategory) {
            fetchSaleProductDataAsync();
        }
    }, [priceCategory]);

    const allLoadingStates = [
        productDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <div className="mt-5 mb-5">
            {!!productData &&
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Swiper
                                modules={[Autoplay, Navigation]}
                                spaceBetween={50}
                                slidesPerView={4}
                                navigation
                                loop={true}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: true,
                                }}
                                onSwiper={(swiper) => console.log("")}>
                                {productData.map((product, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <GridItem
                                                title={product.name}
                                                price={product.price}
                                                priceCategory={priceCategory}
                                                vendor={product.vendor}
                                                image={product.image}
                                                productId={product.productId}
                                                vendorImage={product.vendorImage}
                                                handle={product.handle}
                                                vendorDescription={product.vendorDescription}
                                                quality={product.quality}
                                                fitment={product.fitment}
                                                availability={product.availability}
                                                warranty={product.warranty}
                                                warrantyText={product.warrantyText}
                                                applicationPosition={product.applicationPosition}
                                                applicationPositionImage={product.applicationPositionImage}
                                                // showVehicleSpecificInformation={showVehicleSpecificInformation}
                                                // isKiosk={isKiosk}
                                                bodyHtml={product.html}
                                                sku={product.sku}
                                                hideAddToCart={true}
                                            />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </div>
    )
}

export default SaleProducts;