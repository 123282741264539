import React, {useEffect, useState} from "react";
import { Button, Form, Grid, Header, Icon, Item, Segment } from "semantic-ui-react";
import { cloneDeep, compact, find, join, round, trim } from "lodash";
import { useCart } from "react-use-cart";
import { SouthAfricanRand } from "../../utils/helpers";

const CheckoutAddressUi = {
    firstLoad: -1,
    noAddressOptions: 0,
    addressDropdown: 1,
    addressForm: 2,
    addressChosen: 3,
    error: 4
}

const CheckoutCollectionAddress = ({
    checkoutData,
    cartItems,
    vat,
    subtotal,
    vatAmount,
    total,
    countries,
    provinces,
    collectionAddresses,
    onSubmit,
    gotoCheckoutSummaryPage

}) => {
    const { cartTotal} = useCart();
    const [currentUiState, setCurrentUiState] = useState(CheckoutAddressUi.firstLoad);
    const [chosenAddressId, setChosenAddressId] = useState(checkoutData?.collectionAddressId ?? null)
    const [formDropdownOptionsChosen, setFormDropdownOptionsChosen] = useState({
        addressId: checkoutData?.collectionAddressId ?? null
    });

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);


    useEffect(() => {
        if (!!collectionAddresses && collectionAddresses.length > 0) {
            setCurrentUiState(CheckoutAddressUi.addressDropdown)
        }
    }, [collectionAddresses]);

    const onUseAddressClick = (addressId) => {
        setChosenAddressId(addressId);
    }

    const onSaveAddressClick = async () => {
        if (!!chosenAddressId) {
            const checkoutDataClone = cloneDeep(checkoutData);
            checkoutDataClone.collectionAddressId = chosenAddressId;
            checkoutDataClone.shippingAddressId = null;

            const onSubmitOptions = {
                checkoutData: checkoutDataClone
            }

            await onSubmit(onSubmitOptions)
                .then (() => setChosenAddressId(null));
        }
    };

    const showSaveChanges = !!chosenAddressId;

    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Checkout</div>
            </div>
            {!!checkoutData &&
                <Grid container>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header className="mb-5" as="h4">
                                Collection address
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Segment padded>
                                <Grid container>
                                    {currentUiState === CheckoutAddressUi.addressDropdown &&
                                        <>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Grid container>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <Item.Group divided>
                                                                    {collectionAddresses.map(address => {
                                                                        const country = find(countries, x => x.countryId === address.countryId);
                                                                        const province = find(provinces, x => x.provinceId === address.provinceId);
                                                                        const addressArray = [
                                                                            address.streetAddress,
                                                                            address.suburb,
                                                                            address.city,
                                                                            address.postalCode,
                                                                            province?.name,
                                                                            country?.name
                                                                        ]
                                                                        const cleanedAddressArray = compact(addressArray);
                                                                        const addressString = join(cleanedAddressArray, ", ");

                                                                        let showAsCurrent = false;
                                                                        if (!!chosenAddressId) {
                                                                            showAsCurrent = address.addressId === chosenAddressId;
                                                                        }
                                                                        else if (!!checkoutData.collectionAddressId) {
                                                                            showAsCurrent = address.addressId === checkoutData.collectionAddressId;
                                                                        }

                                                                        return (
                                                                            <Item>
                                                                                <Item.Content>
                                                                                    <Item.Description>
                                                                                        <p className="b2">{addressString}</p>
                                                                                    </Item.Description>
                                                                                    <Item.Extra>
                                                                                        {!!showAsCurrent &&
                                                                                            <Button type="button"
                                                                                                    disabled
                                                                                                    icon
                                                                                                    className="green-button"
                                                                                                    floated='right'>
                                                                                                <Icon name="check"/>
                                                                                            </Button>
                                                                                        }
                                                                                        {!showAsCurrent &&
                                                                                            <Button type="button"
                                                                                                    className="blue-basic-button"
                                                                                                    onClick={() => onUseAddressClick(address.addressId)}
                                                                                                    floated='right'>
                                                                                                Use
                                                                                            </Button>
                                                                                        }
                                                                                    </Item.Extra>
                                                                                </Item.Content>
                                                                            </Item>
                                                                        )
                                                                    })}
                                                                </Item.Group>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                            </Grid.Row>
                                            {!!showSaveChanges &&
                                                <Grid.Row textAlign="right">
                                                    <Grid.Column width={16}>
                                                        <Button type="button"
                                                                className="blue-button"
                                                                onClick={onSaveAddressClick}>
                                                            <Icon name="save" />
                                                            Save
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            }
                                            {!showSaveChanges &&
                                                <Grid.Row textAlign="right">
                                                    <Grid.Column width={16}>
                                                        <Button type="button"
                                                                className="blue-button"
                                                                onClick={gotoCheckoutSummaryPage}>
                                                            Continue
                                                            <Icon name="arrow right"/>
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            }
                                        </>
                                    }
                                    {currentUiState === CheckoutAddressUi.error &&
                                        <>

                                        </>
                                    }
                                </Grid>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Segment padded>
                                <Grid container>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Header as="h6">Order summary</Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <p className="b3">Subtotal</p>
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right">
                                            {!!subtotal && <p className="b3">{SouthAfricanRand.format(round(subtotal, 2))}</p>}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <p className="b3">VAT</p>
                                            <sub>(VAT: {vat}%)</sub>
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right">
                                            {!!vatAmount && <p className="b3">{SouthAfricanRand.format(round(vatAmount, 2))}</p>}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <p className="b3">Total</p>
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right">
                                            {!!total && <p className="b3">{SouthAfricanRand.format(round(total, 2))}</p>}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </div>
    )
}

export default CheckoutCollectionAddress;