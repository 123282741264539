import React, {Fragment, useEffect, useState} from "react";
import Cart from "../../components/Cart";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import { getAccountInformation, getCurrentUser } from "../../api/authenticationController";
import {useNavigate} from "react-router-dom";
import { ApiCallStatus, LoggedInStatus, PriceCategory } from "../../utils/constants";
import { getVatPercentage } from "../../api/staticDataController";
import { getOrderCheckoutData, getUserCheckoutData, postCheckout } from "../../api/checkoutController";
import { useCart } from "react-use-cart";
import Cookies from "js-cookie";
import { storageKeys } from "../../api/storageKeys";
import { getPrices, getProductData } from "../../api/productController";
import { calculateTradePriceCategory } from "../../api/account";
import { find, sum } from "lodash";

const checkIsLoggedIn = async ({
   setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const fetchOrderCheckoutData = async ({
    setOrderCheckoutInfo,
    setOrderCheckoutInfoCallStatus
}) => {
    setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let checkoutUser = await getOrderCheckoutData();
        if (checkoutUser?.success) {
            setOrderCheckoutInfo(checkoutUser.data);
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setOrderCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchProductData = async ({
    productSkus,
    setProductData,
    setProductDataCallStatus
}) => {
    setProductDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getProductData(productSkus);
        if (response?.success) {
            setProductData(response.data);
            setProductDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setProductDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setProductDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchPriceData = async ({
    productSkus,
    category,
    setPriceData,
    setPriceDataCallStatus
}) => {
    setPriceDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getPrices({
            productSkus,
            level: category
        });

        if (response?.success) {
            setPriceData(response.data);
            setPriceDataCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setPriceDataCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setPriceDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchAccountInformationData = async ({
    setAccountInformation,
    setAccountInformationCallStatus
}) => {
    setAccountInformationCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getAccountInformation();
        if (response?.success) {
            setAccountInformation(response.data);
            setAccountInformationCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setAccountInformationCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setAccountInformationCallStatus(ApiCallStatus.Error);
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};

const createCheckout = async ({
    setCreateCheckoutCallStatus
}) => {
    setCreateCheckoutCallStatus(ApiCallStatus.InProgress);
    try
    {
        const response = await postCheckout();
        if (response.success) {
            Cookies.set(storageKeys.CHECKOUT_ID, response.data, { secure: true, sameSite: 'strict' });
            setCreateCheckoutCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCreateCheckoutCallStatus(ApiCallStatus.Failed);
        }
    }
    catch (error) {
        console.error(error);
        setCreateCheckoutCallStatus(ApiCallStatus.Error);
    }

}

const CartPage = () => {
    const {items, cartTotal} = useCart();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [accountInformation, setAccountInformation] = useState(null);
    const [priceCategory, setPriceCategory] = useState(PriceCategory.Retail);
    const [productData, setProductData] = useState(null);
    const [priceData, setPriceData] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [vat, setVat] = useState(null);
    const [cartSubtotal, setCartSubtotal] = useState(0);
    const [cartVatAmount, setCartVatAmount] = useState(0);
    const [cartGrandTotal, setCartGrandTotal] = useState(0);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [accountInformationCallStatus, setAccountInformationCallStatus] = useState(ApiCallStatus.NotStarted)
    const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [createCheckoutCallStatus, setCreateCheckoutCallStatus] = useState(ApiCallStatus.NotStarted);
    const [productDataCallStatus, setProductDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [priceDataCallStatus, setPriceDataCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoCheckoutPage = () => navigate("/checkout");
    const gotoErrorPage = () => navigate("/error");

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchAccountInformationDataAsync = async () => {
        await fetchAccountInformationData({
            setAccountInformation,
            setAccountInformationCallStatus
        });
    }

    const fetchCheckoutOrderDataAsync = async () => {
        await fetchOrderCheckoutData({
            setOrderCheckoutInfo,
            setOrderCheckoutInfoCallStatus
        });
    }

    const fetchProductDataAsync = async () => {
        const productSkus = items?.map(p => p.sku)
        await fetchProductData({
            productSkus,
            setProductData,
            setProductDataCallStatus
        });
    }

    const fetchPriceDataAsync = async (userPriceCategory) => {
        const productSkus = items?.map(p => p.sku)
        await fetchPriceData({
            productSkus,
            category: userPriceCategory,
            setPriceData,
            setPriceDataCallStatus
        });
    }

    const createCheckoutAsync = async () => {
        await createCheckout({
            setCreateCheckoutCallStatus
        });
    }

    const authorizedPageLoad = async () => {
        await Promise.all([
            fetchCurrentUserAsync(),
            fetchAccountInformationDataAsync()
        ]);
    }

    const unAuthorizedPageLoad = async () => {
        await Promise.all([
            fetchCheckoutOrderDataAsync(),
            fetchProductDataAsync()
        ]);
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            return navigate(0);
        }
        else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            setAccountInformationCallStatus(ApiCallStatus.Succeeded);
        }
        unAuthorizedPageLoad();
    }, [loggedIn]);

    useEffect(() => {
        if (!!orderCheckoutInfo?.vat) {
            const vatTemp = orderCheckoutInfo.vat;
            setVat(vatTemp);
        }
    }, [orderCheckoutInfo?.vat]);

    useEffect(() => {
        if (createCheckoutCallStatus === ApiCallStatus.Succeeded) {
            gotoCheckoutPage();
        }
    }, [createCheckoutCallStatus]);

    useEffect(() => {
        if (accountInformationCallStatus === ApiCallStatus.Succeeded) {
            if (!!accountInformation?.currentTradeAccount) {
                const userPriceCategory = calculateTradePriceCategory(accountInformation.currentTradeAccount.category);
                setPriceCategory(userPriceCategory);
                fetchPriceDataAsync(userPriceCategory);
            }
            else {
                fetchPriceDataAsync(PriceCategory.Retail);
            }
        }
    }, [accountInformation, accountInformationCallStatus]);

    useEffect(() => {
        if (cartTotal > 0 && !!productData && !!priceData) {
            const cartItemsData = items.map((item, index) => {
                const product = find(productData, p => p.sku === item.sku);
                const price = find(priceData, p => p.sku === item.sku);

                return {
                    id: product?.productId ?? item.id,
                    image: product?.image ?? item.image,
                    name: product?.name ?? item.name,
                    sku: product?.sku ?? item.sku,
                    quantity: item.quantity,
                    price: price.price
                }
            })
            setCartItems(cartItemsData);
        }

    }, [cartTotal, productData, priceData]);

    useEffect(() => {
        if (!!cartItems && !!vat) {
            const lineItemTotals = cartItems.map(i => i.price * i.quantity);
            if (priceCategory === PriceCategory.Retail) {
                const totalTemp = sum(lineItemTotals);
                const subtotalTemp = totalTemp / (1 + (vat /100));
                const vatAmountTemp = totalTemp - subtotalTemp;

                setCartGrandTotal(totalTemp);
                setCartSubtotal(subtotalTemp);
                setCartVatAmount(vatAmountTemp);
            }
            else {
                const subtotalTemp = sum(lineItemTotals);
                const vatAmountTemp = subtotalTemp * (vat/100);
                const totalTemp = subtotalTemp + vatAmountTemp;

                setCartGrandTotal(totalTemp);
                setCartSubtotal(subtotalTemp);
                setCartVatAmount(vatAmountTemp);
            }
        }
    }, [cartItems, vat]);

    const allLoadingStates = [
        currentUserCallStatus,
        accountInformationCallStatus,
        orderCheckoutInfoCallStatus,
        createCheckoutCallStatus,
        productDataCallStatus,
        priceDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser} />
            <Cart loading={isLoading}
                  subtotal={cartSubtotal}
                  vatAmount={cartVatAmount}
                  total={cartGrandTotal}
                  cartItems={cartItems}
                  vat={vat}
                  productData={productData}
                  priceData={priceData}
                  priceCategory={priceCategory}
                  createCheckout={createCheckoutAsync} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default CartPage;
