import React, {Fragment, useEffect, useState} from "react";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {useNavigate} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import { getAccountInformation, getCurrentUser } from "../../api/authenticationController";
import Home from "../../components/Home";
import SiteSubheader from "../../components/SiteSubheader";
import SiteTopbar from "../../components/SiteTopbar";
import { ApiCallStatus, LoggedInStatus, PriceCategory } from "../../utils/constants";
import { calculateTradePriceCategory } from "../../api/account";
import { fetchHomePageContentData } from "../PageFunctions/pageContentFunctions";
import {
    editAnnouncementDataMarkAsAcknowledged,
    editAnnouncementDataMarkAsSeen
} from "../PageFunctions/announcementFunctions";
import { getItem, setItem } from "../../utils/storageHelper";
import { storageKeys } from "../../api/storageKeys";
import { cloneDeep, uniq } from "lodash";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const fetchAccountInformationData = async ({
    setAccountInformation,
    setAccountInformationCallStatus
}) => {
    setAccountInformationCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getAccountInformation();
        if (response?.success && response?.data) {
            setAccountInformation(response.data);
            setAccountInformationCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setAccountInformationCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setAccountInformationCallStatus(ApiCallStatus.Error);
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};

const HomePage = () => {
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [accountInformation, setAccountInformation] = useState(null);
    const [pageContent, setPageContent] = useState(null);
    const [priceCategory, setPriceCategory] = useState(PriceCategory.Retail);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchHomePageContentCallStatus, setFetchHomePageContentCallStatus] = useState(ApiCallStatus.NotStarted);
    const [accountInformationCallStatus, setAccountInformationCallStatus] = useState(ApiCallStatus.NotStarted);
    const [editAnnouncementDataMarkAsSeenCallStatus, setEditAnnouncementDataMarkAsSeenCallStatus] = useState(ApiCallStatus.NotStarted);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchAccountInformationDataAsync = async () => {
        await fetchAccountInformationData({
            setAccountInformation,
            setAccountInformationCallStatus
        });
    }

    const fetchHomePageContentDataAsync = async () => {
        await fetchHomePageContentData({
            setPageContent,
            setStatusInProgress: () => setFetchHomePageContentCallStatus(ApiCallStatus.InProgress),
            setStatusSuccess: () => setFetchHomePageContentCallStatus(ApiCallStatus.Succeeded),
            setStatusFailed: () => setFetchHomePageContentCallStatus(ApiCallStatus.Failed),
            setStatusError: () => setFetchHomePageContentCallStatus(ApiCallStatus.Error)
        });
    }

    const editAnnouncementDataMarkAsSeenAsync = async (announcementId) => {
        const userAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS);
        let userAnnouncements = JSON.parse(userAnnouncementsString);

        if (!userAnnouncements) {
            userAnnouncements = {
                seen: [],
                acknowledged: []
            };
        }

        const userAnnouncementsClone = cloneDeep(userAnnouncements);
        const arrayClone = userAnnouncementsClone.seen;
        arrayClone.push(announcementId);
        userAnnouncementsClone.seen = uniq(arrayClone);
        setItem(storageKeys.USER_ANNOUNCEMENTS, JSON.stringify(userAnnouncementsClone));

        if (loggedIn === LoggedInStatus.Yes) {
            await editAnnouncementDataMarkAsSeen({
                announcementId,
                setStatusInProgress: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Error)
            });
        }
    }

    const editAnnouncementDataMarkAsAcknowledgedAsync = async (announcementId) => {
        const userAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS);
        let userAnnouncements = JSON.parse(userAnnouncementsString);

        if (!userAnnouncements) {
            userAnnouncements = {
                seen: [],
                acknowledged: []
            };
        }

        const userAnnouncementsClone = cloneDeep(userAnnouncements);
        const arrayClone = userAnnouncementsClone.acknowledged;
        arrayClone.push(announcementId);
        userAnnouncementsClone.acknowledged = uniq(arrayClone);
        setItem(storageKeys.USER_ANNOUNCEMENTS, JSON.stringify(userAnnouncementsClone));

        if (loggedIn === LoggedInStatus.Yes) {
            await editAnnouncementDataMarkAsAcknowledged({
                announcementId,
                setStatusInProgress: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Error)
            });
        }
    }

    const authorizedLoad = async () => {
        await Promise.all([
            fetchCurrentUserAsync(),
            fetchAccountInformationDataAsync(),
            fetchHomePageContentDataAsync()
        ])
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            return navigate(0);
        }
        else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            fetchHomePageContentDataAsync();
            setAccountInformationCallStatus(ApiCallStatus.Succeeded);
        }
    }, [loggedIn])

    useEffect(() => {
        if (accountInformationCallStatus === ApiCallStatus.Succeeded) {
            if (!!accountInformation?.currentTradeAccount) {
                const userPriceCategory = calculateTradePriceCategory(accountInformation.currentTradeAccount.category);
                setPriceCategory(userPriceCategory);
            }
        }
    }, [accountInformation, accountInformationCallStatus]);

    const allLoadingStates = [
        currentUserCallStatus,
        accountInformationCallStatus,
        fetchHomePageContentCallStatus,
        editAnnouncementDataMarkAsSeenCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteTopbar />
            <SiteHeader currentUser={currentUser} />
            <SiteSubheader currentUser={currentUser} />
            <Home
                priceCategory={priceCategory}
                pageContent={pageContent}
                markAnnouncementAsAcknowledged={editAnnouncementDataMarkAsAcknowledgedAsync}
                markAnnouncementAsSeen={editAnnouncementDataMarkAsSeenAsync} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default HomePage;
