import React from "react";
import { Box, Modal } from "@mui/material";
import { Button, Grid, Header, Icon, Image } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { SouthAfricanRand } from "../../utils/helpers";
import { round } from "lodash";

export const confirmationChoices = {
	changeVehicle: "change",
	searchForParts: "search",
	removeVehicle: "remove",
	cancel: "cancel"
};

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	bgcolor: '#FFFFFF',
	boxShadow: 24,
	p: 4
};


const AddedToCartModal = ({
	open,
	setOpen,
	item
}) => {
	const navigate = useNavigate();

	const closeModal = () => {
		setOpen(false);
	}

	return (
		<Modal open={open}>
			<Box sx={style}>
				<Grid>
					<Grid.Row textAlign="center">
						<Grid.Column width={16} textAlign="center">
							<Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="small" centered/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16} textAlign="center">
							<div className="argus-modal-title">Successfully added to cart</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={8}>
							<Image src={item.image} size="small" centered/>
						</Grid.Column>
						<Grid.Column width={8}>
							<p className="b1">{item.name}</p>
							<p className="b1">{SouthAfricanRand.format(round(item.price, 2))}</p>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16} textAlign="right">
							<Button type="button"
									className="blue-button"
									floated='right'
									size="medium"
									onClick={() => navigate("/cart")}>
								Go to cart
							</Button>
							<Button floated="right"
									size="medium"
									onClick={closeModal}
									type="button">
								Continue shopping
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Box>

				</Box>
			</Box>
		</Modal>
	);
};
export default AddedToCartModal;