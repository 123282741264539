import React, {useEffect, useState} from 'react'
import {Button, Grid, Header, Container} from "semantic-ui-react";
import {cloneDeep, remove} from "lodash";
import {GridProducts} from "../GridProducts";
import "./style.css";
import {confirmationChoices} from "../ConfirmationModal";
import AddedToCartModal from "../AddedToCart";
import { useNavigate } from "react-router-dom";
import GridFilters from "../GridFilters";


const ProductGrid = ({
    products,
    prices,
    priceCategory,
    pageNumber,
    pageSize,
    total,
    handlePageNumberChange
}) => {
    const navigate = useNavigate();const [addedToCartModelOpen, setAddedToCartModalOpen] = useState(false);
    const [productAddedInformation, setProductAddedInformation] = useState(null);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        if (!!productAddedInformation) {
            setAddedToCartModalOpen(true);
        }
    }, [productAddedInformation]);

    useEffect(() => {
        if (!addedToCartModelOpen) {
            setAddedToCartModalOpen(false);
        }
    }, [addedToCartModelOpen]);

    return (
        <Container className="argus-container" fluid>
            {total === 0 &&
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header as="h1" textAlign="center">No products matching your criteria.</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center" verticalAlign="middle">
                            <Button compact
                                    className="blue-basic-button"
                                    onClick={() =>  navigate(-1)}
                                    size="small">
                                Try again
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            {total > 0 &&
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <GridProducts
                                products={products}
                                prices={prices}
                                priceCategory={priceCategory}
                                pageNumber={pageNumber}
                                handlePageNumberChange={handlePageNumberChange}
                                totalResults={total}
                                pageSize={pageSize}
                                itemsPerRow={4}
                                setProductAddedInformation={setProductAddedInformation}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            {!!productAddedInformation &&
                <AddedToCartModal open={addedToCartModelOpen}
                                  setOpen={setAddedToCartModalOpen}
                                  item={productAddedInformation}/>

            }
        </Container>
    );
}

export default ProductGrid;