import React, {useEffect, useState} from "react";
import {Grid, Accordion, Menu, Container} from "semantic-ui-react";
import "./style.css";

export const LargeGridFilters = ({
    activeFilters,
    filters
}) => {
    return (
        <Container>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Accordion as={Menu} vertical fluid>
                            

                        </Accordion>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default LargeGridFilters;