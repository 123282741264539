import { Grid, Header, Image, Icon } from "semantic-ui-react";

import React, { useState } from "react";

const Team = () => {
    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Meet the team</div>
            </div>
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Header as="h3">Our management</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={3}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-management-baruch.png" />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Baruch</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-management-moshe.png" />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Moshe</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-management-shifra.png" />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Shifra</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-management-mark.png"/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Mark</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-management-larry.png"/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Larry</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row />
                <Grid.Row />
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Header as="h3">Our sales team</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={4}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-sales-lawrence.png"/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Lawrence</Header>
                                    <p className="b3">
                                        <Icon name="phone"/>
                                        <span>011 747 5200 | EXT 5602/3</span>
                                    </p>
                                    <p className="b3">
                                        <Icon name="envelope"/>
                                        <a href="mailto:%20LaurenceR@argusmotoring.co.za">LaurenceR@argusmotoring.co.za</a>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-sales-sanaa.png"/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Sanaa</Header>
                                    <p className="b3">
                                        <Icon name="phone"/>
                                        011 747 5200 | EXT 5705
                                    </p>
                                    <p className="b3">
                                        <Icon name="envelope"/>
                                        <a href="mailto:%20KajalL@argusmotoring.co.za">KajalL@argusmotoring.co.za</a>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-sales-calvin.png"/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Calvin</Header>
                                    <p className="b3">
                                        <Icon name="phone"/>
                                        011 747 5200 | EXT 5606/7
                                    </p>
                                    <p className="b3">
                                        <Icon name="envelope"/>
                                        <a href="mailto:%20CalvinM@argusmotoring.co.za">CalvinM@argusmotoring.co.za</a>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={4}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-sales-wiseman.png"/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Wiseman</Header>
                                    <p className="b3">
                                        <Icon name="phone"/>
                                        011 747 5200 | EXT 5903
                                    </p>
                                    <p className="b3">
                                        <Icon name="envelope"/>
                                        <a href="mailto:%20wisemans@argusmotoring.co.za">wisemans@argusmotoring.co.za</a>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-sales-vera.png"/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Vera</Header>
                                    <p className="b3">
                                        <Icon name="phone"/>
                                        011 747 5200 | EXT 5805
                                    </p>
                                    <p className="b3">
                                        <Icon name="envelope"/>
                                        <a href="mailto:%20veroniquev@argusmotoring.co.za">veroniquev@argusmotoring.co.za</a>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Image circular
                                           size="medium"
                                           src="https://argusweb.azureedge.net/argus-web/team/team-sales-elsa.png"/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <Header as="h6">Elsa</Header>
                                    <p className="b3">
                                        <Icon name="phone"/>
                                        011 747 5200 | EXT 5701
                                    </p>
                                    <p className="b3">
                                        <Icon name="envelope"/>
                                        <a href="mailto:%20ElsaD@argusmotoring.co.za">ElsaD@argusmotoring.co.za</a>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Row className="mb-5"/>
                </Grid.Row>
            </Grid>
        </div>
    );
};
export default Team;
