import React, {useEffect, useState} from "react";
import "./style.css";
import {useCart} from "react-use-cart";
import {
  Container,
  Icon,
  Grid,
  Form,
  Button,
  Header,
  Segment,
  Item
} from "semantic-ui-react";
import { cloneDeep, round } from "lodash";
import _ from 'lodash';
import {
  AccountType, AddressType,
  DeliveryMethod, LogisticsMethod
} from "../../utils/constants";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {yupResolver} from "@hookform/resolvers/yup";
import { SouthAfricanRand } from "../../utils/helpers";
import CheckoutAddress from "../CheckoutBillingAddress";
import CheckoutOrderInformation from "../CheckoutOrderInformation";

const CheckoutUiState = {
  OrderInformation: Symbol(1001),
  BillingAddress: Symbol(1002),
  DeliveryMethod: Symbol(1003),
  ShippingAddress: Symbol(1004),
  CollectionAddress: Symbol(1005),
  Summary: Symbol(1006),
  Error: Symbol(1007),
}



const Checkout = () => {
  const { cartTotal, items } = useCart();

  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
      <div className="argus-min-height">
        <div className="argus-title-background mb-5">
          <div className="argus-title">Checkout</div>
        </div>
        {cartTotal > 0 &&
          <Grid container>
            <Grid.Row>
              <Grid.Column width={11}>
                {/*<Form>*/}
                  {/*{currentUiState === CheckoutUiState.OrderInformation &&*/}
                  {/*    <CheckoutOrderInformation accountType={accountType}*/}
                  {/*                              currentUser={currentUser}*/}
                  {/*                              salesPeople={salesPeople}*/}
                  {/*                              retailAccount={retailAccount}*/}
                  {/*                              tradeAccount={tradeAccount} />*/}
                  {/*}*/}
                  {/*{currentUiState === CheckoutUiState.DeliveryMethod &&*/}
                  {/*    <>*/}
                  {/*      <Button type="button"*/}
                  {/*              compact*/}
                  {/*              className="blue-basic-button borderless"*/}
                  {/*              size="tiny"*/}
                  {/*              onClick={showOrderInformationForm}>*/}
                  {/*        <Icon name="arrow left" />*/}
                  {/*        Back*/}
                  {/*      </Button>*/}
                  {/*      <Segment padded>*/}
                  {/*        <Grid container>*/}
                  {/*          <Grid.Row>*/}
                  {/*            <Grid.Column width={8}>*/}
                  {/*              <Header as={!!deliveryMethod ? "h6" : "h4"}>Delivery method</Header>*/}
                  {/*              {deliveryMethod === DeliveryMethod.Ship && <p className="b1">Delivery</p>}*/}
                  {/*              {deliveryMethod === DeliveryMethod.Collection && <p className="b1">Collection</p>}*/}
                  {/*            </Grid.Column>*/}
                  {/*            <Grid.Column width={8} textAlign="right">*/}
                  {/*              {!!deliveryMethod &&*/}
                  {/*                  <Button type="button"*/}
                  {/*                          compact*/}
                  {/*                          className="blue-basic-button borderless"*/}
                  {/*                          size="tiny"*/}
                  {/*                          onClick={onChangeDeliveryMethodClick}>*/}
                  {/*                    Change*/}
                  {/*                  </Button>*/}
                  {/*              }*/}
                  {/*            </Grid.Column>*/}
                  {/*          </Grid.Row>*/}
                  {/*          {!deliveryMethod &&*/}
                  {/*              <Grid.Row>*/}
                  {/*                <Grid.Column width={16}>*/}
                  {/*                  <Item.Group divided>*/}
                  {/*                    <Item>*/}
                  {/*                      <Item.Content>*/}
                  {/*                        <Item.Header>Delivery</Item.Header>*/}
                  {/*                        <Item.Description>*/}
                  {/*                          <p className="mt-4">Orders received Monday to Thursday before 9am will be dispatched the same day.</p>*/}
                  {/*                          <p>Orders received after 9am on Friday to Sunday will be dispatched on the following Monday.</p>*/}
                  {/*                          <p>Delivery typically takes between 2 and 3 working days to deliver to all main centres.</p>*/}
                  {/*                          <p>Smaller cities and rural areas may take 5 to 7 working days.</p>*/}
                  {/*                        </Item.Description>*/}
                  {/*                        <Item.Extra>*/}
                  {/*                          <Button type="button"*/}
                  {/*                                  compact*/}
                  {/*                                  className="blue-basic-button"*/}
                  {/*                                  floated='right'*/}
                  {/*                                  onClick={() => handleDeliveryMethodClick(DeliveryMethod.Ship)}>*/}
                  {/*                            Deliver my order*/}
                  {/*                          </Button>*/}
                  {/*                        </Item.Extra>*/}
                  {/*                      </Item.Content>*/}
                  {/*                    </Item>*/}
                  {/*                    <Item>*/}
                  {/*                      <Item.Content>*/}
                  {/*                        <Item.Header>Collection</Item.Header>*/}
                  {/*                        <Item.Description>*/}
                  {/*                          <p className="mt-4 mb-4">Orders are generally ready within 60 minutes from confirmation of payment.</p>*/}
                  {/*                          <div className="mb-4">*/}
                  {/*                            <p><b>Collection address:</b></p>*/}
                  {/*                            <p>28 Great North Road,</p>*/}
                  {/*                            <p>Brentwood Park,</p>*/}
                  {/*                            <p>Benoni, 1501</p>*/}
                  {/*                          </div>*/}
                  {/*                          <div>*/}
                  {/*                            <p><b>Collection times:</b></p>*/}
                  {/*                            <p>Mon-Thu 8:00am-4:30pm</p>*/}
                  {/*                            <p>Fri: 8:00am-1:30pm</p>*/}
                  {/*                          </div>*/}
                  {/*                        </Item.Description>*/}
                  {/*                        <Item.Extra>*/}
                  {/*                          <Button type="button"*/}
                  {/*                                  compact*/}
                  {/*                                  className="blue-basic-button"*/}
                  {/*                                  floated='right'*/}
                  {/*                                  onClick={() => handleDeliveryMethodClick(DeliveryMethod.Collection)}>*/}
                  {/*                            Collect my order*/}
                  {/*                          </Button>*/}
                  {/*                        </Item.Extra>*/}
                  {/*                      </Item.Content>*/}
                  {/*                    </Item>*/}
                  {/*                  </Item.Group>*/}
                  {/*                </Grid.Column>*/}
                  {/*              </Grid.Row>*/}
                  {/*          }*/}
                  {/*        </Grid>*/}
                  {/*      </Segment>*/}
                  {/*    </>*/}
                  {/*}*/}
                  {/*{currentUiState === CheckoutUiState.ShippingAddress &&*/}
                  {/*    <>*/}
                  {/*      <Button type="button"*/}
                  {/*              compact*/}
                  {/*              className="blue-basic-button borderless"*/}
                  {/*              size="tiny"*/}
                  {/*              onClick={showChooseDeliveryMethod}>*/}
                  {/*        <Icon name="arrow left"/>*/}
                  {/*        Back*/}
                  {/*      </Button>*/}
                  {/*      <CheckoutAddress addressType={AddressType.Shipping}*/}
                  {/*                       addresses={shippingAddresses}*/}
                  {/*                       countries={countries}*/}
                  {/*                       provinces={provinces}*/}
                  {/*                       currentAddressId={shippingAddressId}*/}
                  {/*                       setCurrentAddressId={setShippingAddressId}*/}
                  {/*                       accountType={accountType}*/}
                  {/*                       storeAddress={storeShippingAddress}*/}
                  {/*                       fetchGooglePlaceDetails={fetchGooglePlaceDetails}*/}
                  {/*                       reloadAddressOptions={reloadShippingAddresses}/>*/}
                  {/*    </>*/}
                  {/*}*/}
                  {/*{currentUiState === CheckoutUiState.CollectionAddress &&*/}
                  {/*    <>*/}
                  {/*      <Button type="button"*/}
                  {/*              compact*/}
                  {/*              className="blue-basic-button borderless"*/}
                  {/*              size="tiny"*/}
                  {/*              onClick={showChooseDeliveryMethod}>*/}
                  {/*        <Icon name="arrow left"/>*/}
                  {/*        Back*/}
                  {/*      </Button>*/}
                  {/*      <CheckoutAddress addressType={AddressType.Collection}*/}
                  {/*                       addresses={collectionAddresses}*/}
                  {/*                       countries={countries}*/}
                  {/*                       provinces={provinces}*/}
                  {/*                       currentAddressId={collectionAddressId}*/}
                  {/*                       setCurrentAddressId={setCollectionAddressId}*/}
                  {/*                       accountType={accountType}*/}
                  {/*                       storeAddress={storeCollectionAddress}*/}
                  {/*                       fetchGooglePlaceDetails={fetchGooglePlaceDetails}*/}
                  {/*                       reloadAddressOptions={reloadCollectionAddresses}/>*/}
                  {/*    </>*/}
                  {/*}*/}
                  {/*{currentUiState === CheckoutUiState.Summary &&*/}
                  {/*    <>*/}
                  {/*      <Button type="button"*/}
                  {/*              compact*/}
                  {/*              className="blue-basic-button borderless"*/}
                  {/*              size="tiny"*/}
                  {/*              onClick={showChooseDeliveryMethod}>*/}
                  {/*        <Icon name="arrow left"/>*/}
                  {/*        Back*/}
                  {/*      </Button>*/}
                  {/*    </>*/}
                  {/*}*/}
                {/*</Form>*/}
              </Grid.Column>

            </Grid.Row>
          </Grid>
        }
        {cartTotal === 0 &&
            <Grid container>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center" verticalAlign="middle">
                  <Header as="h4">Your cart is empty</Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
        }
      </div>
  );
};

export default Checkout;
