import React, {useEffect, useState} from "react";
import "./style.css";
import {Button, Form, Image} from "semantic-ui-react";
import { capitalize, cloneDeep } from "lodash";
import { getManufacturers, getModels, getVariants, getYears } from "../../api/vehicleController";

const fetchManufacturersData = async ({
    userInput,
    setManufacturerOptions,
    setLoading
}) => {
    setLoading(true);
    try {
        const response = await getManufacturers(userInput.year);

        if (response?.success) {
            const sortedManufacturers = response.data.sort();
            const manufacturerOptions = sortedManufacturers.map(x => {
                return {
                    key: x,
                    text: x.toUpperCase(),
                    value: x
                }
            });
            setManufacturerOptions(manufacturerOptions);
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoading(false);
    }
}

const fetchYearsData = async ({
    userInput,
    setYearOptions,
    setLoading
}) => {
    setLoading(true);
    try {
        let response = await getYears(userInput.manufacturer, userInput.model, userInput.variant);

        if (response?.success) {
            const sortedYears = response.data.sort();
            const yearOptions = sortedYears.map(x => {
                return {
                    key: x,
                    text: x,
                    value: x
                }
            });
            setYearOptions(yearOptions);
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoading(false);
    }
}

const fetchModelsData = async ({
   userInput,
   setModelOptions,
   setLoading
}) => {
    setLoading(true);
    try {
        if (userInput.manufacturer || userInput.year) {
            const response = await getModels(userInput.manufacturer, userInput.year);

            if (response?.success) {
                const sortedModels = response.data.sort();

                const modelOptions = sortedModels.map(model => {
                    return {
                        key: model,
                        text: model.toUpperCase(),
                        value: model
                    }
                });
                setModelOptions(modelOptions);
            }
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoading(false);
    }
}

const fetchVariantsData = async ({
   userInput,
   setVariantOptions,
   setLoading
}) => {
    setLoading(true);
    try {
        if (userInput.manufacturer && userInput.model) {
            const response = await getVariants(userInput.manufacturer, userInput.model, userInput.year);

            if (response?.success) {
                const sortedVariants = response.data.sort();
                const variantOptions = sortedVariants.map(variant => {
                    return {
                        key: variant,
                        text: variant.toUpperCase(),
                        value: variant
                    }
                });
                setVariantOptions(variantOptions);
            }
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoading(false);
    }
}

const MakeModelYear = () => {
    const [yearOptions, setYearOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userInput, setUserInput] = useState({
        manufacturer: null,
        model: null,
        variant: null,
        year: null
    });

    useEffect(() => {
        const fetchManufacturersOptions = {
            userInput,
            setManufacturerOptions,
            setLoading
        }
        fetchManufacturersData(fetchManufacturersOptions);
    }, []);

    useEffect(() => {
        const fetchModelsOptions = {
            userInput,
            setModelOptions,
            setLoading
        }
        fetchModelsData(fetchModelsOptions);
    }, [userInput.manufacturer]);

    useEffect(() => {
        const fetchVariantsOptions = {
            userInput,
            setVariantOptions,
            setLoading
        }
        fetchVariantsData(fetchVariantsOptions);
    }, [userInput.model]);

    useEffect(() => {
        const fetchYearsOptions = {
            userInput,
            setYearOptions,
            setLoading
        }
        fetchYearsData(fetchYearsOptions);
    }, [userInput.variant]);

    const SubmitHandler = (e) => {
        e.preventDefault();
        let newUrl = "/products?"
        //TODO
        if (userInput.manufacturer) {
            if (newUrl.charAt(-1) !== "?") newUrl += "&";
            newUrl += `make=${userInput.manufacturer}`;
        }
        if (userInput.model) {
            if (newUrl.charAt(-1) !== "?") newUrl += "&";
            newUrl += `model=${userInput.model}`;
        }
        if (userInput.variant) {
            if (newUrl.charAt(-1) !== "?") newUrl += "&";
            newUrl += `variant=${userInput.variant}`;
        }
        if (userInput.year) {
            if (newUrl.charAt(-1) !== "?") newUrl += "&";
            newUrl += `year=${userInput.year}`;
        }
        window.location.href= newUrl
    };

    const handleUserVehicleChange = (_, {name, value}) => {
        let userInputCopy = cloneDeep(userInput);
        userInputCopy[name] = value;

        if (name === 'manufacturer') {
            userInputCopy.model = null;
            userInputCopy.variant = null;
            userInputCopy.year = null;
        }
        else if (name === 'model') {
            userInputCopy.variant = null;
            userInputCopy.year = null;
        }
        else if (name === 'variant') {
            userInputCopy.year = null;
        }
        setUserInput(userInputCopy);
    }

    return (
        <div className="find-form">
            <Image className="backgroundGrey26" src='https://argusweb.azureedge.net/argusblob01/web/argus-mmy.jpg' fluid />
            <Form className="mt-3" inverted onSubmit={SubmitHandler} size="tiny" fluid>
                <Form.Group inline widths="equal">
                    <Form.Field>
                        <Form.Select
                            fluid
                            name="manufacturer"
                            label='Make'
                            options={manufacturerOptions}
                            placeholder='Make'
                            value={userInput.manufacturer}
                            onChange={handleUserVehicleChange}
                            loading={loading}
                            search
                            clearable
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Select
                            fluid
                            name="model"
                            label='Model'
                            options={modelOptions}
                            placeholder='Model'
                            value={userInput.model}
                            onChange={handleUserVehicleChange}
                            loading={loading}
                            disabled={!userInput.manufacturer}
                            search
                            clearable
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Select
                            fluid
                            name="variant"
                            label='Variant'
                            options={variantOptions}
                            placeholder='Variant'
                            value={userInput.variant}
                            onChange={handleUserVehicleChange}
                            loading={loading}
                            disabled={!userInput.manufacturer && !userInput.model}
                            search
                            clearable
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Select
                            fluid
                            name="year"
                            label='Year'
                            options={yearOptions}
                            placeholder='Year'
                            value={userInput.year}
                            onChange={handleUserVehicleChange}
                            disabled={!userInput.manufacturer && !userInput.model && !userInput.variant}
                            loading={loading}
                            search
                            clearable
                        />
                    </Form.Field>
                    <Button className="form-button" type='submit' color='red'>Search</Button>
                </Form.Group>
            </Form>
        </div>
    )
}

export default MakeModelYear;