import { getHomePageContent } from "../../api/pageContentController";

export const fetchHomePageContentData = async ({
	setPageContent,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getHomePageContent();

		if (response?.success) {
			setPageContent(response.data);
			setStatusSuccess()
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error)
		setStatusError();
	}
};