import React  from "react";
import "./style.css";
import {Grid} from "semantic-ui-react";

const SiteHeader = () => {

  return (
      <Grid padded>
        <Grid.Row className="top-bar">
          <Grid.Column width={8} textAlign="center">
            <p className="b2 color-white mt-2">
              <b>
                <span className="color-yellow"> Free </span>
                PUDO locker delivery on orders over
                <span className="color-yellow"> R350</span>
              </b>
            </p>
          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            <p className="b2 color-white mt-2">
              <b>
                <span className="color-yellow"> Free </span>
                door to door delivery on orders over
                <span className="color-yellow"> R550</span>
              </b>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
  );
};

export default SiteHeader;
