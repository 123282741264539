import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getPartNumbersByVendor, getVendorsAndManufacturers} from "../../api/searchController";
import {Button, Form, Image} from "semantic-ui-react";
import "./style.css";
import { useNavigate } from "react-router-dom";

const fetchVendorData = async ({
    setVendorOptions,
    setLoading
}) => {
    setLoading(true);
    try {
        const response = await getVendorsAndManufacturers();
        if (response.success) {
            const sortedVendors = response.data.sort();
            const vendorOptions = sortedVendors.map(x => {
                return {
                    key: x,
                    text: x,
                    value: x
                }
            });
            setVendorOptions(vendorOptions);
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoading(false);
    }
}

const fetchPartNumberData = async ({
    vendor,
    setPartNumberOptions,
    setLoading
}) => {
    setLoading(true);
    try {
        const response = await getPartNumbersByVendor(vendor);
        if (response.success) {
            const sortedPartNumbers = response.data.sort();
            const partNumberOptions = sortedPartNumbers.map(x => {
                return {
                    key: x,
                    text: x,
                    value: x
                }
            });
            setPartNumberOptions(partNumberOptions);
        }
    }
    catch (error) {
        console.error(error);
    }
    finally {
        setLoading(false);
    }
}

const PartNumberSearch = () => {
    const navigate = useNavigate();
    const [vendorOptions, setVendorOptions] = useState([]);
    const [partNumberOptions, setPartNumberOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [vendorChosen, setVendorChosen] = useState(null);
    const [partNumberChosen, setPartNumberChosen] = useState(null);

    useEffect(() => {
        const fetchVendorDataOptions = {
            setVendorOptions,
            setLoading
        }

        fetchVendorData(fetchVendorDataOptions);
    }, [])

    useEffect(() => {
        const fetchPartNumberDataOptions = {
            vendor: vendorChosen,
            setPartNumberOptions,
            setLoading
        }

        fetchPartNumberData(fetchPartNumberDataOptions);
    }, [vendorChosen])

    const { t } = useTranslation();
    const submitHandler = (e) => {
        e.preventDefault();
        navigate(`/product/${partNumberChosen}`);
    };

    const handleVendorChange = (_, {value}) => {
        setVendorChosen(value);
    };

    const handlePartNumberChange = (_, {value}) => {
        setPartNumberChosen(value);
    };


    return (
        <div className="find-form">
            <Image src='https://argusweb.azureedge.net/argusblob01/web/argus-part-number.jpg' fluid />
            <Form className="mt-3" inverted onSubmit={submitHandler} size="small" fluid>
                <Form.Group inline widths="equal">
                    <Form.Field>
                        <Form.Select
                            fluid
                            name="vendor"
                            label='Vendor'
                            options={vendorOptions}
                            placeholder='Vendor'
                            value={vendorChosen}
                            onChange={handleVendorChange}
                            loading={loading}
                            search
                            clearable
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Select
                            fluid
                            name="partNumber"
                            label='Part Number'
                            options={partNumberOptions}
                            placeholder='Part Number'
                            value={partNumberChosen}
                            onChange={handlePartNumberChange}
                            loading={loading}
                            disabled={!vendorChosen}
                            search
                            clearable
                        />
                    </Form.Field>
                    <Button className="form-button" type='submit' color='red'>Search</Button>
                </Form.Group>
            </Form>
        </div>
    )
}

export default PartNumberSearch;