import React, {Fragment, useEffect, useState} from "react";
import SiteHeader from "../../components/SiteHeader";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import PaymentPayfast from "../../components/PaymentPayfast";
import {AccountType, ApiCallStatus, LoggedInStatus} from "../../utils/constants";
import {getOrderPaymentData} from "../../api/orderController";
import {useLocation, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {storageKeys} from "../../api/storageKeys";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getCurrentUser} from "../../api/authenticationController";
import {getPaymentUserData} from "../../api/paymentController";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const clearCookies = async () => {
    await removeAuthCookies();
};

const fetchPaymentOrderData = async ({
    orderId,
    setPaymentOrderInfo,
    setPaymentOrderInfoCallStatus
}) => {
    setPaymentOrderInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getOrderPaymentData(orderId);
        if (response?.success) {
            setPaymentOrderInfo(response.data);
            setPaymentOrderInfoCallStatus(ApiCallStatus.Succeeded);
        } else {
            setPaymentOrderInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setPaymentOrderInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchPaymentUserData = async ({
    setPaymentUserInfo,
    setPaymentUserInfoCallStatus
}) => {
    setPaymentUserInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getPaymentUserData();
        if (response?.success) {
            setPaymentUserInfo(response.data);
            setPaymentUserInfoCallStatus(ApiCallStatus.Succeeded);
        } else {
            setPaymentUserInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setPaymentUserInfoCallStatus(ApiCallStatus.Error);
    }
}

const PaymentPayfastPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [paymentOrderInfo, setPaymentOrderInfo] = useState(null);
    const [paymentUserInfo, setPaymentUserInfo] = useState(null);

    const [accountType, setAccountType] = useState(AccountType.Guest);
    const [tradeAccount, setTradeAccount] = useState(null);
    const [retailAccount, setRetailAccount] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [paymentOrderInfoCallStatus, setPaymentOrderInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [paymentUserInfoCallStatus, setPaymentUserInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [createPaymentCallStatus, setCreatePaymentCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoLoginPage = () => {
        return navigate('/login', { state: { from: location } });
    };

    const reloadPage = () => navigate(0);
    const gotoErrorPage = () => navigate("/error");
    const gotoPaymentPage = (orderId) => navigate(`/payment/${orderId}`);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchPaymentOrderDataAsync = async () => {
        const orderId = Cookies.get(storageKeys.ORDER_ID);
        await fetchPaymentOrderData({
            orderId,
            setPaymentOrderInfo,
            setPaymentOrderInfoCallStatus
        });
    }

    const fetchPaymentUserDataAsync = async () => {
        await fetchPaymentUserData({
            setPaymentUserInfo,
            setPaymentUserInfoCallStatus
        });
    }

    const authorizedPageLoad = async () => {
        await fetchCurrentUserAsync();
        await fetchPaymentUserDataAsync();
    }

    const unAuthorizedPageLoad = async () => {
        await fetchPaymentOrderDataAsync();
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
            unAuthorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            gotoLoginPage();
        }
        // unAuthorizedPageLoad();
    }, [loggedIn]);

    useEffect(() => {
        if (!!paymentUserInfo) {
            if (paymentUserInfo.currentTradeAccount) {
                setAccountType(AccountType.Trade);
            } else {
                setAccountType(AccountType.Retail);
            }
        }
    }, [paymentUserInfo]);

    useEffect(() => {
        if (accountType === AccountType.Trade) {
            setTradeAccount(paymentUserInfo.currentTradeAccount);
        } else if (accountType === AccountType.Retail) {
            setRetailAccount(paymentUserInfo)
        }
    }, [accountType]);

    const allLoadingStates = [
        currentUserCallStatus,
        paymentOrderInfoCallStatus,
        paymentUserInfoCallStatus,
        createPaymentCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);


    return (
        <Fragment>
            <SiteHeader currentUser={currentUser}/>
            <PaymentPayfast paymentOrderInfo={paymentOrderInfo}
                            paymentUserInfo={paymentUserInfo}
                            accountType={accountType}
                            tradeAccount={tradeAccount}
                            retailAccount={retailAccount}
            />
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <SiteFooter/>
        </Fragment>
    );
}

export default PaymentPayfastPage;