import React, {Fragment, useEffect, useState} from "react";

import {Backdrop, CircularProgress} from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getAccountInformation, getCurrentUser} from "../../api/authenticationController";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom'
import SiteTopbar from "../../components/SiteTopbar";
import SiteSubheader from "../../components/SiteSubheader";
import { cloneDeep, every } from "lodash";
import { getFilters, getPrices, getProducts, getProductsForVehicle } from "../../api/productController";
import ProductGrid from "../../components/ProductGrid";
import {AccountType, ApiCallStatus, LoggedInStatus, PriceCategory} from "../../utils/constants";
import {calculateTradePriceCategory} from "../../api/account";

const checkIsLoggedIn = async ({
   setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const fetchAccountInformationData = async ({
    setAccountInformation,
    setAccountInformationCallStatus
}) => {
    setAccountInformationCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getAccountInformation();
        console.log("response", response)
        if (response?.success && response?.data) {
            setAccountInformation(response.data);
            setAccountInformationCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setAccountInformationCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setAccountInformationCallStatus(ApiCallStatus.Error);
    }
}

const clearCookies = async () => {
    await removeAuthCookies();
};

const fetchProductsData = async ({
    productCategory,
    productType,
    vendor,
    vehicleYearId,
    make,
    model,
    variant,
    year,
    pageNumber,
    setProductsCallStatus,
    setProducts,
    setAllProductIds,
    setTotal,
    setPageSize,
    setPageNumber,
}) => {
    setProductsCallStatus(ApiCallStatus.InProgress)
    try {
        let response;

        if (!!vehicleYearId) {
            response = await getProductsForVehicle({
                productCategory,
                productType,
                vendor,
                vehicleYearId,
                pageNumber
            });
        }
        else {
            response = await getProducts({
                productCategory,
                productType,
                vendor,
                make,
                model,
                variant,
                year,
                pageNumber
            });
        }

        if (response?.success) {
            const productsData = response.data.products.data;
            const allProductIds = response.data.allProductIds;
            const total = response.data.products.total;
            const skip = response.data.products.skip;
            const limit = response.data.products.limit;

            setProducts(productsData);
            setAllProductIds(allProductIds);
            setTotal(total);
            setPageNumber(skip + 1);
            setPageSize(limit);
            setProductsCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setProductsCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setProductsCallStatus(ApiCallStatus.Error);
    }
}

const fetchPricesData = async ({
    products,
    level,
    setPrices,
    setPricesCallStatus
}) => {
    setPricesCallStatus(ApiCallStatus.InProgress)
    try {
        if (!products || products.length === 0) {
            setPricesCallStatus(ApiCallStatus.Failed);
            return;
        }

        const productSkus = products.map(x => x.sku);

        const getPricesOptions = {
            productSkus,
            level
        }
        const response = await getPrices(getPricesOptions);

        if (response?.success) {
            setPrices(response.data);
            setPricesCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setPricesCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setPricesCallStatus(ApiCallStatus.Error);
    }
}

const fetchFiltersData = async ({
    allProductIds,
    setFilters,
    currentProductCategory,
    currentProductType,
    currentVendor,
    currentMake,
    currentModel,
    currentVariant,
    currentYear,
    setFiltersCallStatus
}) => {
    setFiltersCallStatus(ApiCallStatus.InProgress);
    try {
        if (!allProductIds || allProductIds.length === 0) {
            setFiltersCallStatus(ApiCallStatus.Failed);
            return;
        }

        const response = await getFilters({
            productIds: allProductIds,
            currentProductCategory,
            currentProductType,
            currentVendor,
            currentMake,
            currentModel,
            currentVariant,
            currentYear
        });

        if (response?.success) {
            setFilters(response.data);
            setFiltersCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setFiltersCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setFiltersCallStatus(ApiCallStatus.Error);
    }
};

const ProductsPage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [accountInformation, setAccountInformation] = useState(null);
    const [accountType, setAccountType] = useState(AccountType.Retail);
    const [tradeAccount, setTradeAccount] = useState(null);
    const [retailAccount, setRetailAccount] = useState(null);
    const [priceCategory, setPriceCategory] = useState(PriceCategory.Retail);
    const [products, setProducts] = useState(null);
    const [prices, setPrices] = useState(null);
    const [filters, setFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState({
        productCategory: "",
        productType: "",
        vendor: "",
        vehicleYearId: "",
        make: "",
        model: "",
        year: null,
        pageNumber: null,
    });
    const [allProductIds, setAllProductIds] = useState([]);
    const [total, setTotal] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(18);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted)
    const [accountInformationCallStatus, setAccountInformationCallStatus] = useState(ApiCallStatus.NotStarted)
    const [productsCallStatus, setProductsCallStatus] = useState(ApiCallStatus.NotStarted)
    const [filtersCallStatus, setFiltersCallStatus] = useState(ApiCallStatus.NotStarted)
    const [pricesCallStatus, setPricesCallStatus] = useState(ApiCallStatus.NotStarted)

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchAccountInformationDataAsync = async () => {
        await fetchAccountInformationData({
            setAccountInformation,
            setAccountInformationCallStatus
        });
    }

    const fetchPricesDataAsync = async (priceCategory) => {
        await fetchPricesData({
            products,
            setPrices,
            level: priceCategory,
            setPricesCallStatus
        });
    }

    const fetchFiltersDataAsync = async () => {
        const fetchFiltersDataOptions = {
            allProductIds,
            setFilters,
            currentProductCategory: activeFilters.productCategory,
            currentProductType: activeFilters.productType,
            currentVendor: activeFilters.vendor,
            currentMake: activeFilters.make,
            currentModel: activeFilters.model,
            currentVariant: activeFilters.variant,
            currentYear: activeFilters.year,
            setFiltersCallStatus
        }

        await fetchFiltersData(fetchFiltersDataOptions);
    }

    useEffect(() => {
        scrollTop();
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            fetchCurrentUserAsync();
            fetchAccountInformationDataAsync();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            return navigate(0);
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            setAccountInformationCallStatus(ApiCallStatus.Succeeded);
        }
    }, [loggedIn]);

    useEffect(() => {
        const productCategory = searchParams.get('product_category');
        const productType = searchParams.get('product_type');
        const vendor = searchParams.get('vendor');
        let vehicleYearId = searchParams.get('vehicle_year_id');
        let make = searchParams.get('make');
        let model = searchParams.get('model');
        let variant = searchParams.get('variant');
        let year = searchParams.get('year');
        const pageNumberQuery = searchParams.get('pageNumber') ?? "1";

        const activeFiltersClone = cloneDeep(activeFilters);
        if (!!activeFiltersClone) {
            activeFiltersClone.productCategory = productCategory;
            activeFiltersClone.productType = productType;
            activeFiltersClone.vendor = vendor;
            activeFiltersClone.vehicleYearId = vehicleYearId;
            activeFiltersClone.make = make;
            activeFiltersClone.model = model;
            activeFiltersClone.variant = variant;
            activeFiltersClone.year = year;
            activeFiltersClone.pageNumber = pageNumberQuery;

            setActiveFilters(activeFiltersClone);
        }
    }, [searchParams]);

    useEffect(() => {
        const fetchProductsDataAsync = async () => {
            /* USE VEHICLE SAVED IN SEARCH
            const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);
            let vehicle = null;

            if (!!vehicleString) {
                const vehicleObject = JSON.parse(vehicleString);
                vehicle = cloneDeep(vehicleObject);
            }


            if (!!vehicle) {
                if (!!vehicle.vehicleYearId) {
                    if (!vehicleYearId && !vehicle?.vehicleYearId) vehicleYearId = vehicle.vehicleYearId;
                }
                else {
                    if (!make && !!vehicle?.make) make = vehicle.make;
                    if (!model && !!vehicle?.model) make = vehicle.model;
                    if (!variant && !!vehicle?.variant) make = vehicle.variant;
                    if (!year && !!vehicle?.year) make = vehicle.year;
                }
            }
            */

            const productQueryParams = [activeFilters.productCategory, activeFilters.productType, activeFilters.vendor];
            const vehicleQueryParams = [activeFilters.vehicleYearId, activeFilters.make, activeFilters.model, activeFilters.variant, activeFilters.year];

            const atLeastOneProductQueryParam = productQueryParams.some(x => !!x);
            const atLeastOneVehicleQueryParam = vehicleQueryParams.some(x => !!x);

            if (atLeastOneProductQueryParam || atLeastOneVehicleQueryParam) {
                const fetchProductsDataOptions = {
                    productCategory: activeFilters.productCategory,
                    productType: activeFilters.productType,
                    vendor: activeFilters.vendor,
                    vehicleYearId: activeFilters.vehicleYearId,
                    make: activeFilters.make,
                    model: activeFilters.model,
                    variant: activeFilters.variant,
                    year: activeFilters.year,
                    pageNumber: activeFilters.pageNumber,
                    setProductsCallStatus,
                    setProducts,
                    setAllProductIds,
                    setTotal,
                    setPageSize,
                    setPageNumber
                }
                await fetchProductsData(fetchProductsDataOptions);
            }
        }

        setProductsCallStatus(ApiCallStatus.NotStarted);
        scrollTop();
        if (!!activeFilters) {
            fetchProductsDataAsync();
        }
    }, [activeFilters]);

    useEffect(() => {
        if (accountInformationCallStatus === ApiCallStatus.Succeeded && !!products && products.length >0) {
            if (!!accountInformation?.currentTradeAccount) {
                const userPriceCategory = calculateTradePriceCategory(accountInformation.currentTradeAccount.category);
                setPriceCategory(userPriceCategory);
                fetchPricesDataAsync(userPriceCategory);
            }
            else {
                fetchPricesDataAsync(PriceCategory.Retail);
            }
        }
    }, [accountInformation, accountInformationCallStatus, products]);

    const handlePageNumberChange = async (_, {activePage}) => {
        await updateUrlQueryValue("pageNumber", activePage);
    }

    const updateUrlQueryValue = async (key, value) => {
        setProductsCallStatus(ApiCallStatus.NotStarted);
        const currentParams = Object.fromEntries([...searchParams]);
        const paramsClone = cloneDeep(currentParams);

        paramsClone[key] = value;
        await navigate({
            pathname: '/products',
            search: `?${createSearchParams(paramsClone)}`
        });
    }

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    const allLoadingStates = [
        currentUserCallStatus,
        accountInformationCallStatus,
        productsCallStatus,
        filtersCallStatus,
        pricesCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteTopbar />
            <SiteHeader currentUser={currentUser} />
            <SiteSubheader currentUser={currentUser} />
            <ProductGrid products={products}
                         prices={prices}
                         priceCategory={priceCategory}
                         filters={filters}
                         activeFilters={activeFilters}
                         total={total}
                         pageNumber={pageNumber}
                         pageSize={pageSize}
                         handlePageNumberChange={handlePageNumberChange}  />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteFooter />
        </Fragment>
    );
};
export default ProductsPage;
