import React, {useEffect, useState} from "react";
import {
    Button,
    Grid,
    Header,
    Item,
    Label,
    Segment
} from "semantic-ui-react";
import { LogisticsMethod, PaymentMethod } from "../../utils/constants";
import { SouthAfricanRand } from "../../utils/helpers";
import { round } from "lodash";



const Payment = ({
    paymentOrderInfo,
    paymentUserInfo,
    fullTradeAccount,
    logisticsMethod,
    gotoPaymentPayfastPage,
    gotoPaymentAccountCredit,
    gotoPaymentEftPage,
    gotoPaymentCreditCardOnCollectionPage
}) => {
    const [recommendedPaymentMethod, setRecommendedPaymentMethod] = useState(PaymentMethod.PayFast);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        if (!!fullTradeAccount && !!paymentOrderInfo) {
            if (fullTradeAccount.availableCredit >= paymentOrderInfo.total) {
                setRecommendedPaymentMethod(PaymentMethod.AccountCredit)
            }
        }
    }, [fullTradeAccount]);

    const deliveryFeeToDisplay = () => {
        if (!!paymentOrderInfo?.logisticsFee) {
            return SouthAfricanRand.format(round(paymentOrderInfo?.logisticsFee, 2))
        }
        else if (paymentOrderInfo?.logisticsFee === 0) {
            return "Free";
        }
        else {
            return "TBD"
        }
    }


    return (
        <div className="argus-min-height">
            <div className="argus-title-background mb-5">
                <div className="argus-title">Payment</div>
            </div>
            {!!paymentOrderInfo &&
                <Grid container>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={12}>
                            <Header as="h4">Choose your payment method</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Segment padded>
                                <Grid container>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Item.Group>
                                                {!!fullTradeAccount &&
                                                    <Item>
                                                        <Item.Content verticalAlign='middle'>
                                                            <Item.Image size="small" src="https://argusweb.azureedge.net/argus-web/logo.svg"/>
                                                            <Button type="button"
                                                                    compact
                                                                    className="blue-basic-button"
                                                                    floated='right'
                                                                    onClick={gotoPaymentAccountCredit}>
                                                                Pay with account credit
                                                            </Button>
                                                            {recommendedPaymentMethod === PaymentMethod.AccountCredit &&
                                                                <Label className="green-label" tag size="small">
                                                                    Recommended
                                                                </Label>
                                                            }
                                                        </Item.Content>
                                                    </Item>
                                                }
                                                <Item>
                                                    <Item.Content verticalAlign='middle'>
                                                        <Item.Image size="small" src="https://argusweb.azureedge.net/argus-web/payment/eft.svg" />
                                                        <Button type="button"
                                                                compact
                                                                className="blue-basic-button"
                                                                floated='right'
                                                                onClick={gotoPaymentEftPage}>
                                                            Pay via EFT
                                                        </Button>
                                                    </Item.Content>
                                                </Item>
                                                {logisticsMethod === LogisticsMethod.Collection &&
                                                    <Item>
                                                        <Item.Content verticalAlign='middle'>
                                                            <Item.Image size="small" src="https://argusweb.azureedge.net/argus-web/payment/creditcard.svg" />
                                                            <Button type="button"
                                                                    compact
                                                                    className="blue-basic-button"
                                                                    floated='right'
                                                                    onClick={gotoPaymentCreditCardOnCollectionPage}>
                                                                Pay via credit card on collection
                                                            </Button>
                                                        </Item.Content>
                                                    </Item>
                                                }
                                            </Item.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Segment padded>
                                <Grid container>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Header as="h6">Order summary</Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {!!paymentOrderInfo?.vatPercentage && !!paymentOrderInfo?.vatAmount &&
                                        <>
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <Header as="h6">Subtotal</Header>
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right">
                                                    <Header as="h5">{SouthAfricanRand.format(round(paymentOrderInfo.subtotal, 2))}</Header>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <Header as="h6">VAT</Header>
                                                    <sub>(VAT: {paymentOrderInfo?.vatPercentage * 100}%)</sub>
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right">
                                                    <Header as="h5">{SouthAfricanRand.format(round(paymentOrderInfo.vatAmount, 2))}</Header>
                                                </Grid.Column>
                                            </Grid.Row>
                                            {logisticsMethod === LogisticsMethod.Ship &&
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Header as="h6">Delivery fee</Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        <Header as="h5">{deliveryFeeToDisplay()}</Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            }
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <Header as="h6">Total</Header>
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right">
                                                    <Header as="h5">{SouthAfricanRand.format(round(paymentOrderInfo?.total, 2))}</Header>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </>
                                    }
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            }
            {/*{orderPaymentInfo &&*/}
            {/*    <Grid container>*/}
            {/*        <Grid.Row>*/}
            {/*            <Grid.Column width={11}>*/}
            {/*                <Segment padded>*/}
            {/*                    <Grid container>*/}
            {/*                        <Grid.Row verticalAlign="middle">*/}
            {/*                            <Grid.Column width={!!paymentMethod ? 4 : 12}>*/}
            {/*                                {!paymentMethod && <Header as="h4">Choose your payment method</Header>}*/}
            {/*                                {!!paymentMethod && <Header as="h6">Payment method</Header>}*/}
            {/*                            </Grid.Column>*/}
            {/*                            {!!paymentMethod &&*/}
            {/*                                <Grid.Column textAlign="center" width={8}>*/}
            {/*                                    {paymentMethod === PaymentMethod.AccountCredit &&*/}
            {/*                                        <>*/}
            {/*                                            <Image src={ArgusLogo} size="small" fluid centered/>*/}
            {/*                                            <p>Account credit</p>*/}
            {/*                                        </>*/}
            {/*                                    }*/}
            {/*                                    {paymentMethod === PaymentMethod.PayFast &&*/}
            {/*                                        <Image src={PayfastLogo} size="small" fluid centered/>*/}
            {/*                                    }*/}
            {/*                                    {paymentMethod === PaymentMethod.EFT &&*/}
            {/*                                        <Image src={EftLogo} size="small" fluid centered/>*/}
            {/*                                    }*/}
            {/*                                    {paymentMethod === PaymentMethod.CardOnCollection &&*/}
            {/*                                        <>*/}
            {/*                                            <Image src={CreditCardLogo} size="small" fluid centered/>*/}
            {/*                                            <p>Credit card on collection</p>*/}
            {/*                                        </>*/}
            {/*                                    }*/}
            {/*                                </Grid.Column>*/}
            {/*                            }*/}
            {/*                            <Grid.Column width={4} textAlign="right">*/}
            {/*                                {!!paymentMethod &&*/}
            {/*                                    <Button type="button"*/}
            {/*                                            compact*/}
            {/*                                            className="blue-basic-button borderless"*/}
            {/*                                            size="tiny"*/}
            {/*                                            onClick={onChangePaymentMethodClick}>*/}
            {/*                                        Change*/}
            {/*                                    </Button>*/}
            {/*                                }*/}
            {/*                            </Grid.Column>*/}
            {/*                        </Grid.Row>*/}
            {/*                        {!paymentMethod &&*/}
            {/*                            <Grid.Row>*/}
            {/*                                <Grid.Column width={16}>*/}
            {/*                                    <Item.Group divided>*/}
            {/*                                        {!!tradeAccount &&*/}
            {/*                                            <Item>*/}
            {/*                                                <Item.Content verticalAlign='middle'>*/}
            {/*                                                    <Item.Image size="small" src={ArgusLogo}/>*/}
            {/*                                                    <Button type="button"*/}
            {/*                                                            compact*/}
            {/*                                                            className="blue-basic-button"*/}
            {/*                                                            floated='right'*/}
            {/*                                                            onClick={() => handlePaymentMethodClick(PaymentMethod.AccountCredit)}>*/}
            {/*                                                        Pay with account credit*/}
            {/*                                                    </Button>*/}
            {/*                                                    {recommendedPaymentMethod === PaymentMethod.AccountCredit &&*/}
            {/*                                                        <Label className="green-label" tag size="small">*/}
            {/*                                                            Recommended*/}
            {/*                                                        </Label>*/}
            {/*                                                    }*/}
            {/*                                                </Item.Content>*/}
            {/*                                            </Item>*/}
            {/*                                        }*/}
            {/*                                        <Item>*/}
            {/*                                            <Item.Content verticalAlign='middle'>*/}
            {/*                                                <Item.Image size="small" src={PayfastLogo} />*/}
            {/*                                                <Button type="button"*/}
            {/*                                                        compact*/}
            {/*                                                        className="blue-basic-button"*/}
            {/*                                                        floated='right'*/}
            {/*                                                        onClick={gotoPaymentPayfastPage}>*/}
            {/*                                                    Pay with Payfast*/}
            {/*                                                </Button>*/}
            {/*                                                {recommendedPaymentMethod === PaymentMethod.PayFast &&*/}
            {/*                                                    <Label className="green-label" tag size="small">*/}
            {/*                                                        Recommended*/}
            {/*                                                    </Label>*/}
            {/*                                                }*/}
            {/*                                            </Item.Content>*/}
            {/*                                        </Item>*/}
            {/*                                        <Item>*/}
            {/*                                            <Item.Content verticalAlign='middle'>*/}
            {/*                                                <Item.Image size="small" src={EftLogo} />*/}
            {/*                                                <Button type="button"*/}
            {/*                                                        compact*/}
            {/*                                                        className="blue-basic-button"*/}
            {/*                                                        floated='right'*/}
            {/*                                                        onClick={() => handlePaymentMethodClick(PaymentMethod.EFT)}>*/}
            {/*                                                    Pay via EFT*/}
            {/*                                                </Button>*/}
            {/*                                            </Item.Content>*/}
            {/*                                        </Item>*/}
            {/*                                        {!!orderPaymentInfo.logisticsMethod && orderPaymentInfo.logisticsMethod === LogisticsMethod.Collection &&*/}
            {/*                                            <Item>*/}
            {/*                                                <Item.Content verticalAlign='middle'>*/}
            {/*                                                    <Item.Image size="small" src={CreditCardLogo} />*/}
            {/*                                                    <Button type="button"*/}
            {/*                                                            compact*/}
            {/*                                                            className="blue-basic-button"*/}
            {/*                                                            floated='right'*/}
            {/*                                                            onClick={() => handlePaymentMethodClick(PaymentMethod.CardOnCollection)}>*/}
            {/*                                                        Pay via credit card on collection*/}
            {/*                                                    </Button>*/}
            {/*                                                </Item.Content>*/}
            {/*                                            </Item>*/}
            {/*                                        }*/}
            {/*                                    </Item.Group>*/}
            {/*                                </Grid.Column>*/}
            {/*                            </Grid.Row>*/}
            {/*                        }*/}
            {/*                    </Grid>*/}
            {/*                </Segment>*/}
            {/*                {showAccountCreditPaymentMethod &&*/}
            {/*                    <Segment padded loading={tecfinityAccountLoading}>*/}
            {/*                        {!!fullTecfinityInfo && !tecfinityAccountLoading &&*/}
            {/*                            <Grid container>*/}
            {/*                                <Grid.Row>*/}
            {/*                                    <Grid.Column width={16}>*/}
            {/*                                        <Table celled collapsing>*/}
            {/*                                            <Table.Body>*/}
            {/*                                                <Table.Row>*/}
            {/*                                                    <Table.Cell>*/}
            {/*                                                        <Header as="h4">Available Credit</Header>*/}
            {/*                                                    </Table.Cell>*/}
            {/*                                                    <Table.Cell>{SouthAfricanRand.format(round(fullTecfinityInfo.availableCredit, 2))}</Table.Cell>*/}
            {/*                                                </Table.Row>*/}
            {/*                                                <Table.Row>*/}
            {/*                                                    <Table.Cell>*/}
            {/*                                                        <Header as="h4">Credit Limit</Header>*/}
            {/*                                                    </Table.Cell>*/}
            {/*                                                    <Table.Cell>{SouthAfricanRand.format(round(fullTecfinityInfo.creditLimit, 2))}</Table.Cell>*/}
            {/*                                                </Table.Row>*/}
            {/*                                            </Table.Body>*/}
            {/*                                        </Table>*/}
            {/*                                    </Grid.Column>*/}
            {/*                                </Grid.Row>*/}
            {/*                                <Grid.Row>*/}
            {/*                                    <Grid.Column width={16}>*/}
            {/*                                        <Button className="blue-basic-button" type="button" onClick={onAccountCreditPaymentClick}>*/}
            {/*                                            Pay now*/}
            {/*                                        </Button>*/}
            {/*                                    </Grid.Column>*/}
            {/*                                </Grid.Row>*/}
            {/*                            </Grid>*/}
            {/*                        }*/}
            {/*                        {!fullTecfinityInfo && !tecfinityAccountLoading &&*/}
            {/*                            <Grid container>*/}
            {/*                                <Grid.Row verticalAlign="middle">*/}
            {/*                                    <Grid.Column width={16}>*/}
            {/*                                        <Header as="h4">We are experiencing technical difficulties</Header>*/}
            {/*                                    </Grid.Column>*/}
            {/*                                </Grid.Row>*/}
            {/*                                <Grid.Row verticalAlign="middle">*/}
            {/*                                    <Grid.Column width={16} textAlign="center">*/}
            {/*                                        <p>We were unable to get the latest account balances for your Tecfinity account.</p>*/}
            {/*                                        <p>Please try again later or use a different payment method.</p>*/}
            {/*                                        <p>We apologise for the inconvenience.</p>*/}
            {/*                                    </Grid.Column>*/}
            {/*                                </Grid.Row>*/}
            {/*                            </Grid>*/}
            {/*                        }*/}
            {/*                        {!fullTecfinityInfo && tecfinityAccountLoading &&*/}
            {/*                            <Grid container>*/}
            {/*                                <Grid.Row verticalAlign="middle">*/}
            {/*                                    <Grid.Column width={16}>*/}
            {/*                                        <Header as="h4">Please wait while we fetch your account information.</Header>*/}
            {/*                                    </Grid.Column>*/}
            {/*                                </Grid.Row>*/}
            {/*                                <Grid.Row verticalAlign="middle">*/}
            {/*                                    <Grid.Column width={16} textAlign="center">*/}
            {/*                                        <p>This may take a while...</p>*/}
            {/*                                    </Grid.Column>*/}
            {/*                                </Grid.Row>*/}
            {/*                            </Grid>*/}
            {/*                        }*/}
            {/*                    </Segment>*/}
            {/*                }*/}
            {/*                {showPayfastPaymentMethod &&*/}
            {/*                    <Segment padded>*/}
            {/*                        {payfastDetails &&*/}
            {/*                            <Grid container>*/}
            {/*                                <Grid.Row>*/}
            {/*                                    <Grid.Column width={16} textAlign="center">*/}
            {/*                                        <p className="b1 mb-4">You will be securely redirect to Payfast to complete this transaction.</p>*/}
            {/*                                        <form action="https://sandbox.payfast.co.za/eng/process" method="post">*/}
            {/*                                            <input type="hidden" name="merchant_id" value={payfastDetails.merchantId} />*/}
            {/*                                            <input type="hidden" name="m_payment_id" value={payfastDetails.merchantPaymentId} />*/}
            {/*                                            <input type="hidden" name="merchant_key" value={payfastDetails.merchantKey} />*/}
            {/*                                            <input type="hidden" name="custom_str1" value={payfastDetails.paymentId} />*/}
            {/*                                            <input type="hidden" name="amount" value={payfastDetails.amount} />*/}
            {/*                                            <input type="hidden" name="item_name" value={payfastDetails.orderNumber} />*/}
            {/*                                            <input type="hidden" name="return_url" value={`${payfastDetails.returnUrl}`} />*/}
            {/*                                            <input type="hidden" name="cancel_url" value={`${payfastDetails.cancelUrl}`} />*/}
            {/*                                            <input type="hidden" name="notify_url" value={payfastDetails.notifyUrl} />*/}
            {/*                                            <Button className="blue-basic-button" type="submit"*/}
            {/*                                                    onClick={onAccountCreditPaymentClick}>*/}
            {/*                                                Pay now*/}
            {/*                                            </Button>*/}
            {/*                                        </form>*/}

            {/*                                    </Grid.Column>*/}
            {/*                                </Grid.Row>*/}
            {/*                            </Grid>*/}
            {/*                        }*/}
            {/*                    </Segment>*/}
            {/*                }*/}
            {/*            </Grid.Column>*/}
            {/*            <Grid.Column width={5}>*/}
            {/*                <Segment>*/}
            {/*                    <Grid container>*/}
            {/*                        <Grid.Row>*/}
            {/*                            <Grid.Column width={16}>*/}
            {/*                                <Header as="h6">Order summary</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                        </Grid.Row>*/}
            {/*                        <Grid.Row>*/}
            {/*                            <Grid.Column width={8}>*/}
            {/*                                <Header as="h6">Subtotal</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                            <Grid.Column width={8} textAlign="right">*/}
            {/*                                <Header as="h5">{SouthAfricanRand.format(round(orderPaymentInfo.subtotal, 2))}</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                        </Grid.Row>*/}
            {/*                        {orderPaymentInfo.logisticsFee &&*/}
            {/*                            <Grid.Row>*/}
            {/*                                <Grid.Column width={8}>*/}
            {/*                                    <Header as="h6">Delivery Fee</Header>*/}
            {/*                                </Grid.Column>*/}
            {/*                                <Grid.Column width={8} textAlign="right">*/}
            {/*                                    <Header as="h5">{SouthAfricanRand.format(round(orderPaymentInfo.logisticsFee, 2))}</Header>*/}
            {/*                                </Grid.Column>*/}
            {/*                            </Grid.Row>*/}
            {/*                        }*/}
            {/*                        <Grid.Row>*/}
            {/*                            <Grid.Column width={8}>*/}
            {/*                                <Header as="h6">Vat</Header>*/}
            {/*                                <sub>(VAT: {orderPaymentInfo.vat}%)</sub>*/}
            {/*                            </Grid.Column>*/}
            {/*                            <Grid.Column width={8} textAlign="right">*/}
            {/*                                <Header as="h5">{SouthAfricanRand.format(round(orderPaymentInfo.vatAmount, 2))}</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                        </Grid.Row>*/}
            {/*                        <Grid.Row>*/}
            {/*                            <Grid.Column width={8}>*/}
            {/*                                <Header as="h6">Total</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                            <Grid.Column width={8} textAlign="right">*/}
            {/*                                <Header as="h5">{SouthAfricanRand.format(round(orderPaymentInfo.total, 2))}</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                        </Grid.Row>*/}
            {/*                    </Grid>*/}
            {/*                </Segment>*/}
            {/*            </Grid.Column>*/}
            {/*        </Grid.Row>*/}
            {/*    </Grid>*/}
            {/*}*/}
            {/*<Grid>*/}
            {/*    <Grid.Row>*/}
            {/*        <Grid.Column width={16}>*/}
            {/*            <Header as="h4">Payment</Header>*/}
            {/*        </Grid.Column>*/}
            {/*    </Grid.Row>*/}
            {/*    {orderDetails &&*/}
            {/*        <Grid.Row>*/}
            {/*            <Grid.Column width={11}>*/}
            {/*                <Form action="https://sandbox.payfast.co.za/eng/process" method="post">*/}
            {/*                    <Accordion fluid styled>*/}
            {/*                        {tecfinityAccount && accountCredit > 0 &&*/}
            {/*                            <Accordion.Title*/}
            {/*                                active={activeIndex === 0}*/}
            {/*                                index={0}*/}
            {/*                                onClick={handleAccordionClick}*/}
            {/*                            >*/}
            {/*                                <Icon name='dropdown' />*/}
            {/*                                Account Credit*/}
            {/*                                {"  "}*/}
            {/*                                <Label color='teal' attached='top right'>*/}
            {/*                                    Recommended*/}
            {/*                                </Label>*/}
            {/*                            </Accordion.Title>*/}
            {/*                        }*/}
            {/*                        {tecfinityAccount && payfastDetails &&*/}
            {/*                            <Accordion.Content active={activeIndex === 0}>*/}
            {/*                                <Table celled collapsing>*/}
            {/*                                    <Table.Body>*/}
            {/*                                        <Table.Row>*/}
            {/*                                            <Table.Cell>*/}
            {/*                                                <Header as="h4">Available Credit</Header>*/}
            {/*                                            </Table.Cell>*/}
            {/*                                            <Table.Cell>{SouthAfricanRand.format(round(accountCredit, 2))}</Table.Cell>*/}
            {/*                                        </Table.Row>*/}
            {/*                                        <Table.Row>*/}
            {/*                                            <Table.Cell>*/}
            {/*                                                <Header as="h4">Credit Limit</Header>*/}
            {/*                                            </Table.Cell>*/}
            {/*                                            <Table.Cell>{SouthAfricanRand.format(round(tecfinityAccount.creditLimit, 2))}</Table.Cell>*/}
            {/*                                        </Table.Row>*/}
            {/*                                    </Table.Body>*/}
            {/*                                </Table>*/}
            {/*                                <Button className="mt-4" type="button" color="red" fluid basic onClick={onAccountCreditPaymentClick}>*/}
            {/*                                    Pay now*/}
            {/*                                </Button>*/}
            {/*                            </Accordion.Content>*/}
            {/*                        }*/}
            {/*                        <Accordion.Title*/}
            {/*                            active={activeIndex === 1}*/}
            {/*                            index={1}*/}
            {/*                            onClick={handleAccordionClick}*/}
            {/*                        >*/}
            {/*                            <Icon name='dropdown' />*/}

            {/*                            {accountCredit <= 0 && <Label color='teal' attached='top right'>*/}
            {/*                                Recommended*/}
            {/*                            </Label>}*/}
            {/*                            PayFast*/}
            {/*                        </Accordion.Title>*/}
            {/*                        {payfastDetails && paymentId &&*/}
            {/*                            <Accordion.Content active={activeIndex === 1}>*/}
            {/*                                <p className="b3">After clicking “Pay Via PayFast”, you will be redirected to PayFast to complete your purchase securely.</p>*/}
            {/*                                <Form.Input type="hidden" name="merchant_id" value={payfastDetails.merchantId} />*/}
            {/*                                <Form.Input type="hidden" name="m_payment_id" value={payfastDetails.merchantPaymentId} />*/}
            {/*                                <Form.Input type="hidden" name="merchant_key" value={payfastDetails.merchantKey} />*/}
            {/*                                <Form.Input type="hidden" name="amount" value={cartTotal} />*/}
            {/*                                <Form.Input type="hidden" name="item_name" value={payfastDetails.orderNumber} />*/}
            {/*                                <Form.Input type="hidden" name="custom_str1" value={paymentId} />*/}
            {/*                                <Form.Input type="hidden" name="return_url" value={`${payfastDetails.returnUrl}/${paymentId}`} />*/}
            {/*                                <Form.Input type="hidden" name="cancel_url" value={`${payfastDetails.returnUrl}/${paymentId}`} />*/}
            {/*                                <Form.Input type="hidden" name="notify_url" value={payfastDetails.notifyUrl} />*/}
            {/*                                <Button type="submit" color="red" fluid basic>Pay via PayFast</Button>*/}
            {/*                            </Accordion.Content>*/}
            {/*                        }*/}
            {/*                        <Accordion.Title*/}
            {/*                            active={activeIndex === 2}*/}
            {/*                            index={2}*/}
            {/*                            onClick={handleAccordionClick}*/}
            {/*                        >*/}
            {/*                            <Icon name='dropdown' />*/}
            {/*                            EFT*/}
            {/*                        </Accordion.Title>*/}
            {/*                        <Accordion.Content active={activeIndex === 2}>*/}
            {/*                            <p className="b2 mb-3">Direct EFT.</p>*/}
            {/*                            <p className="b3">Please note that shipment will only take place once the payment reflects in our account.</p>*/}
            {/*                            <p className="b3">(Payment is through Nedbank or Standard Bank)</p>*/}
            {/*                            <Button className="mt-4" type="button" color="red" fluid basic onClick={onEftPaymentClick}>Pay via EFT</Button>*/}
            {/*                        </Accordion.Content>*/}
            {/*                        <Accordion.Title*/}
            {/*                            active={activeIndex === 3}*/}
            {/*                            index={3}*/}
            {/*                            onClick={handleAccordionClick}*/}
            {/*                        >*/}
            {/*                            <Icon name='dropdown' />*/}
            {/*                            Credit Card On Collection*/}
            {/*                        </Accordion.Title>*/}
            {/*                        <Accordion.Content active={activeIndex === 3}>*/}
            {/*                            <p className="b3">Please note, there will be an additional processing time for your order on arrival, when selecting this option.</p>*/}
            {/*                            <Button className="mt-4" type="button" color="red" fluid basic onClick={onCreditOnCollectionPaymentClick}>*/}
            {/*                                Pay via Credit Card on Collection*/}
            {/*                            </Button>*/}
            {/*                        </Accordion.Content>*/}
            {/*                    </Accordion>*/}
            {/*                </Form>*/}
            {/*            </Grid.Column>*/}
            {/*            <Grid.Column width={5}>*/}
            {/*                <Segment>*/}
            {/*                    <p>Free delivery</p>*/}
            {/*                </Segment>*/}
            {/*                <Segment>*/}
            {/*                    <Grid container>*/}
            {/*                        <Grid.Row>*/}
            {/*                            <Grid.Column width={16}>*/}
            {/*                                <Header as="h6">Order summary</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                        </Grid.Row>*/}
            {/*                        <Grid.Row>*/}
            {/*                            <Grid.Column width={8}>*/}
            {/*                                <Header as="h6">Total</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                            <Grid.Column width={8} textAlign="right">*/}
            {/*                                <Header as="h5">{SouthAfricanRand.format(round(cartTotal, 2))}</Header>*/}
            {/*                            </Grid.Column>*/}
            {/*                        </Grid.Row>*/}
            {/*                    </Grid>*/}
            {/*                </Segment>*/}
            {/*            </Grid.Column>*/}
            {/*        </Grid.Row>*/}
            {/*    }*/}
            {/*</Grid>*/}
        </div>
    );
}

export default Payment;