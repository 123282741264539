import axios from "axios";
import {storageKeys} from "./storageKeys";
import Cookies from "js-cookie";

const orderUrl = process.env.REACT_APP_ORDER_API_URL;
const authenticationUrl = process.env.REACT_APP_IDP_URL;

export const postCheckout = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${orderUrl}/api/checkout/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        return await instance.post(``)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                } else if (error.request) {
                    console.error('Network Error:', error.request);
                } else {
                    console.error('Error:', error.message);
                }
            });
    } catch (error) {
        console.error(error);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const putCheckout = async (checkoutId, checkoutData) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${orderUrl}/api/checkout/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        if (!!checkoutData?.orderItems && checkoutData.orderItems === "null") {
            checkoutData.orderItems = null;
        }

        const requestUrl = `/${checkoutId}`;
        return await instance.put(requestUrl, checkoutData)
                                .then((response) => {
                                    if (response.status === 200) return {
                                        error: false,
                                        success: true,
                                        message: response.data.message,
                                        data: response.data.data
                                    };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (error) {
        console.error(error);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getCheckoutData = async (checkoutId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${orderUrl}/api/checkout`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `/${checkoutId}`;
        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                } else if (error.request) {
                    console.error('Network Error:', error.request);
                } else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getAddressCheckoutData = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${orderUrl}/api/checkout`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `/address`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getOrderCheckoutData = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${orderUrl}/api/checkout`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = "/order";
        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                } else if (error.request) {
                    console.error('Network Error:', error.request);
                } else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getCheckoutLogisticsFeeData = async (checkoutId, subtotal) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${orderUrl}/api/checkout`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const searchParams = new URLSearchParams();
        if (!!checkoutId) searchParams.append("checkoutId", checkoutId);
        if (!!subtotal) searchParams.append("subtotal", subtotal);

        const requestUrl = `/logistics-fee?${searchParams.toString()}`;
        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                } else if (error.request) {
                    console.error('Network Error:', error.request);
                } else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getUserCheckoutData = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${authenticationUrl}/api/checkout`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = "/user";

        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                } else if (error.request) {
                    console.error('Network Error:', error.request);
                } else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};