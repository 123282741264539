import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { Grid, Header, Icon, Divider } from "semantic-ui-react";
import "./style.css";
import SiteBottombar from "../SiteBottombar";

const SiteFooter = () => {
  const [currentPage, setCurrentPage] = useState(null);
  const location = useLocation();

  const pagesToExclude = ["login", "register", "account-verification", "forgot-password"];

  useEffect(() => {
    const pathName = location.pathname;
    const pageName = pathName.split("/").pop();
    setCurrentPage(pageName);
  }, [location]);

  return (
      <>
        {!pagesToExclude.includes(currentPage) &&
            <>
              <footer className="py-9">
                <Grid container>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Divider />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={1} />
                    <Grid.Column width={5}>
                      <Header as="h6" className="mb-2">Help</Header>
                      <p className="b3 mb-1">
                        <Link to="/contact-us">Contact us</Link>
                      </p>
                      <p className="b3 mb-1">
                        <Link to="/faq">Frequently asked questions</Link>
                      </p>
                      <p className="b3 mb-1">
                        <Link to="/faq">Shipping policy</Link>
                      </p>
                      <p className="b3 mb-1">
                        <Link to="/faq">Delivery policy</Link>
                      </p>
                      <p className="b3 mb-1">
                        <Link to="/faq">Privacy policy</Link>
                      </p>
                      <p className="b3 mb-1">
                        <Link to="/faq">Terms and conditions</Link>
                      </p>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Header as="h6" className="mb-2">Account</Header>
                      <p className="b3 mb-1">
                        <a href="/">My account</a>
                      </p>
                      <p className="b3 mb-1">
                        <a href="/">Orders</a>
                      </p>
                      <p className="b3 mb-1">
                        <a href="/">Returns</a>
                      </p>
                      <p className="b3 mb-1">
                        <a href="/">Invoices</a>
                      </p>
                      <p className="b3 mb-1">
                        <a href="/register">Register</a>
                      </p>
                      <p className="b3 mb-1">
                        <a href="/login">Login</a>
                      </p>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Header as="h5" className="mb-2">Company</Header>
                      <p className="b3 mb-1">
                        <a href="/about">About us</a>
                      </p>
                      <p className="b3 mb-1">
                        <a href="/team">Meet the team</a>
                      </p>
                      <p className="b3 mb-1">
                        <a href="/catalogues">Catalogues</a>
                      </p>
                      <br />
                      <Header as="h6" className="mb-2">Head office</Header>
                      <p className="b3 mb-1">
                        <Icon name="map pin"/>
                        <a href="https://goo.gl/maps/RbgTFmVshrD2WJYu8"
                           target="_blank">
                          28 Great North Road, Brentwood Park, 1501
                        </a>
                      </p>
                      <p className="b3 mb-1">
                        <Icon name="phone"/>
                        011 747 5200
                      </p>
                      <p className="b3 mb-1">
                        <Icon name="envelope"/>
                        <a href="mailto:info@argusmotoring.co.za">
                          info@argusmotoring.co.za
                        </a>
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </footer>
              <SiteBottombar/>
            </>

        }
      </>
  );
};

export default SiteFooter;
