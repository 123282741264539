import Cookies from "js-cookie";
import { storageKeys } from "./storageKeys";
import axios from "axios";

const url = process.env.REACT_APP_WEB_API_URL;

export const upsertUserAnnouncement = async (announcementId, acknowledged) => {
	try {
		const token = Cookies.get(storageKeys.ACCESS_TOKEN);
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/announcement/`
		});

		if (!!token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		}

		const body = {
			acknowledged
		}

		const websiteId = process.env.REACT_APP_WEBSITE_ID;

		const requestUrl = `update/${websiteId}/${announcementId}`;

		return await instance.put(requestUrl, body)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	} catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};