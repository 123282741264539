
import axios from "axios";
import {encode} from "js-base64";
import {createAxios} from "./axios";
import { storageKeys } from "./storageKeys";
import Cookies from "js-cookie";

const productApiBaseUrl = process.env.REACT_APP_PRODUCT_API_URL;
const url = `${process.env.REACT_APP_PRODUCT_API_URL}/api`;

const commonFilterApi = () => createAxios(`${url}/CommonFilter`);

export const getCommonProducts = async ({
    pageSize,
    pageNumber,
    productCategory,
    productType,
    manufacturers,
    models,
    vendors,
    years,
    variants,
    service_parts,
    defaultFilterValue
}) => {
    try {
        let serviceParts = "false";
        if (service_parts && (service_parts === "true" || service_parts === true)) {
            serviceParts = "true";
        }

        const response = await commonFilterApi().get("", {
            params: {
                pfa_limit: pageSize ?? "16",
                pfa_skip: pageNumber ? pageNumber - 1 : "0",
                pfa_default_filter_value: defaultFilterValue,
                pfa_product_category: productCategory ? encodeURIComponent(productCategory) : null,
                pfa_product_type: productType ? encodeURIComponent(productType) : null,
                pfa_manufacturers: manufacturers ? encodeURIComponent(manufacturers) : null,
                pfa_models: models ? encodeURIComponent(models) : null,
                pfa_vendors: vendors ? encodeURIComponent(vendors) : null,
                pfa_years: years ? encodeURIComponent(years) : null,
                pfa_variants: variants ? encodeURIComponent(variants) : null,
                pfa_service_parts: serviceParts
            }
        });
        return response? response.data : [];
    } catch (error) {
        console.error(error)
    }
};

export const getCommonProductsExp = async ({
    pageSize,
    pageNumber,
    productCategory,
    productType,
    manufacturers,
    models,
    vendors,
    years,
    variants,
    service_parts,
    defaultFilterValue
}) => {
    try {
        let serviceParts = "false";
        if (service_parts && (service_parts === "true" || service_parts === true)) {
            serviceParts = "true";
        }

        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${productApiBaseUrl}/api/commonfilter`,
        });

        const params = {
            pfa_limit: pageSize ?? "16",
            pfa_skip: pageNumber ? pageNumber - 1 : "0",
            pfa_default_filter_value: defaultFilterValue,
            pfa_product_category: productCategory ? encodeURIComponent(productCategory) : null,
            pfa_product_type: productType ? encodeURIComponent(productType) : null,
            pfa_manufacturers: manufacturers ? encodeURIComponent(manufacturers) : null,
            pfa_models: models ? encodeURIComponent(models) : null,
            pfa_vendors: vendors ? encodeURIComponent(vendors) : null,
            pfa_years: years ? encodeURIComponent(years) : null,
            pfa_variants: variants ? encodeURIComponent(variants) : null,
            pfa_service_parts: serviceParts
        }

        const stringfiedParams = JSON.stringify(params);

        const base64Params = encode(stringfiedParams);

        const requestUrl = "/argus-products";

        return await instance.get(requestUrl, { params: { f: base64Params} })
                                .then((response) => {
                                    if (response.status === 200) return {
                                        error: false,
                                        success: true,
                                        message: response.data.message,
                                        data: response.data.data
                                    };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (error) {
        console.error(error)
    }
};

export const getFiltersExp = async ({
   pageSize,
   pageNumber,
   productCategory,
   productType,
   manufacturers,
   models,
   vendors,
   years,
   variants,
   service_parts,
   defaultFilterValue
}) => {
    try {
        let serviceParts = "false";
        if (service_parts && (service_parts === "true" || service_parts === true)) {
            serviceParts = "true";
        }

        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${productApiBaseUrl}/api/commonfilter`,
        });

        const params = {
            pfa_limit: pageSize ?? "16",
            pfa_skip: pageNumber ? pageNumber - 1 : "0",
            pfa_default_filter_value: defaultFilterValue,
            pfa_product_category: productCategory ? encodeURIComponent(productCategory) : null,
            pfa_product_type: productType ? encodeURIComponent(productType) : null,
            pfa_manufacturers: manufacturers ? encodeURIComponent(manufacturers) : null,
            pfa_models: models ? encodeURIComponent(models) : null,
            pfa_vendors: vendors ? encodeURIComponent(vendors) : null,
            pfa_years: years ? encodeURIComponent(years) : null,
            pfa_variants: variants ? encodeURIComponent(variants) : null,
            pfa_service_parts: serviceParts
        }

        const stringfiedParams = JSON.stringify(params);

        const base64Params = encode(stringfiedParams);

        const requestUrl = "/argus-filters";

        return await instance.get(requestUrl, { params: { f: base64Params} })
                                .then((response) => {
                                    if (response.status === 200) return {
                                        error: false,
                                        success: true,
                                        message: response.data.message,
                                        data: response.data.data
                                    };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });

    } catch (error) {
        console.error(error)
    }
};

export const getPricesExp = async ({
    skus,
    level
}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${productApiBaseUrl}/api/commonfilter`,
        });

        const params = {
            skus: skus,
            level: level
        }

        const stringfiedParams = JSON.stringify(params);

        const base64Params = encode(stringfiedParams);

        const requestUrl = "/argus-prices";
        return await instance.get(requestUrl, { params: { f: base64Params} })
                                .then((response) => {
                                    if (response.status === 200) return {
                                        error: false,
                                        success: true,
                                        message: response.data.message,
                                        data: response.data.data
                                    };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (error) {
        console.error(error)
    }
};



