import React, {Fragment, useEffect, useState} from "react";
import SiteHeader from "../../components/SiteHeader";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteFooter from "../../components/SiteFooter";
import Cookies from "js-cookie";
import {storageKeys} from "../../api/storageKeys";
import {AccountType, ApiCallStatus, LoggedInStatus} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import {getPlaceDetails} from "../../api/googlePlaceController";
import _, {cloneDeep} from "lodash";
import {
    getAddressCheckoutData,
    getCheckoutData, getCheckoutLogisticsFeeData, getOrderCheckoutData,
    getUserCheckoutData,
    putCheckout
} from "../../api/checkoutController";
import {createAddress} from "../../api/addressController";
import {isLoggedIn, removeAuthCookies} from "../../api/authentication";
import {getCurrentUser} from "../../api/authenticationController";
import CheckoutShippingAddress from "../../components/CheckoutShippingAddress";
import { useCart } from "react-use-cart";

const checkIsLoggedIn = async ({
    setLoggedIn
}) => {
    try {
        const response = await isLoggedIn();
        setLoggedIn(response);
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
    }
}

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus,
    setLoggedIn
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
            setLoggedIn(LoggedInStatus.No);
        }
    } catch (e) {
        console.error(e);
        setLoggedIn(LoggedInStatus.No);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const clearCookies = async () => {
    await removeAuthCookies();
};

const fetchUserCheckoutData = async ({
    setUserCheckoutInfo,
    setUserCheckoutInfoCallStatus
}) => {
    setUserCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let checkoutUser = await getUserCheckoutData();
        if (checkoutUser?.success) {
            setUserCheckoutInfo(checkoutUser.data);
            setUserCheckoutInfoCallStatus(ApiCallStatus.Succeeded)
        }
        else {
            setUserCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setUserCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchAddressCheckoutData = async ({
    setAddressCheckoutInfo,
    setAddressCheckoutInfoCallStatus
}) => {
    setAddressCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let checkoutUser = await getAddressCheckoutData();
        if (checkoutUser?.success) {
            const addressData = checkoutUser.data;
            const addressDataClone = cloneDeep(addressData);

            const newShippingAddress = Cookies.get(storageKeys.NEW_CHECKOUT_SHIPPING_ADDRESS);
            if (!!newShippingAddress) {
                const guestTempuserAddresses = [];
                guestTempuserAddresses.push(JSON.parse(newShippingAddress));
                addressDataClone.userAddresses = guestTempuserAddresses;
            }

            setAddressCheckoutInfo(addressDataClone);
            setAddressCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setAddressCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setAddressCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchOrderCheckoutData = async ({
    setOrderCheckoutInfo,
    setOrderCheckoutInfoCallStatus
}) => {
    setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress);
    try {
        let checkoutUser = await getOrderCheckoutData();
        if (checkoutUser?.success) {
            setOrderCheckoutInfo(checkoutUser.data);
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setOrderCheckoutInfoCallStatus(ApiCallStatus.Error);
    }
}

const fetchCheckoutData = async ({
    checkoutId,
    setCheckoutData,
    setCheckoutDataCallStatus
}) => {
    setCheckoutDataCallStatus(ApiCallStatus.InProgress);
    try {
        let checkoutUser = await getCheckoutData(checkoutId);
        if (checkoutUser?.success) {
            setCheckoutData(checkoutUser.data);
            setCheckoutDataCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCheckoutDataCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setCheckoutDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchCheckoutLogisticsFeeData = async ({
    checkoutId,
    subtotal,
    setLogisticsFeeData,
    setLogisticsFeeDataCallStatus
}) => {
    setLogisticsFeeDataCallStatus(ApiCallStatus.InProgress);
    try {
        let response = await getCheckoutLogisticsFeeData(checkoutId, subtotal);
        if (response?.success) {
            setLogisticsFeeData(response.data);
            setLogisticsFeeDataCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setLogisticsFeeDataCallStatus(ApiCallStatus.Failed);
        }
    } catch (error) {
        console.error(error);
        setLogisticsFeeDataCallStatus(ApiCallStatus.Error);
    }
}

const fetchGooglePlaceDetails = async ({
   setGoogleAddressCallStatus,
   placeId,
   description
}) => {
    setGoogleAddressCallStatus(ApiCallStatus.NotStarted);
    try {
        const response = await getPlaceDetails(placeId, description);
        if (response.success) {
            setGoogleAddressCallStatus(ApiCallStatus.Succeeded);
            return response.data;
        }
        else {
            setGoogleAddressCallStatus(ApiCallStatus.Failed);
        }
    } catch (e) {
        console.error(e);
        setGoogleAddressCallStatus(ApiCallStatus.Error);
    }
}

const updateCheckoutData = async ({
    checkoutId,
    checkoutData,
    setUpdateCheckoutDataCallStatus
}) => {
    setUpdateCheckoutDataCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await putCheckout(checkoutId, checkoutData);

        if (response.success) {
            setUpdateCheckoutDataCallStatus(ApiCallStatus.Succeeded);
        } else {
            setUpdateCheckoutDataCallStatus(ApiCallStatus.Failed);
        }
    } catch (e) {
        console.error(e);
        setUpdateCheckoutDataCallStatus(ApiCallStatus.Error);
    }
}

const createAddressData = async ({
    firstName,
    lastName,
    email,
    streetAddress,
    countryId,
    suburb,
    city,
    postalCode,
    provinceId,
    gotoErrorPage,
    reloadPage
}) => {
    try {
        const body = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            countryId: countryId,
            suburb: suburb,
            city: city,
            postalCode: postalCode,
            provinceId: provinceId,
            streetAddress: streetAddress
        };
        let response = await createAddress(body);

        if (response.success) {
            reloadPage();
        } else {
            gotoErrorPage();
        }
    } catch (error) {
        console.error(error)
    }
}

const CheckoutShippingAddressPage = () => {
    const { cartTotal } = useCart();
    const location = useLocation();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [checkoutData, setCheckoutData] = useState(null);
    const [logisticsFeeData, setLogisticsFeeData] = useState(null);
    const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
    const [userCheckoutInfo, setUserCheckoutInfo] = useState(null);
    const [addressCheckoutInfo, setAddressCheckoutInfo] = useState(null);
    const [accountType, setAccountType] = useState(AccountType.Guest);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [userAddresses, setUserAddresses] = useState([]);
    const [vat, setVat] = useState(null);
    const [vatAmount, setVatAmount] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [logisticsFeeDataCallStatus, setLogisticsFeeDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [userCheckoutInfoCallStatus, setUserCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [addressCheckoutInfoCallStatus, setAddressCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
    const [googleAddressCallStatus, setGoogleAddressCallStatus] = useState(ApiCallStatus.NotStarted);
    const [updateCheckoutDataCallStatus, setUpdateCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoLoginPage = () => {
        return navigate('/login', { state: { from: location } });
    };

    const reloadPage = () => navigate(0);
    const gotoErrorPage = () => navigate("/error");
    const gotoCheckoutDeliveryMethodPage = () => navigate("/checkout/delivery-method");
    const gotoCheckoutSummaryPage = () => navigate("/checkout/summary");

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus,
            setLoggedIn
        });
    }

    const fetchCheckoutUserDataAsync = async () => {
        await fetchUserCheckoutData({
            setUserCheckoutInfo,
            setUserCheckoutInfoCallStatus
        });
    }

    const fetchCheckoutDataAsync = async () => {
        const checkoutId = Cookies.get(storageKeys.CHECKOUT_ID);
        await fetchCheckoutData({
            checkoutId,
            setCheckoutData,
            setCheckoutDataCallStatus
        });
    }

    const fetchCheckoutLogisticsFeeDataAsync = async () => {
        const checkoutId = Cookies.get(storageKeys.CHECKOUT_ID);
        await fetchCheckoutLogisticsFeeData({
            checkoutId,
            subtotal: cartTotal,
            setLogisticsFeeData,
            setLogisticsFeeDataCallStatus
        });
    }

    const fetchCheckoutAddressDataAsync = async () => {
        await fetchAddressCheckoutData({
            setAddressCheckoutInfo,
            setAddressCheckoutInfoCallStatus
        });
    }

    const fetchCheckoutOrderDataAsync = async () => {
        await fetchOrderCheckoutData({
            setOrderCheckoutInfo,
            setOrderCheckoutInfoCallStatus
        });
    }

    const authorizedPageLoad = async () => {
        await fetchCurrentUserAsync();
        await fetchCheckoutUserDataAsync();
        await fetchCheckoutAddressDataAsync();
    }

    const unAuthorizedPageLoad = async () => {
        await fetchCheckoutDataAsync();
        await fetchCheckoutLogisticsFeeDataAsync();
        await fetchCheckoutOrderDataAsync();
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
            unAuthorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            gotoLoginPage();
        }

        // unAuthorizedPageLoad();
    }, [loggedIn]);

    useEffect(() => {
        if (!!userCheckoutInfo) {
            if (userCheckoutInfo.currentTradeAccount) {
                setAccountType(AccountType.Trade);
            } else {
                setAccountType(AccountType.Retail);
            }
        }
    }, [userCheckoutInfo]);

    useEffect(() => {
        if (!!addressCheckoutInfo) {
            let userAddressesTemp = [];
            let countries = [];
            let provinces = [];

            if (addressCheckoutInfo?.countries) {
                const countriesInAlphabeticalOrder = _.sortBy(addressCheckoutInfo.countries, ({name}) => name);
                countries = _.sortBy(countriesInAlphabeticalOrder, ({name}) => name === 'South Africa' ? 0 : 1);
            }

            if (addressCheckoutInfo?.provinces) {
                provinces = addressCheckoutInfo.provinces;
            }

            if (addressCheckoutInfo?.userAddresses) {
                userAddressesTemp = addressCheckoutInfo.userAddresses;
            }

            setProvinces(provinces);
            setCountries(countries);
            setUserAddresses(userAddressesTemp);
        }
    }, [addressCheckoutInfo]);

    useEffect(() => {
        if (!!orderCheckoutInfo?.vat && !!userCheckoutInfo) {
            const vatTemp = orderCheckoutInfo.vat;
            let vatAmountTemp = 0;

            if (!userCheckoutInfo.currentTradeAccount) {
                vatAmountTemp = cartTotal/(1 + (vatTemp/100));
            }
            else {
                vatAmountTemp = cartTotal * (vatTemp/100)
            }

            setVat(vatTemp);
            setVatAmount(vatAmountTemp);
        }

    }, [orderCheckoutInfo?.vat, userCheckoutInfo]);

    const fetchGooglePlaceDetailsAsync = async ({
        placeId,
        description
    }) => {
        const fetchGooglePlaceDetailsOptions = {
            setGoogleAddressCallStatus,
            placeId,
            description
        }

        return await fetchGooglePlaceDetails(fetchGooglePlaceDetailsOptions);
    }

    const createNewAddressAsync = async ({
        addressId,
        firstName,
        lastName,
        streetAddress,
        countryId,
        suburb,
        city,
        postalCode,
        provinceId
    }) => {
        const createAddressDataOptions = {
            addressId,
            firstName,
            lastName,
            streetAddress,
            countryId,
            suburb,
            city,
            postalCode,
            provinceId,
            gotoErrorPage,
            reloadPage
        }

        await createAddressData(createAddressDataOptions);
    }

    const updateCheckoutDataAsync = async ({
        checkoutData
    }) => {
        const checkoutId = Cookies.get(storageKeys.CHECKOUT_ID);

        if (!checkoutId) {
            gotoErrorPage()
        }

        await updateCheckoutData({
            checkoutId,
            checkoutData,
            setUpdateCheckoutDataCallStatus
        });
        await fetchCheckoutDataAsync();
    }

    const allLoadingStates = [
        currentUserCallStatus,
        checkoutDataCallStatus,
        userCheckoutInfoCallStatus,
        addressCheckoutInfoCallStatus,
        updateCheckoutDataCallStatus,
        googleAddressCallStatus,
        orderCheckoutInfoCallStatus,
        logisticsFeeDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser}/>
            <CheckoutShippingAddress accountType={accountType}
                                     orderCheckoutInfo={orderCheckoutInfo}
                                     checkoutData={checkoutData}
                                     logisticsFee={logisticsFeeData}
                                     vat={vat}
                                     vatAmount={vatAmount}
                                     countries={countries}
                                     provinces={provinces}
                                     userAddresses={userAddresses}
                                     createAddress={createNewAddressAsync}
                                     fetchGooglePlaceDetails={fetchGooglePlaceDetailsAsync}
                                     gotoCheckoutSummaryPage={gotoCheckoutSummaryPage}
                                     onSubmit={updateCheckoutDataAsync}/>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <SiteFooter/>
        </Fragment>
    )
}

export default CheckoutShippingAddressPage;