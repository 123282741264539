import React, {useEffect, useState} from "react";
import {Box, Modal} from "@mui/material";
import {Button, Card, Form, Grid, Header, Icon, Image, Menu, Message} from "semantic-ui-react";
import {storageKeys} from "../../api/storageKeys";
import {getItem, removeItem, setItem} from "../../utils/storageHelper";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import "./style.css";

export const confirmationChoices = {
    changeVehicle: "change",
    searchForParts: "search",
    removeVehicle: "remove",
    cancel: "cancel"
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25%",
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: 4
};


const SavedVehicleModal = ({
    open,
    setOpen,
    openSavedVehiclesModal,
    setClose
}) => {
    const navigate = useNavigate();
    const [currentVehicle, setCurrentVehicle] = useState(null);
    const [currentVehicleName, setCurrentVehicleName] = useState("");

    useEffect(() => {
        const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);

        if (!!vehicleString) {
            const vehicle = JSON.parse(vehicleString);
            setCurrentVehicle(vehicle)
        }
    }, []);

    useEffect(() => {
        if (!!currentVehicle) {
            let name = "";
            name += currentVehicle.make ? `${currentVehicle.make} ` : "";
            name += currentVehicle.model ? `${currentVehicle.model} ` : "";
            name += currentVehicle.variant ? `${currentVehicle.variant} ` : "";
            name += currentVehicle.year ? `${currentVehicle.year} ` : "";

            setCurrentVehicleName(name);
        }
    }, [currentVehicle]);

    const closeModal = () => {
        setClose();
    }

    const handleRemoveVehicleClick = () => {
        removeItem(storageKeys.GUEST_CURRENT_VEHICLE);
        closeModal();
        navigate(0);
    };

    const handleSearchForPartsClick = async () => {
        const currentVehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);
        if (!currentVehicleString) {
            closeModal();
        }

        const currentVehicleObject = JSON.parse(currentVehicleString);

        if (!currentVehicleObject) {
            closeModal();
        }

        const params = {};



        if (!!currentVehicleObject?.vyid) {
            params.vehicle_year_id = currentVehicleObject.vyid;

            await navigate({
                pathname: '/products',
                search: `?${createSearchParams(params)}`
            });
        }
        else if (!!currentVehicleObject.make || !!currentVehicleObject.model || !!currentVehicleObject.variant || !!currentVehicleObject.year) {

            if (!!currentVehicleObject.make) {
                params.make = currentVehicleObject.make;
            }

            if (!!currentVehicleObject.model) {
                params.model = currentVehicleObject.model;
            }

            if (!!currentVehicleObject.variant) {
                params.variant = currentVehicleObject.variant;
            }

            if (!!currentVehicleObject.year) {
                params.year = currentVehicleObject.year;
            }

            await navigate({
                pathname: '/products',
                search: `?${createSearchParams(params)}`
            });
        }

        closeModal();
    };

    const handleChangeVehicleClick = () => {
        openSavedVehiclesModal();
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <Box sx={style}>
                <Grid>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16} textAlign="center">
                            <Image src="https://argusweb.azureedge.net/argus-web/logo.svg" size="small" fluid centered />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <div className="argus-modal-title">{currentVehicleName}</div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div className="argus-modal-text">This will allow us to find parts specifically for your vehicle.</div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Link className="argus-modal-link" to="#" onClick={handleChangeVehicleClick}>
                                Choose a different vehicle
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Link className="argus-modal-link" to="#" onClick={handleRemoveVehicleClick}>
                                Browse without a vehicle
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="right">
                            <Button type="button" className="blue-button" onClick={handleSearchForPartsClick}>
                                Search for parts
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Box>
        </Modal>
    );
};
export default SavedVehicleModal;